<template>
    <v-list
        nav
        dense
    >
        <Transition name="slide-fade">
            <div v-show="father && !isMini">
                <v-container class="text-center" style="background-color: white;">
                    <v-img
                        v-if="!maintenance"
                        lazy-src="@/assets/fundo_conduril.png"
                        src="@/assets/fundo_conduril.png"
                        cover
                    />
                    <v-img
                        v-if="maintenance"
                        src="@/assets/Maintenance/manutencao.png"
                        lazy-src="@/assets/Maintenance/manutencao.png"
                        contain
                    />
                </v-container>
                
                <v-divider color="white" class="mt-4 mb-4"></v-divider>
            </div>
        </Transition>
        
        <template v-for="item in items">
            <template v-if="item.canShow === undefined ? true : item.canShow">
                <v-list-group
                    :key="item.name"
                    v-if="item.items !== undefined && item.items.length > 0"
                    color="white darken-2"
                    :value="item.items.some(i => $route.path.indexOf(i.path) >= 0)"
                    :exact="item.exact"
                >
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon :small="isMini">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                    </template>

                    <NodeList :items="item.items" :father="false" :isMini="isMini"/>
                </v-list-group>

                <v-list-item
                    :key="item.name"
                    v-else
                    color="white darken-2"
                    :href="item.path"
                    :target="openNewTab(item)"
                    :exact="item.exact"
                    link
                >
                    <v-list-item-icon>
                        <v-icon :small="isMini">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item>
            </template>
        </template>
    </v-list>
</template>


<script>
export default {
    name: "NodeList",
    props: {
        items: Array,
        father: {
            typeof: Boolean,
            default: true
        },
        isMini:{
            typeof: Boolean,
            default: false
        },
        userData:{
            typeof: Object,
            default: null
        }
    },
    watch:{
        userData:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                if(val.maintenance == null)
                    return
                    
                this.maintenance = val.maintenance.value == '0' ? false : true
            }
        }
    },
    data(){
        return{
            maintenance: false
        }
    },
    methods:{
        openNewTab(item){
            if(item.newTab == false)
                return '_self'

            
                // Verifica se a aplicação está instalada (não em um navegador padrão)
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

            return isStandalone ? '_self' : '_blank';
        }
    }
}
</script>

<style scoped>
::v-deep .v-list-group__items .v-list--nav {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>