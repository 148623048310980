import Logs from "@/api/Logs";

export const logs = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getLogs(params, data) {
            return new Promise((resolve, reject) => {
                Logs.getLogs(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}