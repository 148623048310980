<template>
    <v-container fluid class="ma-0 pa-0">
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-simple-table class="d-none d-lg-block elevation-10" style="width: 50%;" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left primary white--text">
                                    Obra
                                </th>
                                <th class="text-left primary white--text">
                                    Data fim
                                </th>
                                <th class="text-left primary white--text">
                                    Concluída (%)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in fields.lista_obras" :key="index"
                            >
                                <td>
                                    {{item.obra}}
                                </td>
                                <td>
                                    POR PROGRAMAR
                                </td>
                                <td>
                                    {{fixNumber(item.concluido)}}%
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="primary white--text">
                        Efetuado Semana UO
                    </v-card-title>
                    <v-card-text class="mt-4" v-if="efetuado_semana_percentagem.length > 0">
                        <apexchart style="position: absolute; width: 100%;" type="bar" height="350" :options="getFakeChartOptions()" :series="efetuado_semana_percentagem.length == 0 ? [] : efetuado_semana_percentagem[0].data"></apexchart>
                        <div style="position: relative;">
                            <v-row no-gutters class="ma-0 pa-0">
                                <v-col cols="12" md="2" style="position: relative;" v-for="item in efetuado_semana_percentagem" :key="item.name">
                                    <apexchart type="bar" height="350" :options="getChartOptions(item.codigo)" :series="item.data"></apexchart>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-text v-else>
                        Não existem registos para mostrar
                    </v-card-text>
                </v-card>
            </v-col>

            
            <v-col cols="12">
                <v-card>
                    <v-card-title class="primary white--text">
                        Obras semana
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <apexchart style="position: absolute; width: 100%;" type="bar" height="350" :options="getFakeChartOptions()" :series="efetuado_semana_percentagem.length == 0 ? [] : efetuado_semana_percentagem[0].data"></apexchart>
                        <div style="position: relative;">
                            <v-row no-gutters class="ma-0 pa-0">
                                <v-col cols="12" md="3" style="position: relative;" v-for="item in obras_semana" :key="item.name">
                                    <apexchart type="bar" height="350" :options="getChartOptions(item.name)" :series="item.data"></apexchart>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="2" v-for="(item, index) in fields.diarias_falta" :key="index+2000">
                <v-card height="150">
                    <v-card-text class="primary white--text">
                        {{item.obra}}
                    </v-card-text>
                    <v-card-text>
                        <strong>Diárias feitas / Em atraso: </strong><br/>{{item.feitas}} / {{item.delayed}}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import Dashboard from '@/api/Dashboard.js'

export default{
    name: "DashboardEngineer",
    components:{
        apexchart: VueApexCharts,
    },
    data(){
        return{
            loading: true,
            filter: {

            },
            fields: null,
            efetuado_semana_percentagem: null,
            obras_semana: null
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fixNumber(n){
            return n.toFixed(2)
        },
        fillBaseData(){
            Dashboard.getDashboardEngineer(this.filter).then((resp)    =>  {
                this.fields = resp.data

                this.organizeGraphArrays()

                this.loading = false
            })
        },
        organizeGraphArrays(){
            let final_array = [];


            this.fields.lista_obras.forEach(element => {

                let unidades = []
                element.unidades_obra.forEach(uo => {
                    if(uo.perc_semana > 0)
                        unidades.push({
                            name: uo.id_unidade,
                            data: [uo.perc_semana]
                        });
                });

                final_array.push({
                    name: element.obra,
                    codigo: element.codigo,
                    data: unidades
                });

            });

            this.efetuado_semana_percentagem = final_array


            // Preparação do gráfico de obras por semana
            final_array = []
            this.fields.obras_semanais.forEach(element => {
                let internal_data = []

                element.data.forEach(internal => {
                    if(internal.concluido > 0)
                        internal_data.push({
                            name: internal.codigo,
                            data: [internal.concluido]
                        })
                });
                final_array.push({
                    name: element.semana,
                    data: internal_data
                })
            });

            this.obras_semana = final_array

        },
        getChartOptions(nome){
            let ob = {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar:{
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '50%',
                        endingShape: 'rounded',
                        dataLabels: {
                            orientation: 'vertical'
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, e) {
                        return e.config.series[e.seriesIndex].name;
                    },
                    style: {
                        fontSize: '12px',
                        colors: ['black']
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [nome],
                    position: 'top',
                    offsetX: 0,
                    offsetY: -20,
                },
                legend: {
                    show: false,
                },
                yaxis: {
                    title: {
                        text: '%'
                    },
                    min: 0,
                    max: 100,
                    show: false
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toFixed(2) + "%"
                        }
                    }
                },
                grid:{
                    show: false
                }
            }

            return ob;
        },
        getFakeChartOptions(){
            let ob = {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar:{
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '0%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: false,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                    position: 'top',
                    labels: {
                        show: false,
                    }
                },
                legend: {
                    show: false
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    floating: false,
                    labels: {
                    formatter: function(val) {
                    return val.toFixed(0)
                    }
                }
                },
                fill: {
                    opacity: 1
                },
                grid:{
                    show: true
                }
            }

            return ob;
        }
    },
    computed:{
        getSeries(){

            let obras = []


            let valor_unidades = []

            let unidades_array = []

            this.fields.lista_obras.forEach(element => {

                element.unidades_obra.forEach(uo => {

                    let existe = false
                    unidades_array.forEach(unidade_no_array => {
                        if(unidade_no_array.name == uo.id_unidade)  
                            existe = true                          
                    });

                    if(existe == false){
                        let emptyArray = new Array();

                        unidades_array.push({
                            name: uo.id_unidade, 
                            data: emptyArray
                        })
                        
                    }

                })
            })


            this.fields.lista_obras.forEach(element => {

                element.unidades_obra.forEach(uo => {

                    let i = 0;
                    let pos = -1

                    unidades_array.forEach(unidade_no_array => {
                        if(unidade_no_array.name === uo.id_unidade){
                            pos = i
                            unidades_array[i].data.push(uo.perc_semana)
                        }                           
                        
                        i++
                    });
                    
                        
                    
                });

            });
            

            return  unidades_array
        },        
    }
}
</script>