import API from "./API";

export default class Entidades extends API
{
    static async byWork(id) {
        return this.get("api/admin/entidades/by-work/" + id);
    }

    static async all() {
        return this.get("api/admin/entidades");
    }

}