<template>
    <v-snackbar
        v-bind="$attrs"
        v-on="$listeners"
        top
        right
        :timeout="2000"
        color="success"
    >
        {{message || "Sucesso!"}}

        <template v-slot:action="{attrs}">
            <Button
                fab
                text
                class="mb-2 mt-2"
                v-bind="attrs"
                @click="close"
                color="white"
                small
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </Button>
        </template>
    </v-snackbar>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
    components: {
        Button
    },
    props: {
        message: String
    },
    methods: {
        close: function() {
            this.$emit("input", false)
        }
    }
}
</script>