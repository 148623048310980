import API from "./API";

export default class Role extends API
{
    static async all() {
        return this.get("api/admin/roles/list");
    }

    static async search(filter) {
        return this.get("api/admin/roles",{params: filter});
    }

    static async create(fields) {
        return this.post("api/admin/roles", fields);
    }

    static async updateRole(fields, id) {
        return this.put("api/admin/roles/" + id, fields);
    }

    static async deleteRole(id) {
        return this.delete("api/admin/roles/" + id);
    }

    static async getRole(id) {
        return this.get("api/admin/roles/" + id);
    }
}