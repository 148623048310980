import ODCMachines from "@/api/ODCMachines";

export const odc_machines = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getMachines() {
            return new Promise((resolve, reject) => {
                ODCMachines.getMachines()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async createMachine(params, fields) {
            return new Promise((resolve, reject) => {
                ODCMachines.createMachine(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getODCMachine(params, id) {
            return new Promise((resolve, reject) => {
                ODCMachines.getODCMachine(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateODCMachine(params, data) {
            return new Promise((resolve, reject) => {
                ODCMachines.updateODCMachine(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteODCMachine(params, id){
            return new Promise((resolve, reject) => {
                ODCMachines.deleteODCMachine(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}