<template>
    <Dialog 
      v-bind="$attrs"
      v-on="$listeners"
      title="Sucesso">
      <template #content>
        <v-alert
          border="bottom"
          colored-border
          type="success"
          elevation="2"
        >
          <slot></slot>
        </v-alert>
      </template>
      <template #footer>
        <v-btn
            depressed
            color="white"
            class="success--text"
            @click="$emit('on-ok')"
          >OK</v-btn>
      </template>
    </Dialog>
</template>

<script>
  import Dialog from '@/components/UI/Dialog.vue';
  export default {
    name: 'uiDialogSuccess',
    components: {
      Dialog
    }
  }
</script>

<style lang="scss" scoped>
</style>