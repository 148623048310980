import Exportation from "@/api/Exportation";

export const exportation = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async export_file(params, ob) {
            return new Promise((resolve, reject) => {
                Exportation.export_file(ob)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteExportation(params, path) {
            return new Promise((resolve, reject) => {
                Exportation.deleteExportation(path)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}