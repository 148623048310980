<template>
      <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
          <v-progress-linear v-if="loading"
              indeterminate
              class="global-loader"
          ></v-progress-linear>
          <fieldset :disabled="loading" style="border: 0px;">
          <v-row>
              <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Código" name="Código" rules="required">
                  <v-text-field
                  v-model="fields.code"
                  prepend-inner-icon="mdi-account-details"
                  label="Código *"
                  outlined dense
                  clearable
                  :error-messages="errors"
                  ></v-text-field>
              </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Nome" name="Nome" rules="required">
                  <v-text-field
                  v-model="fields.name"
                  prepend-inner-icon="mdi-account-details"
                  label="Nome *"
                  clearable
                  outlined dense
                  :error-messages="errors"
                  ></v-text-field>
              </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider v-slot="{errors}" vid="Cliente" name="Cliente" rules="|">
                  <v-select
                    :items="clients"
                    v-model="fields.cliente"
                    prepend-inner-icon="mdi-account"
                    label="Cliente"
                    item-text="name"
                    item-value="name"
                    outlined dense
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider v-slot="{errors}" vid="Data de Fecho" name="Data de Fecho" rules="|">
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fields.data_de_fecho"
                          label="Data de fecho"
                          outlined dense
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                      </template>
                      <v-date-picker
                          :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                          v-model="fields.data_de_fecho"
                          @input="menu2 = false"
                      ></v-date-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="11">
              <validation-provider v-slot="{ errors }" vid="Link Sharepoint" name="Link Sharepoint" rules="|">
                  <v-text-field
                  v-model="fields.sharepoint_link"
                  prepend-inner-icon="mdi-account-details"
                  label="Link Sharepoint"
                  outlined dense
                  clearable
                  :error-messages="errors"
                  ></v-text-field>
              </validation-provider>
              </v-col>
              <v-col cols="12" md="1">
                <v-btn :disabled="fields.sharepoint_link == null" @click="openSharepoint" small fab class="primary"><v-icon>mdi-eye</v-icon></v-btn>
              </v-col>
          </v-row>
          <v-card v-if="method == 'update'">
            <v-card-title class="primary white--text">
              Tarefas
            </v-card-title>
            <v-card-text class="mt-n16">
              <Tasks :work="work"/>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-6" align="center" justify="space-around">
              <v-btn :disabled="invalid" depressed color="primary" type="submit">
              Gravar
              </v-btn>
          </v-row>
          </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Obra gravada com sucesso!
      </DialogSuccess>
      </validation-observer>
  </template>
  <script>
  import GarantiasObras from "@/api/GarantiasObras.js";
  import Clients from "@/api/Clients.js"
  import DialogSuccess from '@/components/UI/DialogSuccess.vue';
  import Tasks from '@/components/guarantees/Tasks.vue'
  
  export default {
    components: {
      DialogSuccess,
      Tasks
    },
    props: {
     work: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
        work: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      menu2: false,
      clients: [],
      fields: {
        id: null,
        code: null,
        name: null,
        sharepoint_link: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      openSharepoint(){
        window.open(this.fields.sharepoint_link, '_blank')
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/garantias/obras');
      },
      fillBaseData(){
        Clients.all().then((resp) =>  {
          this.clients = resp.data
        })
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          
          GarantiasObras[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  