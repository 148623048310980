import API from "./API";

export default class Funcionario extends API
{
    static async all() {
        return this.get("api/admin/funcionarios/list");
    }
    
    static async allNoRestrictions() {
        return this.get("api/admin/funcionarios/all-no-restrictions");
    }

    static async allUsed() {
        return this.get("api/admin/funcionarios/allUsed");
    }
    
    static async getListEquipamentosFromFuncionario(id) {
        return this.get("api/admin/funcionarios/" + id + "/list/equipamentos");
    }
}