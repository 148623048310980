import API from "./API";

export default class Notifications extends API
{
    static async markAsViewed(id){
        return this.put("api/admin/notifications/" + id);
    }

}


    
    