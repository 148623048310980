<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Horário de obras</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>
                <Form ref="pd" :loading="loading" @submited="createWorkSchedule">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="$route.params.id == undefined"
                            :items="works"
                            item-text="Descricao"
                            label="Obras"
                            vid="obras"
                            class="basic"
                            v-model="fields.work"
                            return-object
                            @change="render1++"
                        />
                        <div v-else>
                            <v-row>
                                <h2>Obra:</h2>
                                <label class="ml-6 mt-2">{{ workName }}</label>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-card elevation="0">
                            <v-card-title>Hora inicio:</v-card-title>
                            <v-card-text>
                                <v-time-picker
                                    v-model="fields.starting_hour"
                                    class="mt-4"
                                    format="24hr"
                                ></v-time-picker>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card elevation="0">
                            <v-card-title>Hora Fim:</v-card-title>
                            <v-card-text>
                                <v-time-picker
                                    v-model="fields.finishing_hour"
                                    class="mt-4"
                                    format="24hr"
                                ></v-time-picker>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                </Form>
            </v-col>
        </v-row>
        <SuccessSnackbar message="Operação realizada com sucesso" v-model="success"/>
    </v-container>
</template>
<script>
import Select from "@/components/UI/Inputs/Select";
import Form from "@/components/UI/Forms/Form";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default {
    components:{
        Form,
        Select,
        SuccessSnackbar
    },
    data(){
        return{
            render1: 0,
            loading: true,
            success: false,
            works: [],
            fields:{
                work: null,
                starting_hour: null,
                finishing_hour: null
            }
        }
    },
    mounted(){
        if(this.$route.params.id != undefined){
            
            this.$store.dispatch("getWorkSchedule", this.$route.params.id)
                .then((res) => {
                    this.fields = res.data
            }); 
            
        }else{
            this.fields.work = null
            this.fields.starting_hour = null
            this.fields.finishing_hour = null
        }
        this.fillData()
        
        this.loading = false
    },
    methods:{
        createWorkSchedule(){
            if(this.$route.params.id){
                this.$store.dispatch("updateWorkSchedule", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/works_schedule");
                        }, 1000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }else{
                this.$store.dispatch("createWorkSchedule", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/works_schedule");
                        }, 1000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }
        },
        fillData(){
            if(this.$route.params.id == undefined){
            this.$store.dispatch("onlyWorks")
                .then(res => {
                    this.works = res.data
                })  
            }
        }
    },
    computed: {
        workName(){
            if(this.fields == null)
                return ''
            if(this.fields.work == null)
                return ''
            if(this.fields.work.Descricao == null)
                return ''

            return this.fields.work.Descricao
        },
        crumbs: function() {
            return [
                {
                text: 'Horário de obras',
                disabled: true,
                to: '#',
                exact: true,
                },
            ];
        }
    }
}
</script>