import FrenteObra from "@/api/FrenteObra";

export const frente_obra = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listFrentesObra(params, id) {
            return new Promise((resolve, reject) => {
                FrenteObra.all(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listFrenteObraEquipamentos(params, data) {
            return new Promise((resolve, reject) => {
                FrenteObra.getFrenteObraEquipamentos(data.id, data.funcionarioCodigo, data.place, data.classe)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listFrenteObraEquipamentosTemplate(params) {
            return new Promise((resolve, reject) => {
                FrenteObra.getFrenteObraEquipamentosTemplate()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listFrenteObraClasses(params, data) {
            return new Promise((resolve, reject) => {
                FrenteObra.getFrenteObraClasses(data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}