<template>
  <v-container fluid>    
    <v-progress-linear
        v-if="loading"
        class="mb-8"
        color="primary"
        indeterminate
    ></v-progress-linear>    
    <v-container fluid class="ma-0 pa-0" v-if="!loading">
      <DashboardDefault v-if="active_role == 'default'"/>
      <DashboardEngineer v-if="active_role == 'engenheiro'"/>
    </v-container>
    
    <v-row v-if="$store.getters.hasRole(['super'])">
        <v-col cols="12" class="primary white--text">
          Super admin configuration
        </v-col>
        <v-col cols="12" md="2">
          <v-btn @click="active_role = 'default'">Dashboard Default</v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn @click="active_role = 'engenheiro'">Dashboard Engenheiro</v-btn>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import DashboardDefault from '@/components/Dashboard/DashboardDefault.vue'
import DashboardEngineer from '@/components/Dashboard/DashboardEngineer.vue'

export default{
  name: "Dashboard",
  components:{
    DashboardDefault,
    DashboardEngineer
  },
  data(){
    return{
      active_role: 'default',
      loading: true
    }
  },
  mounted(){
    if(this.$store.getters.hasRole(['engenheiro']))
      this.active_role = 'engenheiro';

    this.loading = false
  }
}
</script>