<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">    
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-apps-box</v-icon> <span>Listagem de atividades</span>
          </h1>
          
          <div style="position:relative">
            <v-container>
              
            </v-container>
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16"
              locale="pt-pt"
            >
              
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import Atividades from "@/api/Atividades.js";
  
  export default {
    components: {
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchAtividades()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "ODC - Listagem de atividades";
    },
    data: () => ({      
      headers: [
        {
          text: 'Código Atividade',
          sortable: true,
          value: 'atividade.codigo_atividade',
        },
        {
          text: 'Atividade',
          sortable: true,
          value: 'atividade.atividade',
        },
        {
          text: 'Código Sub-Atividade',
          sortable: true,
          value: 'codigo_sub_atividade',
        },
        {
          text: 'Sub Atividade',
          sortable: true,
          value: 'sub_atividade',
        },
        {
          text: 'CCS',
          sortable: true,
          value: 'ccs',
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      },
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "categories.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
    },
    methods:{      
      fillBaseData() {
  
        if(localStorage["activities-list-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["activities-list-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchAtividades(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["activities-list-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Atividades.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetAtividades()
      {
        this.filter = {
        };
  
        this.searchAtividades();
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Listagem de atividades',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  