<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit" v-if="!loading">
                <v-row>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="team" name="team" rules="required">
                            <v-text-field
                                label="Nome equipa *"
                                v-model="fields.name"
                                :error-messages="errors"
                                dense outlined
                            />
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Colaborador
                                        </th>
                                        <th class="text-left">
                                            Ação
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item) in computeWorkers" :key="item.id"
                                    >
                                        <td>
                                            <v-autocomplete
                                                :items="workers"
                                                v-model="item.worker_code"
                                                :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                label="Colaborador *"
                                                item-value="Codigo"
                                                dense outlined
                                                class="mt-4"
                                            />
                                        </td>
                                        <td>
                                            <v-btn @click="deleteLine(item)" color="error" icon>
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>         
                </v-row>
                <v-row>           
                    <v-col cols="12" md="6" align="center">
                        <v-btn fab @click="addWorker" class="primary">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" align="center">
                        <v-btn rounded class="success mt-4" :disabled="invalid" type="submit">
                            Gravar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>

        
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Equipa gravada com sucesso
      </DialogSuccess>

    </v-container>
</template>
<script>
import dayjs from 'dayjs'
import Funcionario from '@/api/Funcionario.js'
import TeamAPI from '@/api/Teams.js'
import DialogSuccess from '@/components/UI/DialogSuccess.vue';

export default{
    name: "Team",
    components: {
      DialogSuccess,
    },
    props:{
        method: String,
        team: {
            typeof: Object,
            default: null
        }
    },
    watch:{
        team:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.fields = val

                this.fields.workers.forEach(element => {
                    element.worker_code = element.worker_code.trim()
                });

              
            }
        }
    },
    data(){
        return{
            loading: true,
            success: false,
            fields:{
                id: null,
                name: null,
                workers: []
            },
            workers: [],
            timeTeamAPIChecker: 0
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        deleteLine(item){
            let pos = -1
            let i = 0
            this.fields.workers.forEach(element => {
                if(element.id === item.id){
                    pos = i
                }

                i++
            });

            if(pos != -1)
                this.fields.workers.splice(pos, 1)
        },
        onSuccess(){
            this.$router.push('/configuracoes/equipas')
        },
        fillBaseData(){
            Funcionario.all().then((resp)  =>  {
                this.workers = resp.data

                this.loading = false
            })

            if(this.method == 'create'){
                TeamAPI.nextReference().then((resp) =>  {
                    this.fields.name = resp.data
                })
            }
        },
        submit(){
            this.$refs.form.validate().then((result) => {
                this.loading = true;
          
                if(!result){
                    this.loading = false;
                    return;
                }
        
                let data = {...this.fields};

                TeamAPI[this.method](data, this.fields.id).then(() =>  {
                    this.success = true
                })
            })
        },
        addWorker(){
            this.fields.workers.push({
                id: dayjs().unix() + this.fields.workers.length + '-new',
                worker_code: null,
                worker_name: null
            })
        }
    },
    computed:{
        computeWorkers(){
            return this.fields.workers
        }
    }
}
</script>