import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=287f7eb7&scoped=true"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"
import style0 from "./Edit.vue?vue&type=style&index=0&id=287f7eb7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287f7eb7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBreadcrumbs,VBtn,VCol,VContainer,VDialog,VDivider,VIcon,VRow})
