import API from "./API";

export default class Classe extends API
{
    // This function uses the unique identifier
    static async getClasseFuncionarios(id) {
        return this.get("api/admin/classes/" + id + "/funcionarios/list");
    }

    static async getClasseFuncionariosTemplate() {
        return this.get("api/admin/classes/funcionarios/list/template");
    }

    static async getClasseFuncionariosById(id) {

        return this.get("api/admin/classes/" + id + "/funcionarios/list/ByID");
    }

    static async getClasses(params){
        return this.get('api/admin/classes', {params: params})
    }

    static async getClassesByObraPai(obra_pai){
        return this.get('api/admin/classes/from-obra-pai/' + obra_pai)
    }

    
}