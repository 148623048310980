import Works from "@/api/Works";

export const works = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async updateWorks(params, data) {
            return new Promise((resolve, reject) => {
                Works.update(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async searchWorks(params, data) {
            return new Promise((resolve, reject) => {
                Works.search(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async changeApprovalLevel(params, data){
            return new Promise((resolve, reject) => {
                Works.changeApprovalLevel(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}