import API from "./API";

export default class Categorias extends API
{
    static async all() {
        return this.get("api/admin/categorias/list");
    }

    static async search(filter) {
        return this.get("api/admin/categorias",{params: filter});
    }
}