import API from "./API";

export default class Unidades extends API
{
    static async create(fields) {
        return this.post("api/admin/unidades", fields);
    }
    static async getList() {
        return this.get("api/admin/unidades");
    }
    static async deleteUnidades(id) {
        return this.delete("api/admin/unidades/" + id);
    }

    
    
}