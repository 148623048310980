<template>
    <section class="mt-16">
      <v-row class="mx-1 px-1">
        <v-col cols="12">
          <v-breadcrumbs
              large
              :items="crumbs"
              divider="/"
              light
              :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
            ></v-breadcrumbs>
      
            <h1 class="primary--text">
              <v-icon color="primary">mdi-bulldozer</v-icon> <span>Atualizar Equipa</span>
            </h1>
      
            <div class="mt-16">
              <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
      
              <template v-if="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                  <v-skeleton-loader
                      type="button"
                    ></v-skeleton-loader>
                </v-row>
              </template>
              <Team v-else method="update" :team="team"></Team>
            </div>
          </v-col>
        </v-row>


    </section>
  </template>
  <script>
  import Team from '@/components/Teams/Team'
  import TeamsAPI from "@/api/Teams.js";
  
  export default {
    components: {
      Team
    },
    data: () => ({
        team: {},
        loading:true
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "teams.update"])) {
        this.$router.push('/admin');
      }
  
      TeamsAPI.find(this.$route.params.id).then(({data}) => { this.team = data; this.loading = false;});
    },
    methods:{
      
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Config. de Obra',
            disabled: true,
            exact: true,
          },
          {
            text: 'Edição Equipa',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  