<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="warning white--text">
                        Subempreitadas
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" width="40%">
                                            Trabalho
                                        </th>
                                        <th class="text-left" width="7%">
                                            QTD
                                        </th>
                                        <th class="text-left" width="11%">
                                            Local
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in fields.subempreitadas" :key="item.id"
                                    >
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="subempreitada" name="subempreitada" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="subempreitadas"
                                                    :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Subempreitada"
                                                    :error-messages="errors"
                                                    v-model="item.subempreitada"
                                                />
                                            </validation-provider>
                                        </td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="QTD"
                                                outlined dense
                                                placeholder="Insira qtd."
                                                :disabled="true"
                                                v-model="item.qty"
                                            />
                                        <td>
                                            <v-text-field 
                                                label="Local"
                                                placeholder="Insira Local"
                                                :disabled="true"
                                                v-model="item.local"
                                            />
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteSubempreitada(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                            
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn fab @click="addSubempreitada" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SubEmpreitada from "@/api/SubEmpreitada.js"
import dayjs from 'dayjs'

export default{
    name:"Subcontractors",
    props:{
        fields: Object
    },
    data(){
        return{
            subempreitadas: [],
            loading: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        deleteSubempreitada(id){
            let pos = -1    
            let i = 0
            this.fields.subempreitadas.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.subempreitadas.splice(pos, 1)
        },
        addSubempreitada(){
            this.fields.subempreitadas.push({
                id: dayjs().unix(),
                subempreitada: null,
                qty: 0,
                local: null,
                options: ""
            });
        },
        fillBaseData(){
            SubEmpreitada.all(this.fields.obra_id).then((resp)    =>  {
                this.subempreitadas = resp.data
                
                this.loading = false
            })

        }
    }
}
</script>