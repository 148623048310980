import API from "./API";

export default class ExternalCommunications extends API
{
    static async getExternalCommunications(filter) {
        return this.get("api/admin/external_communications", {params: filter});
    }
    
    static async hasExternalCommInDate(data){
        return this.get("api/admin/external_communications/date/" + data.date + '/' + data.id + '/' + data.work_id);
    }
}