<template>
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">Novo registo</span>
      </v-card-title>
      <v-card-text>
          <v-container class="mt-4">
              <v-row>
                <v-col cols="12">
                  <Select
                      v-model="data.obra"
                      :items="obras"
                      :item-text="item => item.Codigo + ' - ' + item.Descricao"
                      label="Obras *"
                      rules="required"
                      vid="obra"
                      return-object
                      @change="changedWork"
                  />
                </v-col>
                <v-col v-if="frentes.length > 0">
                    <Select
                        v-model="data.frente_obra"
                        :items="frentes"
                        item-text="Descricao"
                        label="Frentes de Obra *"
                        rules="required"
                        vid="frentes_obra"
                        return-object
                        @change="loadEmpreitadas"
                    />
                </v-col>
              </v-row>
              <v-row>
                  <v-col v-if="subEmpreitadas.length > 0">
                      <SelectAutoComplete
                          style="width:100%;"
                          :items="subEmpreitadas"
                          item-text="Descricao"
                          label="Sub-Empreitada *"
                          rules="required"
                          vid="subempreitada"
                          class="basic"
                          v-model="data.subEmpreitada"
                          return-object
                      />
                  </v-col>
              </v-row>
              <v-row>
                  <v-col>
                      <SelectAutoComplete
                          style="width:100%;"
                          :items="unidades"
                          item-text="abreviacao"
                          label="Unidade *"
                          rules="required"
                          vid="unidade"
                          class="basic"
                          v-model="data.unidade"
                          return-object
                      />
                  </v-col>
              </v-row>
          </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          color="success"
          text
          @click="sendData"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import Input from "@/components/UI/Inputs/Input";
import Select from "@/components/UI/Inputs/Select";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import Subempreitadas from '@/api/SubEmpreitada.js'
import Obra from '@/api/Obra.js'
import FrenteObra from "@/api/FrenteObra.js"

export default {
  components:{
    Input,
    Select,
    SelectAutoComplete,
  },
  data(){
      return{
          viewLoaded: false,
          loading: false,
          error: false,
          frentes: [],
          classes: [],
          subEmpreitadas: [],
          obras: [],
          unidades: [],
          switch1: false,
          data:{
            subEmpreitada: null,
            frente_obra: null,
            obra: null,
            classe: null,
            unidade: null
          }
      }
  },
  mounted(){
    this.loadData()
  },
  methods:{
    sendData(){

      let fields = {
        id_sub_empreitada: this.data.subEmpreitada.Id,
        id_frente_obra: this.data.frente_obra == null ? null : this.data.frente_obra.SubEmpId,
        unidade: this.data.unidade.abreviacao,
        obra_id: this.data.obra.Id
      }
      
      this.$store.dispatch("createSubEmpreitadaUnidade", fields)
            .then((res) => {
                
                this.success = true;

                this.$emit('success')


                this.error = false;

                this.loading = false;

            }).catch(err => {
                console.log(err)
            });
    },
    changedWork(){
      let ObraPai = null
      // Vou obter o ObraPai que é o código da obra, para obter a sua frente
      // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
      // Vamos à tabela ObraFrente ver quais as obras que têm esse código
      this.obras.forEach(element => {
          if(element.Id == this.data.obra.Id)
              ObraPai = element.Codigo
      });

      FrenteObra.all(ObraPai).then((resp) =>  {
          this.frentes = resp.data
      })     

      Subempreitadas.all(this.data.obra.Id).then((resp)  =>  {
        this.success = true;
        this.subEmpreitadas = []
        this.subEmpreitadas = resp.data


        this.error = false;

        this.loading = false;
      })
    },
    async loadData(){   
      let data = {
          tipo: 'O'
      }
      
      await Obra.all(data).then((resp)   =>  {
          this.obras = resp.data;
          
      });

      this.$store.dispatch("getListUnidades")
            .then((res) => {
                
                this.success = true;
                this.unidades = []
                this.unidades = res.data


                this.error = false;

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });

     

            
    },
    loadEmpreitadas(){
      Subempreitadas.getSubempreitadasByFrente(this.data.frente_obra.SubEmpId).then((resp)  =>  {
        this.success = true;
        this.subEmpreitadas = []
        this.subEmpreitadas = resp.data


        this.error = false;

        this.loading = false;
      })
    }
  }
}

</script>
