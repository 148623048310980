import Templates from "@/api/Templates";

export const templates = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async create_template(params, data) {
            return new Promise((resolve, reject) => {
                Templates.create(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async edit_template(params, data) {
            return new Promise((resolve, reject) => {
                Templates.edit(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getByCodes(params, data) {
            return new Promise((resolve, reject) => {
                Templates.getByCodes(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getById(params, id) {
            return new Promise((resolve, reject) => {
                Templates.getById(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async searchTemplates(params, data) {
            return new Promise((resolve, reject) => {
                Templates.search(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteTemplate(params, id) {
            return new Promise((resolve, reject) => {
                Templates.deleteTemplate(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}