<template>
    <section class="mt-16">        
        <div style="position:relative">
            <v-container>
                <v-speed-dial
                absolute
                v-model="speedDial"
                top
                right
                direction="bottom"
                :open-on-hover="true"
            >
            <template v-slot:activator>
                <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                    >
                    <v-icon v-if="speedDial">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-dots-vertical
                    </v-icon>
                    </v-btn>
                </template>
                <span>Opções</span>
                </v-tooltip>
            </template>
            <v-tooltip right color="info" v-if="true">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                >
                    <v-icon>mdi-filter</v-icon>
                </v-btn>
                </template>
                <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    @click="openModalNewTask"
                    v-if="$store.getters.hasPermission(['super', 'guarantees-tasks.create'])"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                </template>
                <span>Registar</span>
            </v-tooltip>
            </v-speed-dial>
        </v-container>
        
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    class="mt-16"
                    locale="pt-pt"
                    item-key="date"
                >
                <template v-slot:item.status="{item}">
                  {{ item.status == 'OPEN' ? 'Aberto' : 'Fechado' }}
                </template>
                <template v-slot:item.actions="{item}">
                  <IconView
                    class="mr-2"
                    @click="viewTask(item)"
                  />
                  <IconRemove
                    v-if="$store.getters.hasPermission(['super', 'guarantees-tasks.delete'])"
                    class="mr-2"
                    @on-submit="deleteTasks(item)"
                  />
                </template>
        
                    
                </v-data-table>
            </div>
            <v-navigation-drawer
                fixed
                right
                v-model="searching"
                hide-overlay
                width="520"
                class="pt-6"
            >
            <v-list>
                <v-list-item>
                <v-btn fab small color="secondary" @click="searching = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
    
                <v-btn absolute right small color="error" @click="resetTasks">
                    Limpar Campos
                </v-btn>
                </v-list-item>
            </v-list>
            <v-list class="pt-10">
                <v-form @submit.prevent="searchTasks">
                
                  <v-list-item>
                    <v-text-field
                      v-model="filter.description"
                      prepend-inner-icon="mdi-account-details"
                      label="Descrição"
                      clearable
                    ></v-text-field>
                  </v-list-item>
                
                  <v-list-item>
                    <v-autocomplete
                        :items="[{id: 'OPEN', text: 'Aberto'}, {id: 'CLOSED', text: 'Fechado'}]"
                        v-model="filter.status"
                        label="Estado"
                        item-value="id"
                        item-text="text"
                      />
                  </v-list-item>
                  
                <v-row
                    class="mt-10"
                    align="center"
                    justify="space-around"
                >
                <v-btn
                    color="primary"
                    type="submit"
                >
                    Pesquisar
                </v-btn>
                </v-row>
                </v-form>
            </v-list>
        </v-navigation-drawer>
        <v-dialog width="850px" v-model="createTaskModal">
          <v-card>
            <v-card-title class="primary white--text">
              Adicionar tarefa
            </v-card-title>
            <v-card-text class="mt-4">
              <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="addTask" class="mb-8">
                  <v-row>
                    <v-col cols="12" md="6">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" vid="Data de tarefa" name="Data de tarefa" rules="required">
                            <v-text-field
                              v-model="workTask.date"
                              label="Data da tarefa *"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              :error-messages="errors"
                              outlined dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="workTask.date"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <validation-provider v-slot="{ errors }" vid="Estado" name="Estado" rules="required">
                        <v-autocomplete
                          :items="[{id: 'OPEN', text: 'Aberto'}, {id: 'CLOSED', text: 'Fechado'}]"
                          v-model="workTask.status"
                          :error-messages="errors"
                          label="Estado *"
                          dense outlined
                          item-value="id"
                          item-text="text"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="12">
                      <validation-provider v-slot="{ errors }" vid="Descricao" name="Descricao" rules="required">
                          <v-textarea
                            v-model="workTask.description"
                            outlined dense
                            prepend-inner-icon="mdi-account-details"
                            label="Descrição *"
                            clearable
                            :error-messages="errors"
                          ></v-textarea>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" align="center">
                      <v-btn :disabled="workTask.date == null || workTask.description == null" rounded type="submit" class="primary">Gravar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </v-dialog>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Tarefa gravada com sucesso!
      </DialogSuccess>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/UI/IconRemove.vue';
  import IconView from '@/components/UI/IconView.vue';
  import GarantiasTarefas from "@/api/GarantiasTarefas.js";  
  import DialogSuccess from '@/components/UI/DialogSuccess.vue';
  
  export default {
    components: {
      IconRemove,
      IconView,
      DialogSuccess
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchTasks()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      menu2: false,
      workTask:{
        id: null,
        date: null,
        description: null,
        garantias_obras_id: null,
        status: 'OPEN'
      },
      createTaskModal: false,
      method: 'create',
      headers: [
        {
          text: 'Data',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Descrição',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Estado',
          sortable: true,
          value: 'status',
        },
        { 
          text: 'Opções', 
          value: 'actions', 
          sortable: false 
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      success: false,
      filter: {
        name: null,
        display_name: null,
        work_id: null
      },
      users:[]
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "guarantees-tasks.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      onSuccess(){
        this.searchTasks()
        this.success = false
        this.createTaskModal = false
      },
      openModalNewTask(){
        this.workTask = {
          id: null,
          date: null,
          description: null,
          garantias_obras_id: null,
          status: 'OPEN'
        }
        this.createTaskModal = true
      },
      viewTask(item){
        this.workTask = item
        this.createTaskModal = true
      },
      addTask(){
        this.workTask.garantias_obras_id = this.$route.params.id

        this.method = this.workTask.id == null ? 'create' : 'update'

        GarantiasTarefas[this.method](this.workTask, this.workTask.id).then(() => {
  
          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      },
      fillBaseData() {
  
        if(localStorage["guarantees-obras-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["guarantees-obras-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchTasks(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};

        filter.work_id = this.$route.params.id
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage, 
          work_id: this.$route.params.id
        };
      
        Object.assign(request, filter);
  
        localStorage["guarantees-tasks-obras-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });

        GarantiasTarefas.search(request).then(response => {
          
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });

          this.loading = false;
  
        });
      },
      resetTasks()
      {
        this.filter = {};
  
        this.searchTasks();
      },
      deleteTasks(item){
        GarantiasTarefas.deleteTask(item.id)
          .then(() => {
            this.searchTasks();
          });
      },
    }
  };
  </script>
  