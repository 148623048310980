<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text mb-6">Gestão de Obras do Utilizador</h2>

                <Form
                    ref="form"
                    submitText="Gravar"
                    @submited="updateUser"
                    :loading="loading"
                >
                    <v-row>
                        <v-col cols="12" md="6">
                            <Input
                                v-model="fields.name"
                                type="name"
                                label="Nome"
                                rules="required"
                                vid="name"      
                                disabled                          
                            />
                        </v-col>

                        <v-col cols="12" md="6">
                            <Input
                                v-model="fields.email"
                                type="email"
                                label="Email"
                                rules="required|email"
                                vid="email"
                                teste="dasda"
                                disabled
                            />
                        </v-col>

                        <v-col cols="12">
                            <SelectAutoComplete
                                v-model="fields.obra"
                                :items="obras"
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                label="Obras"
                                :multiple="true"
                                rules="required"
                                vid="obra"
                                @change="changeAutoComplete"
                            />
                        </v-col>

                        <v-col cols="12" v-if="frentes_obra.length > 0">
                            <MultipeSelect
                                v-model="fields.frentes_obra"
                                :items="frentes_obra"
                                item-text="Descricao"
                                item-value="ID"
                                label="Frentes de Obra"
                                rules="required"
                                vid="frentes_obra"
                            />
                        </v-col>
                    </v-row>
                    
                    
                </Form>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MenuTabs from "@/components/UI/Tabs/MenuTabs";

    import Form from "@/components/UI/Forms/Form";

    import Input from "@/components/UI/Inputs/Input";

    import MultipeSelect from "@/components/UI/Inputs/MultipeSelect";

    import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";

    import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

    import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";

export default {
    components: {
      MenuTabs,
      Form,
      Input,
      SuccessSnackbar,
      ErrorSnackbar,
      MultipeSelect,
      SelectAutoComplete
    },
    data: () => ({
        fields: {
            name: null,
            email: null,
            frentes_obra: [],
            obra:[]
        },
        loading: false,
        success: false,
        error: null,
        frentes_obra: [],
        obras: [],
        error_message: "",
    }),
    mounted: function() {
        this.$store.dispatch("listObras")
            .then(res => {
                this.obras = res.data
            })    

        this.$store.dispatch("getUser", this.$route.params.id)
            .then((res) => {
                this.fields.name = res.data.name;

                this.fields.email = res.data.email;

                this.fields.obra = res.data.obras.map(item =>  item.Id)

                this.changeAutoComplete(this.fields.obra)

                //this.fields.frentes_obra = res.data.frentes_obra.map(item => item.ID)
            });   
    },
    methods: {
        changeAutoComplete(i){
            let codigo = []
            this.obras.forEach(element => {
                    i.forEach(c => {
                        if(element.Id == c)
                            codigo.push(element.Codigo)
                    });
            });

            this.$store.dispatch("listFrentesObra", i)
            .then(res => {
                let returner = []
                let data_array = res.data;
                data_array.forEach(element => {
                    if(codigo.includes(element.ObraPai))
                        returner.push(element)
                });

                this.frentes_obra = returner
            })  
        },
        updateUser: function() {

            this.loading = true;

            if(this.frentes_obra.length <= 0){
                this.fields.frentes_obra = []
            }
            
            let data = {
                id: this.$route.params.id,
                fields: this.fields
            }

            this.$store.dispatch("updateUserObras", data)
                .then(res => {

                    this.success = true;

                    let self = this;

                    setTimeout(function() {
                        self.$router.push("/super/utilizadores");
                    }, 2000)

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.status == 422){

                        this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                    }

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    }
}
</script>

<style scoped>

</style>