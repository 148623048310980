import API from "./API";

export default class WorkerCard extends API
{
    static async getWorkerCards(filter) {
        return this.get("api/admin/worker_cards", {params: filter});
    }

    static async createWorkerCard(fields) {
        return this.post("api/admin/worker_cards", fields);
    }

    static async getWorkerCard(id) {
        return this.get("api/admin/worker_cards/" + id);
    }

    static async updateWorkerCard(fields, id) {
        return this.put("api/admin/worker_cards/" + id, fields);
    }

    static async deleteWorkerCard(id){
        return this.delete("api/admin/worker_cards/" + id);
    }
    
}