<template>
  <v-container fill-height fluid class="bg-image">
      <v-row justify="center" align="center">
          <v-col cols="12" md="4">
              <v-card
                :loading="loading"
                class="mx-auto my-12 align-self-center pa-5"
              >
                <template slot="progress">
                  <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-row justify="center">
                  <v-avatar height="105" width="300" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/fundo_conduril.png"
                    >
                  </v-avatar>
                </v-row>

                <v-card-title class="primary--text">ODC - Recuperação Password</v-card-title>

                <v-card-text>
                  <Form ref="password" :disabled="loading" @submited="submit" submitText="Reset">
                    <v-row
                      align="center"
                      class="mx-0"
                      no-gutters
                    >
                      <v-col cols="12">
                        <Input
                          label="Password"
                          type="password"
                          placeholder="Insira Password"
                          rules="required|small_letter|password_size|special_char_1|capital_letter|one_number"
                          vid="password"
                          v-model="fields.password"
                        />
                      </v-col>

                      <v-col cols="12">
                        <Input
                          label="Confirmação de Password"
                          type="password"
                          placeholder="Insira Password"
                          rules="required|min:8"
                          vid="password_confirmation"
                          v-model="fields.password_confirmation"
                        />
                      </v-col>
                    </v-row>
                  </Form>
                </v-card-text>
                <v-row justify="center mt-5">
                  <div class="col-12 text-center pb-0"><p class="mb-0">Powered By</p></div>
                  <v-avatar height="45" width="140" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/logo.png"
                    >
                  </v-avatar>
                </v-row>
              </v-card>
          </v-col>
      </v-row>

      <ErrorSnackbar v-model="error" :message="error_message"/>
  </v-container>
</template>
<script>
import OTP from "@/components/UI/Inputs/OTP";
import Form from "@/components/UI/Forms/Form";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Input from "@/components/UI/Inputs/Input";

export default {
  components: {
    OTP,
    Form,
    ErrorSnackbar,
    Input
  },
  data: () => ({
    loading: false,
    fields: {
      id: null,
      password: null,
      password_confirmation: null,
    },
    error: false,
    error_message: "",
  }),
  methods: {
    submit: function() {
      this.loading = true;

      this.fields.id = this.$route.params.id;

      this.$store.dispatch("updateUserPassword", this.fields)
          .then(() => {
            this.$router.push('/');
          })
          .catch(err => {

            console.log(err);

            if(err.response.status == 422) {

              this.$refs.password.setErrors(err.response.data.errors);
              
              return;
            }

            this.error = true;

            this.error_message = err.response.data.message;

            this.loading = false;
          });
    }
  }
}
</script>
<style scoped>
.bg-image {
    background-image: url("@/assets/fundo.jpg");
    background-position: center;
    background-size: cover;
  }
</style>