<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="warning white--text">
                        Materiais
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" width="15%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="15%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="40%">
                                            Artigo
                                        </th>
                                        <th class="text-left" width="7%">
                                            Un
                                        </th>
                                        <th class="text-left" width="7%">
                                            QTD
                                        </th>
                                        <th class="text-left" width="11%">
                                            Local
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in computeMateriais" :key="index+8525"
                                    >
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="|">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="materiais"
                                                    :item-text="item => item.artigo + ' - ' + item.descricao"
                                                    item-value="id"
                                                    label="Material"
                                                    :error-messages="errors"
                                                    v-model="item.material"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <v-autocomplete
                                                class="mt-4"
                                                outlined dense
                                                :items="materiais"
                                                :item-text="item => item.unidade_base"
                                                item-value="id"
                                                label="Unidade"
                                                disabled
                                                v-model="item.material"
                                            />
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteMaterial(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                            
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn fab @click="addMaterial" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Materiais from "@/api/Materiais.js"
import Classe from '@/api/Classe.js'
import dayjs from 'dayjs'

export default{
    name:"Materials",
    props:{
        fields: Object,
        frentes_obra: Array,
        hideFrentes: Boolean,
        template_classes: Array
    },
    data(){
        return{
            classes: [],
            materiais: [],
            loading: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{        
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        showFrente(item){
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true


            return false
        },
        async changeFrenteObraV2(item){

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }


                await Classe.getClasses(data).then((resp) =>  {
                    item.available_classes = resp.data

                    if(item.available_classes.length == 0){
                        item.classeNeeded = false
                    }else item.classeNeeded = true
                    
                })

                
            }
        },
        deleteMaterial(id){
            let pos = -1    
            let i = 0
            this.fields.materiais.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.materiais.splice(pos, 1)
        },
        addMaterial(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            this.fields.materiais.push({
                id: dayjs().unix(),
                material: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                un: "-",
                qty: 0,
                local: null,
                options: ""
            });
        },
        async fillBaseData(){
            let materialsData ={
                id_frente_obra: this.fields.frente_id,
                id_obra_classe: this.fields.classe_id == null ? null : '1',
                obra_id: this.fields.obra_id
            }

            await Materiais.getList(materialsData).then((resp)    =>  {
                this.materiais = resp.data
            })

            if(this.hideFrentes)
                this.classes = this.template_classes
                

            this.loading = false
        },
        getUnity(i){
            if(i==null)
                return "-"

            if(i.product == null)
                return '-'
                
            return i.product.unidade || "-"
        },
    },
    computed:{
        computeMateriais(){
            let returner = []

            this.fields.materiais.forEach(element => {
                if(element.classe_id != null){
                    if(this.frentes_obra.length > 0){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.template_classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        }
    }
}
</script>