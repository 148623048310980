<template>
    <v-container fluid>
        <v-row v-if="!$store.getters.hasRole(['parque'])">
            <v-col cols="12">
                <v-toolbar
                    dark
                    color="warning"
                    class="rounded-lg"
                    >
                    <v-toolbar-title class="d-none d-lg-block">Observações de Mão de Obra:</v-toolbar-title>
                    <v-toolbar-title class="d-block d-lg-none text-caption">Observações de Mão de Obra:</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="fields.obsMO"
                    outlined dense
                    label="Observações"
                    hint="Escreva as suas observações relativas à mão de obra"
                    persistent-hint
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-toolbar
                    dark
                    color="warning"
                    class="rounded-lg"
                    >
                    <v-toolbar-title class="d-none d-lg-block">Observações de Equipamentos:</v-toolbar-title>
                    <v-toolbar-title class="d-block d-lg-none text-caption">Observações de Mão de Obra:</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="fields.obsEQ"
                    outlined dense
                    label="Observações"
                    hint="Escreva as suas observações relativas aos equipamentos"
                    persistent-hint
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-toolbar
                    dark
                    color="warning"
                    class="rounded-lg"
                    >
                    <v-toolbar-title class="d-none d-lg-block">Observações Obra/Trabalhos Efetuados:</v-toolbar-title>
                    <v-toolbar-title class="d-block d-lg-none text-caption">Observações Obra/Trabalhos Efetuados:</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12" v-for="(item,index) in fields.work_order_obs" :key="index + 85552">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="item.work_order_service"
                            outlined dense
                            label="Nº Autorização Trabalho/Ordem Serviço"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="item.description"
                            outlined dense
                            label="Descrição"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" justify="center" align="center">
                <v-btn :disabled="checkDisabledLines" @click="addNewObsLine" fab class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default{
    name: "Observations",
    props:{
        fields: Object
    },
    methods:{
        addNewObsLine(){
            this.fields.work_order_obs.push({
                work_order_service: null,
                description: null
            })
        }
    },
    computed:{
        checkDisabledLines(){
            let res = false

            this.fields.work_order_obs.forEach(element => {
                if(element.work_order_service == null)
                    res = true

                if(element.description == null)
                    res = true
            });

            return res
        }
    }
}
</script>