import Materiais from "@/api/Materiais";

export const materiais = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async createMaterials(params, fields) {
            return new Promise((resolve, reject) => {
                Materiais.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getMaterials(params, fields) {
            return new Promise((resolve, reject) => {
                Materiais.getList(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getAll(params, data) {
            return new Promise((resolve, reject) => {
                Materiais.getAll(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteMaterials(params, fields){
            return new Promise((resolve, reject) => {
                Materiais.deleteMaterials(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}