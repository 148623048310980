import API from "./API";

export default class Materiais extends API
{
    static async create(fields) {
        return this.post("api/admin/materiais", fields);
    }

    static async getList(data) {
        return this.get("api/admin/materiais/obter-materiais", {params: data});
    }

    static async getListMateriaisObra(data){
        return this.get('api/admin/materiais/getMateriaisObra', {params: data});
    }

    static async getAll(data) {
        return this.get("api/admin/materiais/list", {params: data});
    }

    static async deleteMaterials(id) {
        return this.delete("api/admin/materiais/" + id);
    }

    
    
}