import API from "./API";

export default class FrenteObra extends API
{
    static async all(id) {
        let params = {
            obra: id
        }
        return this.get("api/admin/frentes-obra/list", {params:params});
    }

    static async getFrenteObraEquipamentos(id, funcionarioCodigo, place, classe) {

        let params = {
            codigo: funcionarioCodigo,
            place: place,
            classe: classe
        }

        return this.get("api/admin/frentes-obra/" + id + "/equipamentos/list", {params: params});
    }

    static async getFrenteObraEquipamentosTemplate() {
        return this.get("api/admin/frentes-obra/equipamentos/list/template");
    }

    static async getFrenteObraClasses(id) {

        return this.get("api/admin/frentes-obra/" + id + "/classes/list");
    }
}