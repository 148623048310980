<template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Novo registo</span>
      </v-card-title>
      <v-card-text>
        <v-container class="mt-4">
            <v-row>
                <v-col>
                    <Input 
                        label="Nome"
                        placeholder="Insira um nome"
                        v-model="data.nome"
                        rules="required"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <Input 
                        label="Abreviatura"
                        placeholder="Insira uma abreviação"
                        v-model="data.abreviacao"
                        rules="required"
                    />
                </v-col>
            </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          color="success"
          text
          @click="enviar"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import Input from "@/components/UI/Inputs/Input";

export default {
  components:{
    Input
  },
  data(){
      return{
          data:{
            nome: '',
            abreviacao: ''
          }
      }
  },
  methods:{
    enviar(){
        this.$emit('new-unit', this.data)
    }
  }
}

</script>
