<template>
    <v-row>
        <v-col cols="12" md="auto">
            <v-text-field
                v-bind="$attrs"
                :label="textLabel == null ? 'ID Funcionário' : textLabel"
                v-model="userID"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn
                elevation="0"
                fab
                x-small
                color="success"
                @click="sendCode"
            >
                <v-icon small>
                    mdi-eye
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>


<script>
export default {
    props: {
        funcionarios: Array,
        userName: String,
        textLabel:{
            typeof: String,
            default: null
        },
        selectedFunc: {
            typeof: Object,
            default: null
        }
    },
    data: () => ({
        userID: null,
        funcionario: null
    }),
    methods:{
        sendCode(){
            let parent = this
            this.funcionarios.forEach(func => {

                if(func.Codigo == parent.userID){
                    parent.funcionario = func
                }
            });

            this.$emit('input', this.funcionario)
        }
    },
    watch:{
        userName(u){
            this.userName = u
        },
        selectedFunc:{
            immediate: true,
            handler(f){
                if(f != null)
                    this.userID = f.Codigo

            }
        }
    }
}
</script>