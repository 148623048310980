<template>
    <v-container fill-height fluid class="bg-image">
      <v-row justify="center" align="center">
          <v-col cols="12" md="4">
              <v-card
                :loading="loading"
                class="mx-auto my-12 align-self-center pa-5"
              >
                <template slot="progress">
                  <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-row justify="center">
                  <v-avatar height="105" width="300" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/fundo_conduril.png"
                    >
                  </v-avatar>
                </v-row>

                <v-card-title class="primary--text">ODC - Verificação Conta</v-card-title>

                <v-card-text>
                  <v-row>                    
                    <v-col cols="12" align="center">
                        <v-btn
                            color="#6E7A90"
                            @click="microsoftLogin"
                        >
                            <div v-if="!$msal.isAuthenticated()">
                                <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
                            </div>
                            <div v-else>
                                <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
                            </div>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align="center" v-if="$msal.isAuthenticated()">
                        <v-btn
                        @click="$msal.signOut()"                                
                        >
                        <v-icon>mdi-logout</v-icon>
                        Logout
                        </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-row justify="center mt-5">
                  <div class="col-12 text-center pb-0"><p class="mb-0">Powered By</p></div>
                  <v-avatar height="45" width="140" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/logo.png"
                    >
                  </v-avatar>
                </v-row>
              </v-card>
          </v-col>
      </v-row>

      <ErrorSnackbar v-model="error" :message="error_message"/>
  </v-container>
</template>
<script>
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Input from "@/components/UI/Inputs/Input";
import User from '@/api/User.js'

import { msalMixin } from 'vue-msal'; 

export default {
  components: {
    ErrorSnackbar,
    Input
  },
  mixins: [msalMixin],
  data: () => ({
    loading: false,
    error: false,
    error_message: "",
  }),
  methods: {
    microsoftLogin(){
        if(this.$msal.isAuthenticated()){
          if(this.$cookies.get("microsoft_register_cookie_id") == null){
            this.error_message = "O Token expirou. Por favor volte ao email e click no link fornecido para reiniciar o processo"
            this.error = true
            return
          }

          let data = {
            id: this.$cookies.get("microsoft_register_cookie_id"),
            microsoft_identifier: this.$msal.data.user.idToken.oid
          }

          User.verifyMicrosoft(data).then((resp)   =>  {
              this.$router.push('/')
          })
        }else this.$msal.signIn()
    }
  }
}
</script>
<style scoped>
.bg-image {
    background-image: url("@/assets/fundo.jpg");
    background-position: center;
    background-size: cover;
  }
</style>