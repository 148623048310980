import PDEq from "@/api/PDEq";

export const pdeq = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async createPDEq(params, fields) {
            return new Promise((resolve, reject) => {
                PDEq.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteEquipmentLine(params, id) {
            return new Promise((resolve, reject) => {
                PDEq.deleteEquipmentLine(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getPDEq(params, id) {
            return new Promise((resolve, reject) => {
                PDEq.getPD(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updatePDEq(params, data) {
            return new Promise((resolve, reject) => {
                PDEq.updatePD(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deletePDEq(params, data) {
            return new Promise((resolve, reject) => {
                PDEq.deletePD(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async searchPDEq(params, data) {
            return new Promise((resolve, reject) => {
                PDEq.search(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}