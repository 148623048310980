<template>
  <v-card
    class="mx-auto"
    elevation="24"
  >
    <v-toolbar
      color="primary"
      dark
    >
     

      <v-toolbar-title>
        <v-icon>mdi-bell-badge</v-icon> Notificações 
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mr-2" color="success">mdi-check-outline</v-icon><small>Marcar como lida</small>
      <v-icon color="danger" class="ml-2 mr-2">mdi-eye</v-icon><small>Ver</small>
      
    </v-toolbar>

    <div class="text-center" v-if="messages.length == 0">
      <p class="caption mt-4">(Não existem notificações para mostrar)</p>
    </div>

    <v-list two-line v-else>
      <v-list-item-group
        v-model="selected"
        active-class="primary--text"
        multiple
      >
        <template v-for="(m, index) in messages">
          
          <v-list-item v-if="m.encarregado == 'gestor_garantias_obra'">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="m.title"></v-list-item-title>
                <v-list-item-subtitle v-text="m.text"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item v-else>
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="m.text"></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="m.subtitle"
                ></v-list-item-subtitle>

                <v-list-item-subtitle v-text="m.encarregado"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="m.diaria.data"></v-list-item-subtitle>
                <v-list-item-subtitle v-if="m.diaria.frente_obra != ''" v-text="m.diaria.frente_obra"></v-list-item-subtitle>
              </v-list-item-content>
              

              <v-list-item-action>
                <v-list-item-action-text v-text="m.title"></v-list-item-action-text>
              </v-list-item-action>

              
              <v-list-item-action v-if="m.subtitle == ''" class="ml-2">
                <v-list-item-action-text><v-icon  color="success" @click="markReaded(m)">mdi-check-outline</v-icon></v-list-item-action-text>
              </v-list-item-action>
              <v-list-item-action v-if="m.subtitle == ''">
                <v-list-item-action-text><v-icon @click="goTo(m)">mdi-eye</v-icon></v-list-item-action-text>
              </v-list-item-action>
              
            </template>
            

          </v-list-item>


          <v-divider
            v-if="index < messages.length - 1"
            :key="index"
          ></v-divider>
        </template>
        
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data(){
    return{
      selected:[]
    }
  },
  mounted(){
    console.log(this.messages)
  },
  methods:{
    markReaded(ob){
      this.$store.dispatch("markAsViewed", ob.id)
        .then(response => {
            this.$emit('refresh')
        })
        .catch(err => {
            console.log(err)
        });
    },
    goTo(ob){
      console.log(ob)
      if(ob.diary_creation_role == "encarregado"){
            this.$router.push("/diarios/encarregado/editar/" + ob.extraLink.split('/')[1] + '/' + ob.id_user_creator);
            return
        }else if(ob.diary_creation_role == 'manobrador'){
            
            this.$router.push("/diarios/manobrador/" + ob.extraLink.split('/')[1] + '/' + ob.id_user_creator + '/eq');
            return
        }else return
    }
  },
    props:{
        messages: Array
    }
}
</script>