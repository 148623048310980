import Funcionario from "@/api/Funcionario";

export const funcionario = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listFuncionarios() {
            return new Promise((resolve, reject) => {
                Funcionario.all()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listEquipamentosFromFuncionario(params, id){
            return new Promise((resolve, reject) => {
                Funcionario.getListEquipamentosFromFuncionario(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}