<template>
    <v-alert class="modal"
        shaped
      prominent
      type="error"
    >
      {{message}}
    </v-alert>
</template>
<script>
export default {
    props:{
        message: String
    }
}
</script>
<style>
.modal {
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 9999;
  
}
</style>