import EntidadePessoal from "@/api/EntidadePessoal";

export const entidade_pessoal = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listEntidadesPessoal() {
            return new Promise((resolve, reject) => {
                EntidadePessoal.all()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}