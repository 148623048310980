<template>
    <v-row>
            <v-col
            cols="12"
            md="6"
            >
                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="data1"
                        label="Data inicial"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="data1"
                    @input="menu1 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="data2"
                        label="Data final"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="data2"
                    @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-btn v-if="!hide_buttons"
                fab
                dark
                outlined
                small
                color="primary"
                class="mt-4 mb-4"
                @click="enviarData"
            >
                <v-icon small>mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="!hide_buttons"
                fab
                outlined
                dark
                small
                color="red"
                class="mt-4 mb-4 ml-4"
                @click="$emit('reset-dates')"
            >
                <v-icon small>mdi-undo</v-icon>
            </v-btn>
            <MiniAlerts v-if="showAlert" :message="message" />
        </v-row>
</template>
<script>
import MiniAlerts from '@/components/Alerts/MiniAlerts.vue'
export default {
    data: () => ({
        menu1: false,
        menu2: false,
        data1: null,
        data2: null,
        showAlert: false,
        message: "Data inválida. Por favor verifique se preencheu corretamente os campos"
    }),
    props:{
        start_date: null,
        end_date: null,
        hide_buttons: {
            typeof: Boolean,
            default: false
        }
    },
    mounted(){
        if(this.start_date != null)
            this.data1 = this.start_date
            
        if(this.end_date != null)
            this.data2 = this.end_date
    },
    methods: {
        enviarData() {
            if (this.data1 == null)
                this.showAlert = true

            if (this.data2 == null)
                this.showAlert = true

            if (new Date(this.data1) > new Date(this.data2))
                this.showAlert = true
            

            let flag = this.showAlert
            setTimeout(() => {
                this.showAlert = false
            }, "3000")

            if(!flag)
                this.$emit("filter-data", this.data1, this.data2);
        }
    },
    components: { MiniAlerts }
}
</script>