import PDEnc from "@/api/PDEnc";

export const pdenc = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async checkPDencExistence(params, fields){
            return new Promise((resolve, reject) => {
                PDEnc.checkExistence(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async createPDEnc(params, fields) {
            return new Promise((resolve, reject) => {
                PDEnc.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updatePDEnc(params, data) {
            return new Promise((resolve, reject) => {
                PDEnc.update(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getPDEnc(params, data) {
            return new Promise((resolve, reject) => {
                PDEnc.getPDEnc(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getPDEncHalfLoader(params, data) {
            return new Promise((resolve, reject) => {
                PDEnc.getPDEncHalfLoader(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getPDEquipmentERP(params, data) {
            return new Promise((resolve, reject) => {
                PDEnc.getPDEquipmentERP(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },        
        async getPDLaborERP(params, data) {
            return new Promise((resolve, reject) => {
                PDEnc.getPDLaborERP(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}