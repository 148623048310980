var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"10","offset-md":"1"}},[_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"large":"","items":_vm.crumbs,"divider":"/","light":""}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',[_vm._v("mdi-magnifier")]),_c('span',[_vm._v("Templates")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container'),_c('v-data-table',{class:this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16',attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.frente_obra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Frente == null ? '-' : item.Frente.Descricao)+" ")]}},{key:"item.classe_obra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Classe == null ? '-' : item.Classe.Descricao)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_id == null ? '-' : item.user.funcionario_codigo)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[(_vm.$store.getters.hasPermission(['super', 'templates.update']))?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","outlined":"","x-small":"","color":"info"},on:{"click":function($event){return _vm.viewTemplate(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1):_vm._e(),(_vm.$store.getters.hasPermission(['super', 'templates.delete']))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteTemplate(item)}}}):_vm._e()],1)],1)]}}])})],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Template removido com sucesso! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }