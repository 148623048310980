<template>
    <section>
        <v-row class="d-none d-lg-block mx-1 px-1">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <section class="mt-16" v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Consulta diárias por Dia e Obra</span>
                    </h1>
            
                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                    <AggregatorView method="update" :diary="diary"></AggregatorView>
                    </div>
                </section>
            </v-col>
        </v-row>
        <v-row class="d-block d-lg-none">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <section class="mt-16" v-if="!loading">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                    ></v-breadcrumbs>
            
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Edição Diário</span>
                    </h1>
            
                    <div class="mt-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
            
                    <template v-if="loading">
                        <v-row>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                            <v-skeleton-loader
                            type="text"
                            ></v-skeleton-loader>
                        </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-skeleton-loader
                            type="button"
                            ></v-skeleton-loader>
                        </v-row>
                    </template>
                    <AggregatorView method="update" :diary="diary"></AggregatorView>
                    </div>
                </section>
            </v-col>
        </v-row>
    </section>
</template>
<script>
import AggregatorView from '@/components/Diaries/Aggregator/AggregatorDiary.vue'
import Diaries from "@/api/Diaries.js";

export default {
  components: {
    AggregatorView
  },
  data: () => ({
      diary: {},
      loading:true
  }),
  mounted(){  
    let params={
            day: this.$route.params.day,
            work_id: this.$route.params.work_id,
            type: 'encarregado'
      }

      Diaries.aggregator(params).then((resp)  =>  {
          this.diary = resp.data
          this.loading = false
      })            

  },
  methods:{
    refresh(){
      
    }
  },
  computed: {
    crumbs: function() {
      return [
          {
              text: 'Consulta diárias por Dia e Obra',
              disabled: true,
              to: '#',
              exact: true,
          },
      ];
    }
  }
};
</script>
