var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{staticStyle:{"border":"0px"},attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Código","name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Código *","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.fields.code),callback:function ($$v) {_vm.$set(_vm.fields, "code", $$v)},expression:"fields.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Nome","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Nome *","clearable":"","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Cliente","name":"Cliente","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.clients,"prepend-inner-icon":"mdi-account","label":"Cliente","item-text":"name","item-value":"name","outlined":"","dense":""},model:{value:(_vm.fields.cliente),callback:function ($$v) {_vm.$set(_vm.fields, "cliente", $$v)},expression:"fields.cliente"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Data de Fecho","name":"Data de Fecho","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de fecho","outlined":"","dense":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.data_de_fecho),callback:function ($$v) {_vm.$set(_vm.fields, "data_de_fecho", $$v)},expression:"fields.data_de_fecho"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fields.data_de_fecho),callback:function ($$v) {_vm.$set(_vm.fields, "data_de_fecho", $$v)},expression:"fields.data_de_fecho"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('validation-provider',{attrs:{"vid":"Link Sharepoint","name":"Link Sharepoint","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Link Sharepoint","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.fields.sharepoint_link),callback:function ($$v) {_vm.$set(_vm.fields, "sharepoint_link", $$v)},expression:"fields.sharepoint_link"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.fields.sharepoint_link == null,"small":"","fab":""},on:{"click":_vm.openSharepoint}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)],1),(_vm.method == 'update')?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Tarefas ")]),_c('v-card-text',{staticClass:"mt-n16"},[_c('Tasks',{attrs:{"work":_vm.work}})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Obra gravada com sucesso! ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }