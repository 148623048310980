import API from "./API";

export default class Articulados extends API
{
    static async search(filter) {
        return this.get("api/admin/articulados",{params: filter});
    }

    static async find(id) {
        return this.get("api/admin/articulados/" + id);
    }

    static async findByWork(id) {
        return this.get("api/admin/articulados/getByWork/" + id);
    }
}