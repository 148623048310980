<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="primary white--text">
                Mapa de Quantidades de Trabalho
            </v-card-title>
            <v-card-text class="text-center">
                <span class="text-h6 black--text">{{fields.obra.Codigo}} - {{fields.obra.Descricao}}</span>
            </v-card-text>
            <v-card-text>
                <v-row class="font-weight-bold">
                    <v-col cols="12" md="1" class="text-center">
                        Código
                    </v-col>
                    <v-col cols="12" md="6" class="text-start">
                        Descrição
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        Uni.
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        Quant.
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        Val. uni.
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        Total
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        CUO
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-for="(item, index) in fields.articulado" :key="index+952">
                    <v-col cols="12" md="1" class="text-center">
                        {{item.codigo}}
                    </v-col>
                    <v-col cols="12" md="6" class="text-start">
                        <v-row>
                            <v-col cols="12" v-if="item.title != null">
                                <strong>{{item.title}}</strong>
                            </v-col>
                            <v-col cols="12" v-if="item.descricao != null">
                                {{item.descricao}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        {{item.unidade}}
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        {{item.qty}}
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        {{item.unit_price == null ? '' : round(item.unit_price)}}
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        {{item.qty != null && item.unit_price != null ? round(item.qty * item.unit_price) : ''}}
                    </v-col>
                    <v-col cols="12" md="1" class="text-center">
                        {{item.cuo}}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    name:"MQT",
    props:{
        mqt: Object,
        method: String
    },
    watch: { 
        mqt: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
        }
      },
    },
    data: () => ({
        fields: {}
    }),
    mounted(){
        console.log(this.fields)
    },
    methods:{
        round(n){
            return parseFloat(n).toFixed(2)
        }
    }
}
</script>