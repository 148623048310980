<template>
    <div>
         <v-row>
            <!-- DESKTOP -->
            <v-col cols="11" md="9"  class="d-none d-lg-block">
                <h2 class="primary--text">Diários Mão de Obra</h2>
                <v-row class="caption">
                    <v-col cols="12" md="3">
                        <v-icon small color="primary">mdi-cloud</v-icon> - Gerado na Web App
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-icon small>mdi-laser-pointer</v-icon> - Gerado através ERP
                    </v-col>
                        <!--<v-icon small color="red" class="ml-4">mdi-star-half-full</v-icon> - Administrativo aprovou-->
                    </p>
                </v-row>
                <v-row class="caption">
                    <v-col cols="12" md="3">
                        <v-icon small color="warning">mdi-steering</v-icon> - Criado por Manobrador
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-icon small color="black">mdi-account-hard-hat</v-icon> - Criado por Encarregado
                    </v-col>
                    </p>
                </v-row>
                <v-row class="caption">
                    <v-col cols="12" md="3">
                        <v-icon small color="success">mdi-sync</v-icon> - Sincronizada
                    </v-col>
                    <v-col cols="12" md="3">                        
                        <v-icon small>mdi-sync-off</v-icon> - Por sincronizar
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-icon small color="success">mdi-check-decagram</v-icon> - Aprovados
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="d-none d-lg-block">
                <v-btn
                    class="mx-2 mt-2"
                    small       
                    outlined          
                    block   
                    color="warning"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                        Editar
                </v-btn>
            


                <v-btn             
                    block   
                    class="mx-2 mt-2"
                    small
                    outlined
                    color="success"
                    >
                        <v-icon dark>
                            mdi-check-decagram
                        </v-icon>
                        Aprovar
                </v-btn>
                
            </v-col>
            <!-- DESKTOP -->

            <!-- MOBILE -->
                <v-col cols="11" md="9"  class="d-lg-none">
                    <h2 class="primary--text">Diários Mão de Obra</h2>
                    <v-row class="mt-2 ml-2">
                        <p class="caption">
                            <v-col cols="12">
                            <v-icon small color="primary">mdi-cloud</v-icon> - Gerado na Web App
                        </v-col>
                        <v-col cols="12">
                            <v-icon small color="warning">mdi-steering</v-icon> - Criado por Manobrador
                        </v-col>
                        <v-col cols="12">
                            <v-icon small color="red">mdi-star-half-full</v-icon> - Administrativo aprovou
                        </v-col>
                        </p>
                    </v-row>
                    <v-row class="ml-2">
                        <p class="caption">
                        <v-col cols="12">
                            <v-icon class="mt-2" small>mdi-laser-pointer</v-icon> - Gerado através ERP
                        </v-col>
                        <v-col cols="12">
                            <v-icon small color="black">mdi-account-hard-hat</v-icon> - Criado por Encarregado
                        </v-col>
                        <v-col cols="12">
                            <v-icon small color="success">mdi-check-decagram</v-icon> - Aprovados
                        </v-col>
                        </p>
                    </v-row>
                </v-col>
                <v-col class="d-lg-none">
                    <v-btn
                        class="mx-2 mt-2"
                        small       
                        outlined          
                        block   
                        color="warning"
                        >
                            <v-icon dark>
                                mdi-pencil
                            </v-icon>
                            Editar
                    </v-btn>
                


                    <v-btn             
                        block   
                        class="mx-2 mt-2"
                        small
                        outlined
                        color="success"
                        >
                            <v-icon dark>
                                mdi-check-decagram
                            </v-icon>
                            Aprovar
                    </v-btn>
                    
                </v-col>
            
            <!-- MOBILE -->
        </v-row>

        <PaginatedTable
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="total"
            @optionsChanged="searchDiaries"
            class="mt-4"
            locale="pt-pt"
            :canView="false"
            :canDelete="false"
            @deleted="deleteDiary"            
            @filter-data="filterByDate"
            @reset-data="resetDates"
            @changed-local="changedLocal"
            @changeState="changeState"
            :expandable="true"
            :lStorage='"odc-mo-diaries-" + $store.state.user.data.id'
        >
            <template v-slot:options="{item}">
                <!-- Validação em falta. Só pode ser editável se não estiver aprovado -->
                <v-row justify="start">
                    <v-col cols="12" md="auto" v-if="false">
                        <v-btn
                            fab
                            x-small
                            outlined
                            dark
                            color="info"
                            label="Preview"
                            @click="previewDiary(item)"
                        >
                            <v-icon dark>
                                mdi-flash-red-eye
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-btn
                            fab
                            dark
                            outlined
                            x-small
                            color="warning"
                            @click="edit(item)"
                            >
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="auto" v-if="checkIfCanApprove(item.current_approval)">
                        <v-btn
                            fab
                            dark
                            outlined
                            x-small
                            color="success"
                            
                            @click="confirmApprove(item)"
                            >
                                <v-icon dark>
                                    mdi-check-decagram
                                </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="auto" v-if="canDelete(item)">
                        <v-btn
                            fab
                            dark
                            x-small
                            outlined
                            color="error"
                            
                            @click="confirmDeleteDiary(item)"
                            >
                                <v-icon dark>
                                    mdi-delete
                                </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </PaginatedTable>
        <v-dialog
            v-model="previewDiaryEnc.show"
            width="80%"
            style="background-color: white;"
            persistent
            transition="dialog-bottom-transition"
        >
        
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Pré-visualização</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="previewDiary = false; previewDiaryEnc.show = false;"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <PreviewEditEnc v-if="previewDiaryEnc.show" :preview_diary="true" :diary_helper="previewDiaryEnc" :dialog_open="previewDiaryEnc.show"/>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="previewDiaryMan.show"
            width="80%"
            style="background-color: white;"
            persistent
            transition="dialog-bottom-transition"
        >
        
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Pré-visualização</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="previewDiaryMan.show = false;"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <PreviewDiaryMan v-if="previewDiaryMan.show" :preview_diary="true" :diary_helper="previewDiaryMan" :dialog_open="previewDiaryMan.show"/>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="previewDiaryLABOR.show"
            width="80%"
            style="background-color: white;"
            persistent
            transition="dialog-bottom-transition"
        >
        
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Pré-visualização</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="previewDiaryLABOR.show = false;"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <PreviewDiaryLaborView  v-if="previewDiaryLABOR.show" :preview_diary="true" :diary_helper="previewDiaryLABOR" :dialog_open="previewDiaryLABOR.show"/>
            </v-card>
        </v-dialog>





        <DialogConfirmation 
            v-if="confirmationModal.show"
            :title="confirmationModal.title" 
            type="warning" 
            :opened="confirmationModal.show" 
            :elevation="0"
            @on-submit="approve" 
            @on-cancel="confirmationModal.item = null; confirmationModal.show = false;">
                {{ confirmationModal.message }}
        </DialogConfirmation> 

        <DialogConfirmation 
            v-if="confirmationModalDelete.show"
            :title="confirmationModalDelete.title" 
            type="warning" 
            :opened="confirmationModalDelete.show" 
            :elevation="0"
            @on-submit="deleteDiary" 
            @on-cancel="confirmationModalDelete.item = null; confirmationModalDelete.show = false;">
                {{ confirmationModalDelete.message }}
        </DialogConfirmation> 
        <ErrorSnackbar v-model="error" :message="error_message"/>
    </div>
</template>

<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

import PaginatedTable from "@/components/UI/Tables/PaginatedTable"

import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import PreviewEditEnc from "@/views/Diaries/Supervisor/Edit.vue"
import PreviewDiaryMan from "@/views/Diaries/Operator/Edit.vue"
import PreviewDiaryLaborView from "@/views/Diaries/ERP/LaborView.vue"

import Button from "@/components/UI/Buttons/Button";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";

export default {
    components: {
        Tooltip,
        ErrorSnackbar,
        PaginatedTable,
        SuccessSnackbar,
        Button,
        DialogConfirmation,
        PreviewEditEnc,
        PreviewDiaryMan,
        PreviewDiaryLaborView
    },
    data: () => ({
        error: false,
        error_message: '',
        previewDiaryLABOR:{
            show: false,
            diary_id: null,
            diary_web_hash: null,
            diary_user: null
        },
        previewDiaryEnc:{
            show: false,
            diary_id: null,
            diary_web_hash: null,
            diary_user: null
        },
        previewDiaryMan:{
            show: false,
            diary_id: null,
            diary_web_hash: null,
            diary_user: null
        },
        confirmationModalDelete:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        },
        confirmationModal:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        },
        headers: [
            {
                text: 'Status',
                sortable: false,
                value: 'approval_status',
                align: "center",
            },
            {
                text: 'Chave',
                sortable: false,
                value: 'Chave',
            },
            {
                text: 'Data',
                sortable: false,
                value: 'Data'
            },
            {
                text: 'Responsável',
                sortable: false,
                value: 'Responsavel',
            },
            {
                text: 'Criada Em',
                value: 'CreatedOn',
                sortable: false,
            },
            {
                text: 'Criada Por',
                sortable: false,
                value: 'creator',
            },
            {
                text: 'Local',
                sortable: false,
                value: 'Obs',
            },
            { 
                text: 'Opções', 
                align: 'center',
                value: 'actions', 
                sortable: false 
            },
        ],
        loading: false,
        success: false,
        options: {
            local: 'Web App',
            start_date: null,
            end_date: null
        },
        items: [],
        itemsReseted: [],
        total: 0,
        filter: {
            name: null,
            email:null,
            local:'Web App',
            start_date: null,
            end_date: null,
            approved_state: 0
        },
    }),
    mounted(){
        if(localStorage["odc-mo-diaries-" + this.$store.state.user.data.id] != undefined){

            let data = JSON.parse(localStorage["odc-mo-diaries-" + this.$store.state.user.data.id]);

            this.filter = data.filter;

            this.options = data.filter;
        }

        this.loading = false
    },
    methods: {    
        previewDiary(d){
            let type = d.creationRole;
            if(this.filter.local == 'ERP'){
                this.previewDiaryLABOR.diary_id = d.Id
                this.previewDiaryLABOR.show = true
                return
            }

            if(type=='encarregado'){
                this.previewDiaryEnc.diary_user = d.id_user_creator
                this.previewDiaryEnc.diary_web_hash = d.webAppLinkHash
                this.previewDiaryEnc.diary_id = null
                this.previewDiaryEnc.show = true
            }else{
                this.previewDiaryMan.diary_user = d.id_user_creator
                this.previewDiaryMan.diary_web_hash = d.webAppLinkHash
                this.previewDiaryMan.diary_id = null
                this.previewDiaryMan.show = true
            }                
        },
        changeState(s){
            this.filter.approved_state = s
            this.searchDiaries(this.options);            
        },
        changedLocal(l){
            this.filter.local = l
            this.searchDiaries(this.options);
        },
        resetDates(){
            this.filter = {
                name: null,
                email:null,
                local:'Web App',
                start_date: null,
                end_date: null,
                approved_state: 0
            }
            this.searchDiaries(this.filter)
        },
        filterByDate(data1, data2){
            var date1 = new Date(data1)
            var date2 = new Date(data2)


            let organized = []

            this.itemsReseted.forEach(lineItem => {
                let lineItemData = new Date(lineItem.Data)

                if(lineItemData >= date1 && lineItemData <= date2)
                    organized.push(lineItem)
                    
            });
            
            this.items = organized
        },
        edit(item){
            // Significa que vem do ERP, vamos carregar vistas do ERP
            if(item.webAppLinkHash == null){
                this.$router.push("diarios/view/erp/equipment/" + item.Id);
                return
            }

            if(item.creationRole == "encarregado"){
                this.$router.push("diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user_creator);
                return
            }else{
                
                this.$router.push("diarios/manobrador/" + item.webAppLinkHash + "/" + item.id_user_creator + '/mo');
                return
            }
        },
        confirmApprove(approval){
            this.confirmationModal.item = approval
            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
            this.confirmationModal.show = true
        },
        approve(){
            let approval = this.confirmationModal.item
            if(approval != null){
                // Send hash from Header
                this.$store.dispatch("approvePD", approval)
                    .then(() => {
                        this.confirmationModal.item = null
                        this.confirmationModal.show = false

                        this.searchDiaries(this.options);
                });
            }else{
                this.error_message = 'Um erro aconteceu ao aprovar a diária! Por favor tente mais tarde'
                this.error = true
                this.confirmationModal.item = null
                this.confirmationModal.show = false
            }
        },
        searchDiaries(options){
            this.loading = true;

            let filter = {...this.filter};
            
            let request = {
                sortBy:     options.sortBy,
                sortDesc:   options.sortDesc,
                page:       options.page,
                limit:      options.itemsPerPage,
            };

            Object.assign(request, request);

            localStorage["odc-mo-diaries-" + this.$store.state.user.data.id] = JSON.stringify({
                filter: filter,
                options: options,
            });

            
            request.local = 'ERP'
            
            this.$store.dispatch("searchPDMO", request)
                .then(response => {

                    let items = response.data;

                    this.total = items.total;

                    this.items = items.data;
                    this.itemsReseted = items.data;

                    this.loading = false;

            });
        },
        confirmDeleteDiary(diary){
            this.confirmationModalDelete.item = diary
            this.confirmationModalDelete.title = 'Tem a certeza?'
            this.confirmationModalDelete.message = 'Tem a certeza que pretende eliminar a diária?'
            this.confirmationModalDelete.show = true
        },
        deleteDiary(){
            let diary = this.confirmationModalDelete.item
            let params = {
                id_user_creator: diary.id_user_creator,
                id: diary.webAppLinkHash
            }

            this.$store.dispatch("deletePDEq", params)
                .then(response => {

                    this.success = true;

                    this.confirmationModalDelete.show = false
                    this.searchDiaries(this.options);

            });
        },
        canDelete(item){
            if(!this.$store.getters.hasPermission('super', 'diaries.delete'))
                return false;

            if(item.webAppLinkHash == null)
                return false
            
            if(item.sync == true)
                return false;
            return true;
        },
        checkIfCanApprove(approval){
            // Verficar primeiro o role... Se não tiver role de administrativo ou engenheiro, não pode aprovar
            if(!this.$store.getters.hasRole(['administrativo', 'engenheiro']))
                return false


            // Se está a null, significa que é preciso ser aprovado pelo ADMINISTRATIVO
            if(approval == null){
                if(this.$store.getters.hasRole(['engenheiro']))
                    return false

                return true
            }

            if(parseInt(approval.level) == 0){
                return this.$store.getters.hasRole(['administrativo'])
            }
            
            // Se tem approval, verificar qual o role de aprovação. 
            // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
            if(approval.level == 1){
                return this.$store.getters.hasRole(['engenheiro'])
            }
            
            // Se estiver a 2, significa que já foi totalmente aprovado e não precisa de mais aprovações
            return false

        },
    }
}
</script>

<style scoped>

</style>