<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <validation-observer ref="form" v-slot="{ invalid }" v-if="!loading">
            <v-form @submit.prevent="submit">
                <v-row justify="start">
                    <v-col cols="12" md="4">
                        <validation-provider v-if="method=='create'" v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                :items="obras"
                                dense outlined
                                item-value="Id"
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                :error-messages="errors"
                                v-model="fields.work_id"
                                label="Obra *"
                            />
                        </validation-provider>
                        <v-text-field v-else
                            dense outlined
                            label="Obra *"
                            :disabled="true"
                            :value="fields.work_code + ' - ' + fields.work_name"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn :disabled="fields.work_id == null" @click="importArticulado" small class="success" fab>
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="fields.work_id != null && showContent == true">
                    <v-col cols="12">
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Natureza
                                        </th>
                                        <th class="text-center">
                                            Sub-Atividade
                                        </th>
                                        <th class="text-center">
                                            Cód. Internos
                                        </th>
                                        <th class="text-center">
                                            U.O.
                                        </th>
                                        <th class="text-center">
                                            Unidade
                                        </th>
                                        <th class="text-center">
                                            Rend. Esperado /h
                                        </th>
                                        <th class="text-center">
                                            Total produção
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        
                                    >
                                        <td class="text-left">
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-btn rounded class="success mt-4" :disabled="invalid" type="submit" style="margin: auto; display: block;">
                        Gravar
                    </v-btn>
                </v-row>
            </v-form>
        </validation-observer>
    </v-container>
</template>
<script>
import Obra from '@/api/Obra.js'
import Articulados from '@/api/Articulados.js'

export default{
    name:"Articulado_UnidadeObra",
    props: {
        method: String,
    },
    data(){
        return{
            obras: [],
            articulado: null,
            loading: true,
            showContent: false,
            fields:{
                work_id: null
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        submit(){
            
        },
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            if(this.method == 'create'){
                await Obra.all(data).then((resp)   =>  {
                    this.obras = resp.data;
                });
            }

            this.loading = false
        },
        importArticulado(){
            Articulados.findByWork(this.fields.work_id).then((resp) =>  {
                this.articulado = resp.data
                this.showContent = true
            })
        }
    }
}
</script>