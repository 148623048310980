<template>
    <v-container fluid>
        <v-row class=" mx-1 px-1">
            <v-col cols="12">
                <h2 class="primary--text">Registo de Material</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                <v-container fluid>
                    <validation-observer ref="form" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <validation-provider v-slot="{ errors }" vid="FrenteObra" name="FrenteObra" rules="required">
                                        <v-autocomplete
                                            v-model="fields.obra_id"
                                            :items="obras"
                                            outlined dense
                                            :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                            item-value="Id"
                                            :error-messages="errors"
                                            label="Obras *"   
                                            @change="changeWork"                                         
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4" v-if="fields.obra_id != null && hideFrentes == false">
                                    <validation-provider v-slot="{ errors }" vid="FrenteObra" name="FrenteObra" rules="|">
                                        <v-autocomplete
                                            v-model="fields.frente_id"
                                            :items="frentes_obra"
                                            outlined dense
                                            :item-text="item => item.Descricao"
                                            item-value="ID"
                                            :error-messages="errors"
                                            label="Frente Obra"
                                            @change="changedFrenteObra"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4" v-if="classeNeeded">
                                    <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="|">
                                        <v-autocomplete
                                            v-model="fields.classe_id"
                                            :items="classes"
                                            outlined dense
                                            :item-text="item => item.Descricao"
                                            item-value="ID"
                                            :error-messages="errors"
                                            label="Classe"
                                            @change="changedClass"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row v-if="showContent">
                                <v-card class="elevation-0" width="100%">
                                    <v-card-title class="primary white--text">Materiais</v-card-title>
                                    <v-card-text class="mt-4">
                                        <v-simple-table fixed-header class="mt-6">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="33%">
                                                            Material
                                                        </th>
                                                        <th class="text-center" width="7%">
                                                            Unidade
                                                        </th>
                                                        <th class="text-center" width="5%">
                                                            Opções
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in fields.materiais" :key="index">
                                                        <td class="text-center">
                                                            {{item.artigo}} - {{item.descricao}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.unidade_base}}
                                                        </td>
                                                        <td class="text-center">
                                                            <v-btn
                                                                dark
                                                                small
                                                                color="error"
                                                                @click="deleteMaterial(item)"
                                                                >
                                                                <v-icon small>mdi-delete</v-icon>
                                                            </v-btn> 
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="warning"
                                            class="mt-4 mb-4"
                                            @click="newMaterial.showDialog = true"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-row>
                            <v-btn rounded class="success mt-4" :disabled="invalid" type="submit">
                                Gravar
                            </v-btn>
                        </v-form>
                    </validation-observer>
                </v-container>            
            </v-col>    
        </v-row>
        <SuccessSnackbar message="Materiais gravados com sucesso" v-model="success"/>

        <v-dialog v-model="newMaterial.showDialog" width="50%">
            <v-card class="elevation-0 ma-0 pa-0">
                <v-card-title class="primary white--text">Adicionar material</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="10">
                            <v-text-field
                                v-model="search"
                                label="Escolha o material"
                                dense outlined
                                class="mt-4"
                                hint="Escreva, no mínimo, 4 caracteres para iniciar a procura"
                                persistent-hint
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn
                                class="success mt-4"
                                @click="loadMaterials"
                                :disabled="search.length < 3"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>                        
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-progress-circular
                        style="display: block; margin: auto;"
                        v-if="isLoading"
                        indeterminate
                        color="amber"
                    ></v-progress-circular>
                    <v-row v-if="!isLoading">
                        <v-col cols="12">
                            <v-simple-table class="d-none d-lg-block">
                                <template v-slot:default>
                                    <thead class="primary">
                                        <tr>
                                            <th width="85%" class="text-center white--text text-caption font-weight-bold">
                                                Material
                                            </th>
                                            <th width="15%" class="text-center white--text text-caption font-weight-bold">
                                                Unidade
                                            </th>
                                            <th class="text-center white--text text-caption font-weight-bold">
                                                Opções
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="item in getMateriais" :key="item.artigo">
                                        <tr>
                                            <td>
                                                {{item.artigo}} - {{item.descricao}}
                                            </td>
                                            <td>
                                                {{item.unidade_base}}
                                            </td>
                                            <td>
                                                <v-switch
                                                    v-model="item.selected"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="success" @click="adicionarMaterial" style="display: block; margin: auto;">Gravar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>
<script>
import FrenteObra from "@/api/FrenteObra.js"
import Classe from "@/api/Classe.js"
import Obra from "@/api/Obra.js"
import Artigos from "@/api/Artigos.js"
import Materiais from "@/api/Materiais.js"
import dayjs from 'dayjs'
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default {
    name:"AddMaterial",
    components:{
        SuccessSnackbar
    },
    data(){
        return{
            hideFrentes: false,
            frentes_obra: [],
            classes: [],
            materiais: [],
            materiais_remote: [],
            obras: [],
            success: false,
            unidades:[],
            showContent: false,
            classeNeeded: false,
            isLoading: false,
            model: null,
            search: '',
            fields:{
                frente_id: null,
                classe_id: null,
                obra_id: null,
                materiais: []
            },
            novo_material:{
                material_id: null
            },
            newMaterial: {
                showDialog: false,
                material_id: null,
                unidade: null,
            },
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{   
        loadMaterials(){
            this.isLoading = true
            Artigos.all({search: this.search}).then((resp)    =>  {
                this.materiais = resp.data
                this.isLoading = false
            })
        },
        adicionarMaterial(){
            this.materiais_remote.forEach(element => {
                if(element.selected){
                    this.fields.materiais.push({
                        id: dayjs().unix(),
                        artigo: element.artigo,
                        descricao: element.descricao,
                        unidade_base: element.unidade_base
                    });
                }
            });

            

        },
        changedMaterial(item){
            let ob = null
            this.materiais.forEach(element => {
                if(element.artigo == item.artigo)
                    ob = element
            });
            item.artigo = ob.artigo
            item.descricao = ob.Descricao
            item.unidade_base = ob.unidade_base
        },
        changeWork(){
            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    ObraPai = element.Codigo
            });

            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data


             
                if(this.frentes_obra.length == 0)
                    this.classesPorObra()

                if(this.frentes_obra.length == 1){
                    this.fields.frente_id = this.frentes_obra[0].ID
                    this.changedFrenteObra()
                }
            })            

            this.loadTable();

            this.showContent = true
            
        },
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
                this.hideFrentes = true
                this.classeNeeded = true


                if(this.classes.length == 1){
                    this.fields.classe_id = this.classes[0].ID
                }
                
                this.showContent = true
            })
        },
        deleteMaterial(item){
            let pos = -1
            let i = 0
            this.fields.materiais.forEach(element => {            
                if(element.id == item.id){
                    pos = i
                }

                i++
            });

            if(pos != -1)
                this.fields.materiais.splice(pos, 1)
        },
        loadTable(){
            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            let data = {
                id_frente_obra: frente == null ? null : frente.SubEmpId,
                id_obra_classe: classe == null ? null : classe.ClasseId,
                obra_id: this.fields.obra_id
            }
            
            Materiais.getListMateriaisObra(data).then((resp)    =>  {
                this.fields.materiais = resp.data
            })
        },
        addMaterial(){
            this.fields.materiais.push({
                id: dayjs().unix(),
                artigo: null,
                descricao: null,
                unidade_base: null
            });
        },
        fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
                
            });
   

            /*Artigos.all().then((resp)    =>  {
                this.materiais = resp.data
            })*/

           
        },
        async changedFrenteObra(){
            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.classes = resp.data


                    if(this.classes.length == 1){
                        this.fields.classe_id = this.classes[0].ID
                    }
                })

                if(this.classes.length == 0){
                    this.showContent = true
                    this.classeNeeded = false
                }else this.classeNeeded = true

                this.loadTable()
            }
        },
        changedClass(){
            this.loadTable()
        },
        submit(){

            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });


            let data = {
                frente_id: frente == null ? null : frente.SubEmpId,
                classe_id: classe == null ? null : classe.ClasseId,
                obra_id: this.fields.obra_id,
                materiais: this.fields.materiais
            }
            
            Materiais.create(data)
            .then(() => {
                this.success = true;

                this.error = false;

                this.loader = false;

                
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loader = false;
            });
        }
    },
    computed: {
        getMateriais(){
            this.materiais_remote = []
            
            this.materiais.forEach(element => {
                this.materiais_remote.push({
                    artigo: element.artigo,
                    descricao: element.descricao,
                    unidade_base: element.unidade_base,
                    selected: false
                });
            });

            return this.materiais_remote
        },
        crumbs: function() {
            return [
                {
                text: 'Registo de Material',
                disabled: true,
                to: '#',
                exact: true,
                },
            ];
        }
    }
}
</script>