<template>
    <v-card v-if="selectedWork != null">
        <v-card-title class="primary white--text">Adicionar máquinas: {{selectedWork.Descricao}}</v-card-title>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-card-text class="mt-6">
            <v-row>
                <v-col cols="12" md="8">
                    <v-autocomplete
                        :items="machines"
                        :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                        v-model="selectedMachine"
                        label="Máquinas"
                        dense outlined
                        item-value="Id"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox
                        v-model="selectedAccessory"
                        label="Ative para acessório"
                        dense outlined
                        color="warning"
                    />
                </v-col>
                <v-col cols="12" md="8">
                    <v-autocomplete
                        :items="workers"
                        :item-text="item => item.funcionario_codigo + ' - ' + item.name"
                        v-model="selectedWorker"
                        label="Utilizadores"
                        dense outlined
                        item-value="id"
                        persistent-hint
                        hint="Verifique se o utilizador está adicionado à obra"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-btn class="success" @click="addEquipmentWork">
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">                    
                    Máquina: <v-icon color="success">mdi-bulldozer</v-icon>
                    Acessório: <v-icon color="warning">mdi-tag</v-icon>
                </v-col>
                <v-col cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Máquina
                                    </th>
                                    <th class="text-left">
                                        Colaborador
                                    </th>
                                    <th class="text-center">
                                        Tipo
                                    </th>
                                    <th class="text-left">
                                        Opção
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="!loading">
                                <tr
                                    v-for="item in machinesInWork"
                                    :key="item.id"
                                >
                                <td>{{item.EntityKey}} - {{ item.equipment_description }}</td>
                                <td>{{ item.user_name }}</td>
                                <td class="text-center">
                                    <v-icon v-if="item.is_accessory == true" color="warning">mdi-tag</v-icon>
                                    <v-icon v-else color="success">mdi-bulldozer</v-icon>
                                </td>
                                <td>
                                    <IconRemove
                                        :key="item.id"
                                        class="mr-2"
                                        @on-submit="deleteMachine(item)"
                                    /> 
                                </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
        <DialogSuccess :opened="success.show" @on-ok="success.show = false" >
          {{success.msg}}
      </DialogSuccess>
    </v-card>
</template>
<script>
import Equipamentos from "@/api/Equipamentos.js"
import Works from "@/api/Works.js"
import IconRemove from '@/components/UI/IconRemove.vue';
import DialogSuccess from '@/components/UI/DialogSuccess.vue';

export default{
    name:"AddMachines",
    props:{
        selectedWork: Object
    },
    components:{
        IconRemove,
        DialogSuccess
    },
    data(){
        return{
            loading: true,
            machines: [],
            workers:[],
            selectedMachine: null,
            selectedWorker: null,
            selectedAccessory: false,
            machinesInWork: [],
            success: {
                show: false,
                msg: ''
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            this.loadTable()
            Equipamentos.allUnassigned().then((resp)  =>  {
                this.machines = resp.data
            })
            Works.getUsers(this.selectedWork.Id).then((resp) => {
                
                this.workers = resp.data
            })
        },
        async loadTable(){
            await Works.getMachines(this.selectedWork.Id).then((resp) =>  {
                this.machinesInWork = resp.data
                
                this.loading = false
            })
        },
        addEquipmentWork(){
            this.loading = true
            let data = {
                work_id: this.selectedWork.Id,
                user_id: this.selectedWorker,
                machine_id: this.selectedMachine,
                is_accessory: this.selectedAccessory,
            }
            Works.addMachine(this.selectedWork.Id, data).then((resp)   =>  {
                this.loadTable();
                this.success.msg = 'Adicionado com sucesso'
                this.success.show = true
                this.loading = false
            })
        },
        deleteMachine(item){
            this.loading = true
            let data = {
                work_id: this.selectedWork.Id,
                user_id: item.user_id,
                equipment_id: item.equipment_id,
                is_accessory: this.selectedAccessory
            }
            Works.deleteMachine(this.selectedWork.Id, data).then((resp)    =>  {
                this.loadTable();
                this.success.msg = 'Eliminado com sucesso'
                this.success.show = true
            })
        }
    }
}
</script>