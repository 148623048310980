import API from "./API";

export default class Atividades extends API
{
    static async search(filter) {
        return this.get("api/admin/atividades",{params: filter});
    }

    static async all(filter) {
        return this.get("api/admin/atividades/list",{params: filter});
    }

    static async getSubAtividade(id){
        return this.get("api/admin/atividades/get-subatividade/" + id);
    }
}