<template>
    <section class="mt-16">
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <v-breadcrumbs
                large
                :items="crumbs"
                divider="/"
                class="pl-0"
                ></v-breadcrumbs>
        
                <h1 class="primary--text">
                <v-icon color="primary">mdi-shield-account</v-icon> <span>Gestão de Clientes</span>
                </h1>
        
                <div style="position:relative">
                    
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    class="mt-16"
                    locale="pt-pt"
                >
                    
                </v-data-table>
                </div>
                <v-navigation-drawer
                    fixed
                    right
                    v-model="searching"
                    hide-overlay
                    width="520"
                    class="pt-6"
                >
                <v-list>
                    <v-list-item>
                    <v-btn fab small color="secondary" @click="searching = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
        
                    <v-btn absolute right small color="error" @click="resetClients">
                        Limpar Campos
                    </v-btn>
                    </v-list-item>
                </v-list>
                <v-list class="pt-10">
                    <v-form @submit.prevent="searchClients">
                    
                      
                    
                    <v-row
                        class="mt-10"
                        align="center"
                        justify="space-around"
                    >
                    <v-btn
                        color="primary"
                        type="submit"
                    >
                        Pesquisar
                    </v-btn>
                    </v-row>
                    </v-form>
                </v-list>
                </v-navigation-drawer>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/UI/IconRemove.vue';
  import IconView from '@/components/UI/IconView.vue';
  import Clients from "@/api/Clients.js";
  
  export default {
    components: {
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchClients()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'Nome',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Criado a',
          sortable: true,
          value: 'created_at',
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        name: null,
        display_name: null
      },
      users:[]
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "clients.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      fillBaseData() {
  
        if(localStorage["clientsODC-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["clientsODC-obras-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchClients(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["clientsODC-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Clients.search(request).then(response => {
          
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetClients()
      {
        this.filter = {};
  
        this.searchClients();
      },
      deleteGuarantee(item){
        GarantiasObras.deleteObraGuarantee(item.id)
          .then(() => {
            this.searchClients();
          });
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Clientes',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  