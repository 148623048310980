<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Listagem de Logs</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    class="mt-16"
                    locale="pt-pt"
                >
            </v-data-table>

            </v-col>
        </v-row>
    </v-container>                        
</template>
<script>
import PaginatedTable from "@/components/UI/Tables/PaginatedTable"

export default {
    components: {
        PaginatedTable
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchLogs()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
        loading: true,
        items: [],
        headers: [
            {
                text: 'Ação',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Utilizador',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Data',
                sortable: false,
                value: 'createdIn'
            },
            {
                text: 'Parametros',
                sortable: false,
                value: 'param_fields'
            },
        ],
        tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
        },
        total: 0,
        filter: {
            name: null,
            email:null,
            is_active: true
        },
        options: {},
    }),
    mounted(){
        
    },
    methods:{
        searchLogs(options){
            this.loading = true;

            let filter = {...this.filter};
            
            let request = {
                sortBy:     this.options.sortBy,
                sortDesc:   this.options.sortDesc,
                page:       this.options.page,
                limit:      this.options.itemsPerPage,
            };

            Object.assign(request, filter);

            localStorage["odc-logs-listings-" + this.$store.state.user.data.id] = JSON.stringify({
                filter: filter,
                options: this.options,
            });
            

            this.$store.dispatch("getLogs", request)
                .then(response => {

                this.items = response.data
                this.total = response.total
                this.loading = false

            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Listagem de Logs',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>