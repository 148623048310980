import API from "./API";

export default class Testings extends API
{
    static async sendEmailVerify(fields) {
        return this.post("api/admin/testings/verify/verify");
    }

    static async sendEmailPassword(fields) {
        return this.post("api/admin/testings/password/password");
    }

    static async sendPDDiary(fields) {
        return this.post("api/admin/testings/pd_diarias/pd_diarias");
    }

    static async sendPDMissing(fields) {
        return this.post("api/admin/testings/pd_falta/pd_falta");
    }

    
    
}