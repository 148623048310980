<template>
    <v-container fluid>
        <v-row class="mt-2">
            <v-col>
                <v-card class="elevation-0" outlined>
                <v-card-title class="grey light-4 white--text">
                    <v-row>
                    <v-col cols="12" align="center">
                        Bem vindo {{ this.$store.state.user.data.name}}
                    </v-col>
                    <v-col class="mt-n6" cols="12" align="center">
                        {{getDate()}}
                    </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text v-if="!loading">
                    <v-row class="mt-2">
                    <v-col 
                        v-if="$store.getters.hasRole(['super', 'manobrador'])">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                            <v-card
                                class="mx-auto"
                                max-width="344"
                            >
                                <v-img contain height="170"
                                src="images/steering.png"></v-img>

                                <v-card-text>
                                <h2 class="text-h6 primary--text">
                                    Criar parte diária Manobrador
                                    
                                </h2>
                                <small v-if="stats.last != null">última criação a {{stats.last.created_at}}</small>
                                </v-card-text>

                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#036358"
                                >
                                    <v-btn @click="goTo('/diarios/manobrador/registar')">Criar</v-btn>
                                </v-overlay>
                                </v-fade-transition>
                            </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    <v-col
                        v-if="$store.getters.hasRole(['super', 'encarregado'])">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                            <v-card
                                class="mx-auto"
                                max-width="344"
                            >
                            <v-img contain height="170"
                                src="images/encarregado.png"></v-img>

                                <v-card-text>
                                <h2 class="text-h6 primary--text">
                                    Criar parte diária Encarregado
                                </h2>
                                <small v-if="stats.last != null">última criação a {{stats.last.created_at}}</small>
                                </v-card-text>


                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#036358"
                                >
                                    <v-btn @click="goTo('/diarios/encarregado/registar')">Criar</v-btn>
                                </v-overlay>
                                </v-fade-transition>
                            </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    <v-col
                        v-if="false">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                            <v-card
                                class="mx-auto"
                                max-width="344"
                            >
                                <v-img contain height="170"
                                src="images/unidade.png"></v-img>

                                <v-card-text>
                                <h2 class="text-h6 primary--text">
                                    Registar unidades de material 
                                    
                                </h2>
                                </v-card-text>

                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#036358"
                                >
                                <v-btn @click="goTo('/criar/materiais')">Criar</v-btn>
                                </v-overlay>
                                </v-fade-transition>
                            </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    </v-row>
                </v-card-text>
                </v-card>
            </v-col>
            </v-row>    
            <div v-if="!loading">      
            <v-row>
                <v-col>
                <div id="chart">
                    <apexchart ref="PDMONTH" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template> 
<script>
  import VueApexCharts from 'vue-apexcharts'
  import Statistics from "@/api/Statistics.js"

  export default {
    components: {
          apexchart: VueApexCharts,
        },
    data: () => ({
      stats: null,
      loading: true,
      series: [
            {
              data: []
            }
          ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Partes diárias inseridas por mês',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          title: {
            text: 'Mês'
          }
        },
        yaxis: {
          title: {
            text: 'Quantidade'
          },
          min: 0,
          max: 9000
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      labels: [
        '12am',
        '3am',
        '6am',
        '9am',
        '12pm',
        '3pm',
        '6pm',
        '9pm',
      ],
      value: [
        200,
        675,
        410,
        390,
        310,
        460,
        250,
        240,
      ],
    }),
    mounted(){
      // getStatistics
      Statistics.getStatistics().then((res)  =>  {
        this.stats = res.data;

              
        this.series =[{
          data: res.data.PD_By_Month
        }]

        let max_of_array = Math.max.apply(Math, res.data.PD_By_Month);
        this.chartOptions.yaxis.max = max_of_array

        this.loading = false

      }).catch(err => {

        console.log(err)
      });
    },
    methods:{
      goTo(link){
        this.$router.push(link);
      },
      getDate(){
        let dt = new Date().toLocaleDateString("pt-PT");
        return dt
      }
    }
  }
</script>
<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>