<template>
    <v-dialog
        v-model="openDialog"
        :fullscreen="false"
        persistent
        max-width="1000px"
    >        
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
            <v-toolbar
                class="sticky-toolbar"
                dark
                color="primary"
                >                    
                <v-toolbar-title>Novo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn
                    icon
                    dark
                    @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <validation-observer ref="form_ticket" v-slot="{ invalid }">      
                    <v-form class="mb-16 mt-12" @submit.prevent="addTicket">
                        <v-row>               
                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" vid="title" :name="'titulo'" rules="required">
                                    <v-text-field :error-messages="errors" 
                                            :label="'Título *'" 
                                            v-model="ticket.title">
                                    </v-text-field>
                                </validation-provider>
                            </v-col>    
                        </v-row>
                        <v-row>               
                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" vid="description" :name="'descrição'" rules="required">
                                    <v-textarea
                                    outlined
                                    name="input-7-1"
                                    v-model="ticket.description"
                                    :error-messages="errors"
                                    :label="'Descrição *'" 
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>    
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    multiple
                                    :label="'Anexos'"
                                    v-model="ticket.files"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-col align="center" justify="space-around">
                            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Guardar
                            </v-btn>
                        </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
            </v-card-text>
        </v-card>
        
    </v-dialog>
</template>
<script>   

export default {
    props:{
        openDialog: Boolean
    },
    data(){
        return{
            success: false,
            dialog: false,
            ticket: {
                title: '',
                description: '',
                files: [],
            }
        }
    },
    methods:{
        onSuccess(){
            this.ticket = {
                title: '',
                description: '',
                files: '',
            }
            this.success = false
        },
        addTicket(){
            const formData = new FormData()

            for (var i = 0; i < this.ticket.files.length; i++ ){
                let file = this.ticket.files[i];
                formData.append('files[]', file);
            }

    
            
            formData.append('title', this.ticket.title)
            formData.append('description', this.ticket.description)
            formData.append('total_files', this.ticket.files.length)

            this.$store.dispatch("createTicket", formData)
            .then((res) => {
                
                this.success = true;

                this.loading = false;

                this.$emit('close-success')

            }).catch(err => {

                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = 'Erro: ' + " " + err.response.status;

                this.error.message = err.response.data.message;
            });
        }
    }
}
</script>