<template>
    <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{errors}">
        <v-select
            v-bind="$attrs"
            v-on="$listeners"
            dense
            color="primary"
            :error-messages="errors"
            multiple
        >
            <template v-slot:prepend-item>
                <v-list-item
                ripple
                @mousedown.prevent
                @click="toggle"
                >
                <v-list-item-action>
                    <v-icon :color="$attrs.value.length > 0 ? 'primary darken-4' : ''">
                    {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                    Select All
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
    </ValidationProvider>
</template>
<script>
export default {
    props: {
        rules: String,
        vid: String,
    },
    computed: {
        allSelected () {
            return this.$attrs.value.length === this.$attrs.items.length
        },
        someSelected () {
            return this.$attrs.value.length > 0 && !this.allSelected
        },
        icon () {
            if (this.allSelected) return 'mdi-close-box'
            
            if (this.someSelected) return 'mdi-minus-box'
            
            return 'mdi-checkbox-blank-outline';
        },
    },
    methods: {
        toggle () {
            this.$nextTick(() => {
                if (this.allSelected) {

                    this.$emit("input", []);
                } else {this.$attrs["return-object"]

                    let selectedValues = this.$attrs["return-object"] ? this.$attrs.items.slice() : this.$attrs.items.map((item) => { return item[this.$attrs["item-value"]]});

                    this.$emit("input", selectedValues);
                }
            })
        },
    }
}
</script>
<style scoped>

</style>