import API from "./API";

export default class UnidadesObra extends API
{
    static async create(fields) {
        return this.post("api/admin/unidades-obra", fields);
    }  
    
    static async update(fields, id) {
        return this.put("api/admin/unidades-obra/" + id, fields);
    }  
    
    static async search(filter) {
        return this.get("api/admin/unidades-obra",{params: filter});
    }

    static async find(id) {
        return this.get("api/admin/unidades-obra/" + id);
    }

    static async findByWork(id, params){
        return this.get("api/admin/unidades-obra/get-by-work/" + id,{params: params})
    }
}
