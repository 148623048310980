var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"10","offset-md":"1"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Gestão Subempreitadas")]),_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('v-divider',{staticClass:"mb-12"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(this.$store.getters.hasPermission(['super', 'subcontract.create']))?_c('v-btn',{attrs:{"tile":"","color":"primary","small":""},on:{"click":function($event){_vm.newDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Adicionar novo ")],1):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"d-none d-lg-block text-right"},[_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","tile":""},on:{"click":function($event){return _vm.exportSubEmpreitada()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-export ")]),_vm._v(" Exportar ")],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","offset":"0","md":"10","offset-md":"1"}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.sub_empreitadas,"server-items-length":_vm.total,"options":_vm.options,"footer-props":_vm.tableFooter,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.obra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obra == null ? '-' : item.obra.Descricao)+" ")]}},{key:"item.frente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frente == null ? '-' : item.frente.Descricao)+" ")]}},{key:"item.sub",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sub == null ? '-' : item.sub.Descricao)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteSub(item)}}})]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}},[_c('AddSubempreitada',{on:{"success":_vm.reloadWindow,"close":function($event){_vm.newDialog = false}}})],1)],1),_c('SuccessSnackbar',{attrs:{"message":"Dados gravados!"},model:{value:(_vm.successSubempreitada),callback:function ($$v) {_vm.successSubempreitada=$$v},expression:"successSubempreitada"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.chooseDate),callback:function ($$v) {_vm.chooseDate=$$v},expression:"chooseDate"}},[_c('DatesPicker',{on:{"confirm":_vm.startExport,"close":function($event){_vm.chooseDate = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }