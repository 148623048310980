<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" >
                <h2 class="primary--text">Registo Diário Encarregado</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>

                <Form ref="pd" :loading="loading" @submited="createPD">
                    <v-row>
                        <v-col cols="12" md="2">
                            <DateInput 
                                label="Data"
                                placeholder="Insira Data"
                                v-model="fields.Data"
                                rules="required"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <Select
                                v-model="fields.obra"
                                :items="obras_user_tem_acesso"
                                item-text="Descricao"
                                label="Obras"
                                rules="required"
                                vid="obras"
                                return-object
                                @change="choosed_work"
                            />
                        </v-col>
                        <v-col cols="12" md="4" v-if="fields.obra != null && fields.obra.classes != null && fields.obra.classes.length > 0">
                            <Select
                                v-model="fields.classe"
                                :items="fields.obra.classes"
                                item-text="Descricao"
                                label="Classes"
                                rules="required"
                                vid="classe"
                                return-object
                                @change="workChoosed = true; escolhaUnidade();"
                            />
                        </v-col>
                        <v-col cols="12" md="4" v-if="choosedWorkFlag && frentes_obra.length > 0">
                            <Select
                                v-model="fields.frente_obra"
                                :items="computeFrentesObra"
                                item-text="Descricao"
                                label="Frentes de Obra"
                                rules="required"
                                vid="frentes_obra"
                                return-object
                                @change="fillData"
                            />
                        </v-col>
                        <v-col cols="12" md="3" v-if="frentes_obra.length > 0">
                            <Select
                                :disabled="fields.frente_obra == null"
                                v-model="fields.classe"
                                :items="classes"
                                item-text="Descricao"
                                label="Unidade Obra"
                                rules="required"
                                vid="classe"
                                return-object
                                @change="fillMO"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="importTemplate" class="ma-2">
                        <v-col cols="12" md="auto">
                            <v-btn 
                                block
                                dark
                                color="#BF360C" 
                                @click="parseTemplateData"
                            ><v-icon class="mr-2">mdi-export</v-icon>Importar template</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="!workChoosed">
                        <v-col cols="12">
                            <v-alert
                            icon="mdi-alert-box"
                            prominent
                            text
                            type="error"
                            >
                                Escolha uma <strong>data</strong>, <strong>frente de obra</strong> e <strong>unidade de obra</strong> para prosseguir
                            </v-alert>
                        </v-col>
                    </v-row>
                    
                    <Stepper style="width: 100% !important" class="ma-0 pa-0" :headers="['MO', 'EQ', 'MAT', 'SUB', 'Observações']" v-show="workChoosed">
                        <template v-slot:1>
                            <v-row>
                                <v-col cols="12">         
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">MO Interna</h3>
                                    </v-toolbar>
                                    <Table :headers="moHeaders" :items="itemsMOInternal" item-value="id">                                        
                                        <template v-slot:funcionario="{item}">                                          
                                            <SearchUserById 
                                                :funcionarios="funcionarios"
                                                :userName="searchByIdName"     
                                                :selectedFunc="item.funcionario"                                         
                                                v-model="item.funcionario"
                                               />
                                            <SelectAutoComplete
                                                :items="funcionarios"
                                                item-text="Nome"
                                                label="Funcionário"
                                                rules="required"
                                                vid="funcionario"
                                                class="basic"
                                                v-model="item.funcionario"
                                                return-object
                                                @change="render0++"
                                            />
                                        </template>
                                        <template v-slot:hn="{item}">
                                            <Input 
                                                label="HN"
                                                placeholder="Insira HN"
                                                v-model="item.hn"
                                            />
                                        </template>
                                        <template v-slot:hext="{item}">
                                            <Input 
                                                label="HExt"
                                                placeholder="Insira HExt"
                                                v-model="item.hext"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deleteFuncionario(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addFuncionario"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-12 mb-12"/>

                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">MO Externa</h3>
                                    </v-toolbar>
                                    <Table :headers="moHeaders" :items="itemsMOExternal" item-value="id">
                                        <template v-slot:entidade_pessoal="{item}">
                                            <Select
                                                :items="entidades_pessoal"
                                                item-text="Nome"
                                                label="Pessoal"
                                                rules="required"
                                                vid="pessoal"
                                                class="basic"
                                                v-model="item.entidade_pessoal"
                                                return-object
                                                @change="render1++"
                                            />
                                        </template>
                                        <template v-slot:hn="{item}">
                                            <Input 
                                                label="HN"
                                                placeholder="Insira HN"
                                                v-model="item.hn"
                                            />
                                        </template>
                                        <template v-slot:hext="{item}">
                                            <Input 
                                                label="HExt"
                                                placeholder="Insira HExt"
                                                v-model="item.hext"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deletePessoal(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addPessoal"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>   
                        </template>
                        <template v-slot:2>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Equipamento Interno</h3>
                                    </v-toolbar>
                                    <Table :headers="equipHeaders" :items="itemsEquipInternal" item-value="id">
                                        <template v-slot:codigo="{item}">
                                            <SelectAutoComplete
                                                :items="equipamentos.interno"
                                                item-text="EntityKey"
                                                label="Código equip."
                                                rules="required"
                                                vid="equipamento"
                                                class="basic"
                                                v-model="item.equipamento"
                                                return-object
                                                @change="render2++"
                                            />
                                        </template>
                                        <template v-slot:equipamento="{item}">
                                            <SelectAutoComplete
                                                :items="equipamentos.interno"
                                                item-text="Descricao"
                                                label="Equipamento"
                                                rules="required"
                                                vid="equipamento"
                                                class="basic"
                                                v-model="item.equipamento"
                                                return-object
                                                @change="render2++"
                                            />
                                        </template>
                                        <template v-slot:hs="{item}">
                                            <Input 
                                                label="HS/dia"
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </template>
                                        <template v-slot:ho="{item}">
                                            <Input 
                                                label="HO"
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deleteEquipamentoInterno(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addEquipamentoInterno"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-12 mb-12"/>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Equipamento Externo</h3>
                                    </v-toolbar>
                                    <Table :headers="equipHeaders" :items="itemsEquipExternal" item-value="id">
                                        <template v-slot:codigo="{item}">
                                            <SelectAutoComplete
                                                :items="equipamentos.externo"
                                                item-text="EntityKey"
                                                label="Código equip."
                                                rules="required"
                                                vid="equipamento"
                                                class="basic"
                                                v-model="item.equipamento"
                                                return-object
                                                @change="render3++"
                                            />
                                        </template>
                                        <template v-slot:equipamento="{item}">
                                            <SelectAutoComplete
                                                :items="equipamentos.externo"
                                                item-text="Descricao"
                                                label="Equipamento"
                                                rules="required"
                                                vid="equipamento"
                                                class="basic"
                                                v-model="item.equipamento"
                                                return-object
                                                @change="render3++"
                                            />
                                        </template>
                                        <template v-slot:hs="{item}">
                                            <Input 
                                                label="HS/dia"
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </template>
                                        <template v-slot:ho="{item}">
                                            <Input 
                                                label="HO"
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deleteEquipamentoExterno(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addEquipamentoExterno"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:3>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Material</h3>
                                    </v-toolbar>
                                    <Table :headers="materialHeaders" :items="itemsMaterial" item-value="id">
                                        <template v-slot:product="{item}">
                                            <SelectAutoComplete
                                                :items="sub_familias"
                                                item-text="SubFamilia.Descricao"
                                                label="Artigo"
                                                rules="required"
                                                vid="product"
                                                class="basic"
                                                v-model="item.product"
                                                return-object
                                                @change="render4++"
                                            />
                                        </template>
                                        <template v-slot:un="{item}">
                                            {{ getUnity(item) }}
                                        </template>
                                        <template v-slot:qty="{item}">
                                            <Input 
                                                label="Quantidade"
                                                placeholder="Insira Quantidade"
                                                v-model="item.qty"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deleteMaterial(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addMaterial"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:4>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Subempreitada</h3>
                                    </v-toolbar>
                                    <Table :headers="subcontractHeaders" :items="itemsSubcontract" item-value="id">
                                        <template v-slot:work="{item}">
                                            <SelectAutoComplete
                                                :items="sub_empreitadas"
                                                item-text="Descricao"
                                                label="Trabalho"
                                                rules="required"
                                                vid="work"
                                                class="basic"
                                                v-model="item.work"
                                                return-object
                                                @change="render5++"
                                            />
                                        </template>
                                        <template v-slot:qty="{item}">
                                            <Input 
                                                label="Quantidade"
                                                placeholder="Insira Quantidade"
                                                v-model="item.qty"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            dark
                                            small
                                            color="error"
                                            @click="deleteSubEmpreitada(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addSubEmpreitada"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:5>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar
                                        dark
                                        color="primary"
                                        class="rounded-lg"
                                        >
                                        <v-toolbar-title>Observações de Mão de Obra:</v-toolbar-title>
                                    </v-toolbar>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="fields.obsMO"
                                        label="Observações"
                                        hint="Escreva as suas observações relativas à mão de obra"
                                        persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar
                                        dark
                                        color="primary"
                                        class="rounded-lg"
                                        >
                                        <v-toolbar-title>Observações de Equipamentos:</v-toolbar-title>
                                    </v-toolbar>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="fields.obsEQ"
                                        label="Observações"
                                        hint="Escreva as suas observações relativas aos equipamentos"
                                        persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </template>
                    </Stepper>
                </Form>   
            </v-col>
        </v-row>
        <SuccessSnackbar message="Dados gravados!" v-model="success"/>
        <SuccessSnackbar message="Ação realizada com sucesso!" v-model="successAction"/>

        <ErrorSnackbar v-model="error" :message="error_message"/>
        
    </v-container>    
</template>

<script>
import Input from "@/components/UI/Inputs/Input"
import DateInput from "@/components/UI/Inputs/DateInput"
import SearchUserById from "@/components/UI/Inputs/SearchUserById"
import Table from "@/components/UI/Tables/Table"
import Stepper from "@/components/UI/Steppers/Stepper"
import dayjs from 'dayjs'
import Select from "@/components/UI/Inputs/Select";
import Form from "@/components/UI/Forms/Form";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import User from "@/api/User.js"

export default {
    components: {
        Input,
        DateInput,
        Table,
        Stepper,
        Select,
        SelectAutoComplete,
        Form,
        SuccessSnackbar,
        ErrorSnackbar,
        SearchUserById,
    },
    data: () => ({
        choosedWorkFlag: false,
        importTemplate: false,
        templateData: null,
        workChoosed: false, 
        lookById: true,
        searchByIdName: '',
        classes: [],
        frentes_obra: [],
        render0: 0,
        render1: 0,
        render2: 0,
        render3: 0,
        render4: 0,
        render5: 0,
        successAction: null,
        success: null,
        loading: false,
        error: null,
        error_message: "",
        maoObra: [],
        fields: {
            frente_obra: null,
            classe: null,
            obra: null,
            Data: null,
            classe: null,
            obsMO: '',
            obsEQ: ''
        },
        moHeaders: [
            {
                alignment: "center",
                text: "Nome"
            },
            {
                alignment: "center",
                text: "HN"
            },
            {
                alignment: "center",
                text: "HExt"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMOInternal: [],
        itemsMOExternal: [],
        equipHeaders: [
            {
                alignment: "center",
                text: "Código"
            },
            {
                alignment: "center",
                text: "Nome",
                width: '40%'
            },
            {
                alignment: "center",
                text: "HS/dia",
                width: '8%'
            },
            {
                alignment: "center",
                text: "HO",
                width: '8%'
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsEquipInternal: [],
        itemsEquipExternal: [],
        materialHeaders: [
            {
                alignment: "center",
                text: "Artigo"
            },
            {
                alignment: "center",
                text: "UN"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMaterial: [],
        subcontractHeaders: [
            {
                alignment: "center",
                text: "Trabalho"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsSubcontract: [],
        funcionarios: [],
        entidades_pessoal: [],
        equipamentos: [],
        sub_familias: [],
        sub_empreitadas: [],
        obras_user_tem_acesso: []
    }),
    mounted(){
        this.fillBaseData()
    },
    methods: {
        fillBaseData(){
            //\Route::name("admin.users.get-obras")->get("get-obras/{id}", "Obras\GetObras");
            User.getUserObras(this.$store.state.user.data.id).then((resp)    =>  {
                this.obras_user_tem_acesso = resp.data
            })
            
        },
        parseTemplateData(){
            this.templateData.mo_internal.forEach(element => {
                element.Codigo = element.Codigo.replace(/\s/g, '');
                let mo_internal_object = this.search_for_user(element.Codigo, true)
                if(mo_internal_object != null)
                    this.itemsMOInternal.push({
                        id: dayjs().unix(),
                        funcionario: mo_internal_object,
                        hn: element.hn,
                        hext: element.hext,
                        local: element.local,
                        options: ''
                    })
            });
            

            this.templateData.mo_external.forEach(element => {
                let mo_external_object = this.search_for_entidade_pessoal(element.Codigo, true)
                if(mo_external_object != null)
                    this.itemsMOExternal.push({
                        id: dayjs().unix(),
                        entidade_pessoal: mo_external_object,
                        hn: element.hn,
                        hext: element.hext,
                        local: element.local,
                        options: ''
                    })
            });

            this.templateData.eq_internal.forEach(element => {
                let eq_internal_object = this.search_equipment(element.equipment_id, true)
                if(eq_internal_object != null)
                    this.itemsEquipInternal.push({
                        id: dayjs().unix(),
                        codigo: element.equipment_id,
                        equipamento: eq_internal_object,
                        hs: element.hs,
                        ho: element.ho,
                        local: element.local,
                        options: ""
                    });
            });

            this.templateData.eq_external.forEach(element_external => {
                let eq_external_object = this.search_equipment(element_external.equipment_id, false)
                if(eq_external_object != null)
                    this.itemsEquipExternal.push({
                        id: dayjs().unix(),
                        codigo: element_external.equipment_id,
                        equipamento: eq_external_object,
                        hs: element_external.hs,
                        ho: element_external.ho,
                        local: element_external.local,
                        options: ""
                    });
            });

            this.templateData.material.forEach(element => {
                let material_object = this.search_material(element.product_familia, element.product_sub_familia)
                if(material_object != null)
                    this.itemsMaterial.push({
                        id: dayjs().unix(),
                        product: material_object,
                        un: element.product_unit,
                        qty: element.qty,
                        local: element.local,
                        options: ""
                    });
            });

            this.templateData.sub_empreitadas.forEach(element => {
                let work_object = this.search_work(element.obra_id)
                if(work_object != null)
                    this.itemsSubcontract.push({
                        id: dayjs().unix(),
                        work: work_object,
                        qty: element.qty,
                        local: element.local,
                        options: ""
                    });
            });


            this.successAction = true
            
        },
        search_work(id){
            let ob = null;
            this.sub_empreitadas.forEach(element => {
                if(element.Id == id)
                    ob = element
            });
            return ob
        },
        search_material(familia, sub_familia){
            let ob = null
            this.sub_familias.forEach(element => {
                if(element.SubFamilia.Familia == familia && element.SubFamilia.SubFamilia == sub_familia)
                    ob = element
            });

            return ob
        },
        search_equipment(id, internal){
            let ob = null
            let searcher = this.equipamentos.interno
            
            if(!internal)
                searcher = this.equipamentos.externo

            searcher.forEach(element => {
                if(element.Id == id)
                    ob = element
            });
            
            return ob
        },
        getUnity(i){
            if(i==null)
                return "-"

            if(i.product == null)
                return '-'
                
            return i.product.unidade || "-"
        },
        search_for_entidade_pessoal(id){
            let ob = null
            this.entidades_pessoal.forEach(element => {
                if(element.ID == id)
                    ob = element
            });
            return ob
        },
        search_for_user(id, return_object = false){
            if(return_object){
                let ob = null;
                this.funcionarios.forEach(func => {
                    if(parseInt(func.Codigo) == parseInt(id))
                        ob = func
                });
                return ob
            }
            let parent = this
            this.funcionarios.forEach(func => {

                if(func.Codigo == id){
                    parent.itemsMOInternal.funcionario = func
                    parent.searchByIdName = func.Nome
                }
            });

            this.render0++
        },  
        checkExistence(){
            let data = {
                classeID: this.fields.classe.ClasseId,
                SubEmpId: this.fields.frente_obra.SubEmpId,
                Codigo: this.fields.frente_obra.obra.Codigo,
                classe: this.fields.classe.ID,
                Data: this.fields.Data
            };
            this.$store.dispatch("checkPDencExistence", data)
            .then(() => {
                this.success = true;

                this.error = false;

                this.loading = false;

                
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });

        },        
        fillData() {    
            if(this.fields.frente_obra == null) return
            
            let data = {
                id: this.fields.frente_obra.SubEmpId,
                place: 'encarregado'
            };   
            
            this.$store.dispatch("listFrenteObraClasses", data)
                .then(res => {
                    this.classes = res.data;
                }) 
                  

            this.$store.dispatch("listSubEmpreitadas", data.id)
                .then(res => {
                    this.sub_empreitadas = res.data;
                })  


            let object_data = {
                id: this.fields.obra.Id,
                frente: this.fields.frente_obra.ID,
                unidade: null
            }

            this.$store.dispatch("getObrasByClassFuncionarios", object_data)
                .then(res => {
                    console.log(res.data)
                })  
                
            
            let materialsData ={
                id_frente_obra: this.fields.frente_obra.SubEmpId,
                id_obra_classe: this.fields.classe == null ? null : '1'
            }

            this.$store.dispatch("getMaterials", materialsData)
                .then(res => {
                    this.sub_familias = res.data;
                })   
                
            // Ver se há templates
            let codesData = {
                classeId: this.fields.classe == null ? null : this.fields.classe.ClasseId,
                subEmpId: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                work_code: this.fields.obra.Codigo
            }
            
            this.$store.dispatch("getByCodes", codesData)
            .then(res => {
                if(res.data.template != null){
                    this.importTemplate = true
                    this.templateData = res.data.template
                }
                
            })  
        },
        choosed_work(){
            this.choosedWorkFlag = true
            this.loading = true


            this.frentes_obra = this.fields.obra.frentes_obra

            let data = {
                work_id: this.fields.obra.Id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'encarregado'
            }

            this.$store.dispatch("listObrasEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data;
                })  


            let object_data = {
                id: this.fields.obra.Id,
                frente: null
            }

            this.$store.dispatch("getObraFuncionarios", object_data)
                .then(res => {
                    this.funcionarios = res.data;
                })  

            this.$store.dispatch("listObrasPessoal", this.fields.obra.Id)
                .then(res => {
                    this.entidades_pessoal = res.data;
                })  

            // Ver se há templates
            let codesData = {
                classeId: this.fields.classe == null ? null : this.fields.classe.ClasseId,
                subEmpId: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                work_code: this.fields.obra.Codigo
            }
            
            this.$store.dispatch("getByCodes", codesData)
            .then(res => {
                if(res.data.template != null){
                    this.importTemplate = true
                    this.templateData = res.data.template
                }
                
            })  

            this.loading = false
        },
        escolhaUnidade(){

            let object_data = {
                id: this.fields.obra.Id,
                frente: null, //this.fields.frente_obra.ID,
                unidade: this.fields.classe.ID,
                short_id: false
            }

            this.$store.dispatch("getObrasByClassFuncionarios", object_data)
                .then(res => {
                    this.funcionarios = res.data.interno
                    this.entidades_pessoal = res.data.externo
                })  
        },
        async fillMO() {
            // \Route::name("admin.pdenc.checkExistence")->put("/", "CheckInserted");

            // Verificar se já existe alguma Diária com estas condições
            //this.checkExistence();

            this.loading = true

            let data = {
                id: this.fields.classe.ID,
            };
            
            let dataListFrenteObra = {
                classe: this.fields.classe.ClasseId,
                id: this.fields.frente_obra.SubEmpId,
                place: 'encarregado'
            }

            this.$store.dispatch("listFrenteObraEquipamentos", dataListFrenteObra)
                .then(res => {
                    this.equipamentos = res.data;
                })  

            this.$store.dispatch("listClasseFuncionarios", data)
                .then(res => {
                    this.funcionarios = res.data;
                })

           this.$store.dispatch("listObrasPessoal", data.id)
                .then(res => {
                    this.entidades_pessoal = res.data;
                })  

            let object_data = {
                id: this.fields.obra.Id,
                frente: this.fields.frente_obra.ID,
                unidade: this.fields.classe.ID,
                short_id: false
            }

            this.$store.dispatch("getObrasByClassFuncionarios", object_data)
                .then(res => {
                    this.funcionarios = res.data.interno
                    this.entidades_pessoal = res.data.externo
                })  
            
            let materialsData ={
                id_frente_obra: this.fields.frente_obra.SubEmpId,
                id_obra_classe: this.fields.classe == null ? null : this.fields.classe.ClasseId
            }

            this.$store.dispatch("getMaterials", materialsData)
                .then(res => {
                    this.sub_familias = res.data;
                    
                })  

            let codesData = {
                classeId: this.fields.classe.ClasseId,
                subEmpId: this.fields.frente_obra.SubEmpId,
                work_code: this.fields.obra.Codigo
            }
            
            await this.$store.dispatch("getByCodes", codesData)
            .then(res => {
                if(res.data.template != null){
                    this.importTemplate = true
                    this.templateData = res.data.template
                }
                
            })  
            
            this.loading = false
            this.workChoosed = true
        },
        addFuncionario() {
            this.itemsMOInternal.push({
                id: dayjs().unix(),
                funcionario: null,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                options: ""
            });
        },
        deleteFuncionario(id) {
            let index = this.itemsMOInternal.findIndex(item => item.id == id);

            this.itemsMOInternal.splice(index, 1);
        },
        addPessoal() {
            this.itemsMOExternal.push({
                id: dayjs().unix(),
                entidade_pessoal: null,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                options: ""
            });
        },
        deletePessoal(id) {
            let index = this.itemsMOExternal.findIndex(item => item.id == id);

            this.itemsMOExternal.splice(index, 1);
        },
        addEquipamentoInterno() {
            this.itemsEquipInternal.push({
                id: dayjs().unix(),
                codigo: null,
                equipamento: null,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        deleteEquipamentoInterno(id) {
            let index = this.itemsEquipInternal.findIndex(item => item.id == id);

            this.itemsEquipInternal.splice(index, 1);
        },
        addEquipamentoExterno() {
            this.itemsEquipExternal.push({
                id: dayjs().unix(),
                codigo: null,
                equipamento: null,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        deleteEquipamentoExterno(id) {
            let index = this.itemsEquipExternal.findIndex(item => item.id == id);

            this.itemsEquipExternal.splice(index, 1);
        },
        addMaterial() {
            this.itemsMaterial.push({
                id: dayjs().unix(),
                product: null,
                un: "-",
                qty: 0,
                local: null,
                options: ""
            });
        },
        deleteMaterial(id) {
            let index = this.itemsMaterial.findIndex(item => item.id == id);

            this.itemsMaterial.splice(index, 1);
        },
        addSubEmpreitada() {
            this.itemsSubcontract.push({
                id: dayjs().unix(),
                work: null,
                qty: 0,
                local: null,
                options: ""
            });
        },
        deleteSubEmpreitada(id) {
            let index = this.itemsSubcontract.findIndex(item => item.id == id);

            this.itemsSubcontract.splice(index, 1);
        },
        createPD() {
            this.loading = true;
            
            let data = {
                classeID: this.fields.classe == null ? null : this.fields.classe.ClasseId,
                SubEmpId: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                Codigo: this.fields.obra.Codigo,
                classe: this.fields.classe == null ? null : this.fields.classe.ID,
                Data: this.fields.Data,
                maoObraInterna: this.itemsMOInternal,
                maoObraExterna: this.itemsMOExternal,
                equipamentoInterno: this.itemsEquipInternal,
                equipamentoExterno: this.itemsEquipExternal,
                material: this.itemsMaterial,
                subEmpreitadas: this.itemsSubcontract,
                obsMO: this.fields.obsMO,
                obsEQ: this.fields.obsEQ
            };

            // Verificar se há 1 linha de mao de obra externa/interna
            let arrays_vazios = 0
            if(this.itemsMOInternal.length == 0)
                arrays_vazios++
            if(this.itemsMOExternal.length == 0)
                arrays_vazios++
            if(this.itemsEquipInternal.length == 0)
                arrays_vazios++
            if(this.itemsEquipExternal.length == 0)
                arrays_vazios++

            if(arrays_vazios == 4){
                this.error_message = "Adicione pelo menos 1 equipamento ou mão de obra"
                this.error = true
                this.loading = false;
                return
            }
            

            this.$store.dispatch("createPDEnc", data)
            .then(() => {
                this.success = true;

                this.error = false;

                this.loading = false;

                setTimeout(() => {
                    this.$router.push("/diarios");
                }, 2000);
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });
        }
    },
    computed: {
        computeFrentesObra(){
            return this.frentes_obra
        },
        crumbs: function() {
        return [
            {
                text: 'Registo Diário Encarregado',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>
::v-deep .v-data-table .v-select{
  width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}
</style>