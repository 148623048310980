<template>
    <v-container fluid>
        <Loader v-if="!allLoaded"/>
        <v-row v-if="allLoaded">
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Registo Diário Manobrador</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-row>
                    <v-col cols="12" md="3" v-if="sincronized">
                        <v-alert type="warning" shaped prominent dark v-show="sincronized" transition="scale-transition">
                            Diária sincronizada!
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <v-alert type="warning" v-if="fields.current_approval.level != undefined" v-show="fields.current_approval.level == 1" shaped prominent dark transition="scale-transition">
                            Aprovada por administrativo
                        </v-alert>
                    </v-col>
                </v-row>
                <v-divider class="mb-12"></v-divider>


                <Form :viewOnly="VIEW_ONLY" :loading="loading" ref="pd" @submited="editPD" :submitText="'Guardar'" @approve-diary="confirmApprove" :toApprove="checkIfCanApprove(fields.current_approval)">
                    <v-row>
                        <v-col cols="12" md="2">
                            <Input
                                label="Data"
                                placeholder="Insira Data"
                                v-model="fields.Data"
                                disabled
                            />
                        </v-col>
                        <v-col cols="12" md="5">
                            <span class="font-weight-bold">Obra: </span>
                            <span>{{fields.obra.Descricao}}</span>
                            <!--<Select
                                v-model="fields.obra"
                                :items="$store.state.user.data.obras"
                                item-text="Descricao"
                                label="Obras"
                                rules="required"
                                :disabled="true"
                                vid="obras"
                                return-object
                            />-->
                        </v-col>
                        <v-col cols="12" md="5" v-if="fields.frente_obra != null">
                            <span class="font-weight-bold">Frente de Obra: </span>
                            <span>{{fields.frente_obra.Descricao}}</span>
                            <!--<Input
                                v-model="fields.frente_obra.Descricao"
                                label="Frentes de Obra"
                                vid="frentes_obra"
                                return-object
                                disabled
                            />-->
                        </v-col>
                    </v-row>

                    <v-row v-if="hasEquipamentos">
                        <v-col cols="12">
                            <Table :headers="headers" :items="line" item-value="id">
                                <template v-slot:equip="{item}">
                                    <SelectAutoComplete
                                        v-if="item.accessory && item.id.includes('new')"
                                        :items="unassignedMachines"
                                        item-text="Descricao"
                                        label="Acessório"
                                        rules="required"
                                        vid="frentes_obra"
                                        class="basic"
                                        v-model="item.equip"
                                        return-object
                                    />
                                    <SelectAutoComplete
                                        v-else-if="item.id.includes('new')"                                        
                                        :items="equipamentos"
                                        item-text="Descricao"
                                        label="Equipamento"
                                        rules="required"
                                        vid="frentes_obra"
                                        class="basic"
                                        v-model="item.equip"
                                        return-object
                                        @change="render++"
                                    />
                                    <p v-else>{{getEquipmentName(item.id)}}</p>
                                </template>
                                <template v-slot:ho="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="HO"
                                            placeholder="Insira HO"
                                            v-model="item.ho"
                                            :disabled="VIEW_ONLY"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:hs="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="HS"
                                            placeholder="Insira HS"
                                            v-model="item.hs"
                                            :disabled="VIEW_ONLY"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:ha="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="HA"
                                            placeholder="Insira HA"
                                            v-model="item.ha"
                                            :disabled="VIEW_ONLY"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:km="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="KM"
                                            placeholder="Insira KM"
                                            v-model="item.km"
                                            :disabled="VIEW_ONLY"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:h="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="H"
                                            placeholder="Insira H"
                                            v-model="item.h"
                                            :disabled="VIEW_ONLY"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:local="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item)"   
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:user="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <span :key="render">{{ item.user }}</span>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:hn="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="HN"
                                            placeholder="Insira HN"
                                            v-model="item.hn"
                                            :disabled="isDisabled(item)"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:hext="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="HExt"
                                            placeholder="Insira HExt"
                                            v-model="item.hext"
                                            :disabled="isDisabled(item)"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:diesel="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="Qtd. Diesel"
                                            placeholder="Insira Qtd"
                                            v-model="item.diesel"
                                            :disabled="isDisabled(item)"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:oil="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="Qtd. Óleo"
                                            placeholder="Insira Qtd"
                                            v-model="item.oil"
                                            :disabled="isDisabled(item)"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:adblue="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                        <Input 
                                            label="Qtd. Adblue"
                                            placeholder="Insira Qtd"
                                            v-model="item.adblue"
                                            :disabled="isDisabled(item)"
                                        />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:options="{item}">
                                    <v-btn
                                    v-if="!VIEW_ONLY"
                                    dark
                                    small
                                    color="error"
                                    @click="deleteLine(item.id)"
                                    >
                                    <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                
                            </Table>
                                <v-divider></v-divider>
                            <v-row justify="center" v-if="!VIEW_ONLY">                                
                                <v-col cols="auto" md="auto">
                                    <v-btn 
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        class="mt-4 mb-4"
                                        @click="addLineToTable"
                                    >
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" md="auto">
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        class="mt-4 mb-4"
                                        color="warning"
                                        @click="addAccessoryLine"
                                        >
                                        <v-icon small>mdi-bulldozer</v-icon>
                                    </v-btn>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </Form>   
            </v-col>
        </v-row>

        <SuccessSnackbar message="Dados gravados!" v-model="success"/>

        <SuccessSnackbar message="Diária aprovada com sucesso!" v-model="successApproval"/>

        <ErrorSnackbar v-model="error" :message="error_message"/>

        
        <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
        <InputEditorModal
            :disabled="VIEW_ONLY" @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>

        <DialogConfirmation 
            v-if="confirmationModal.show"
            :title="confirmationModal.title" 
            type="warning" 
            :opened="confirmationModal.show" 
            :elevation="0"
            @on-submit="approveDiary" 
            @on-cancel="confirmationModal.item = null; confirmationModal.show = false;">
                {{ confirmationModal.message }}
        </DialogConfirmation> 
      

    </v-container>    
</template>

<script>
import Input from "@/components/UI/Inputs/Input"
import DateInput from "@/components/UI/Inputs/DateInput"
import Table from "@/components/UI/Tables/Table"
import dayjs from 'dayjs'
import Select from "@/components/UI/Inputs/Select";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import Form from "@/components/UI/Forms/Form";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Loader from "@/components/UI/Loaders/Loader";
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';



export default {
    components: {
        Input,
        DialogConfirmation,
        DateInput,
        Table,
        Select,
        Form,
        SuccessSnackbar,
        ErrorSnackbar,
        SelectAutoComplete,
        Loader,
        InputEditorModal
    },    
    props:{
        preview_diary: {
            typeof: Boolean,
            default: false
        },
        diary_helper: {
            typeof: Object,
            defautl: null
        }
    },
    data: () => ({
        confirmationModal:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        },
        sincronized: false,
        inputEditorModalData:{
            itemModel:null,
            id: null,
        },
        editorModal:false,
        allLoaded:false,
        VIEW_ONLY: false,
        hasEquipamentos: false,
        render: 0,
        success: null,
        successApproval: false,
        loading: false,
        error: null,
        error_message: "",
        headers: [
            {
                alignment: "center",
                text: "Equip."
            },
            {
                alignment: "center",
                text: "HO"
            },
            {
                alignment: "center",
                text: "HS"
            },
            {
                alignment: "center",
                text: "HA"
            },
            {
                alignment: "center",
                text: "KM"
            },
            {
                alignment: "center",
                text: "H"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Utilizador"
            },
            {
                alignment: "center",
                text: "HN"
            },
            {
                alignment: "center",
                text: "HExt"
            },
            {
                alignment: "center",
                text: "Diesel"
            },
            {
                alignment: "center",
                text: "Óleo"
            },
            {
                alignment: "center",
                text: "Adblue"
            },
            {
                alignment: "center",
                text: ""
            },
        ],
        fields: {
            frente_obra: null,
            Data: null,
            IdCabecEQ: null,
            IdCabecMO: null,
            lines: null,
            current_approval: null,
            obra: null
        },
        approvalObject:{
            id_user_creator: null,
            creationRole: null,
            webAppLinkHash: null
        },
        equipamentos: {
            interno: [],
            externo: []
        },
        unassignedMachines: [],
        line: [{
            id: dayjs().unix(),
            equip: null,
            ho: 0,
            hs: 0,
            ha: 0,
            km: 0,
            h: 0,
            local: null,
            user: null,
            hn: 0,
            hext: 0,
            diesel: 0,
            oil: 0,
            adblue: 0,
            options: ""
        }]
    }),
    mounted(){
        let ID = this.$route.params.id
        let type = this.$route.params.type
        
        let params={
            ID: ID,
            id_user: this.$route.params.id_user,
            hash: this.$route.params.hash
        }

        if(this.preview_diary){
            this.VIEW_ONLY = true
            ID = this.diary_helper.diary_id
            params = {
                id: ID,
                hash: this.diary_helper.diary_web_hash,
                id_user: this.diary_helper.diary_user
            }
        }

            
            this.$store.dispatch(type == 'eq' ? "getPDEq" : "getPDMaoObra", params)
                .then(res => {
                    let data = res.data;
                    // Check if is view only
                    this.lockEditing(data);

                    this.fields.Data = data.Data.split(' ')[0]
                    this.fields.IdCabecEQ = data.IdCabecEQ
                    this.fields.IdCabecMO = data.IdCabecMO
                    this.fields.current_approval = data.current_approval


                    if(this.fields.current_approval == null){
                        this.approvalObject.id_user_creator = data.id_user
                        this.approvalObject.creationRole = data.creationRole
                        this.approvalObject.webAppLinkHash = data.webAppLinkHash
                    }else{
                        this.approvalObject.id_user_creator = data.current_approval.id_user_creator
                        this.approvalObject.creationRole = data.current_approval.creation_role
                        this.approvalObject.webAppLinkHash = data.current_approval.pd_hash
                    }


                    this.fields.obra = data.obra
                    this.fields.frente_obra = data.empreitada

                    this.fields.lines = data.linhas
                    
                    this.fillEquipamentos()
                    
                    let parent = this
                    if(data.linhas.length > 0){
                        this.line = []
                    }
                    data.linhas.forEach(singleLine => {
                        parent.line.push({
                            id: singleLine.Id,
                            equip: singleLine.EquipId,
                            accessory: singleLine.accessory,
                            ho: singleLine.Ordem == null ? parseFloat(0).toFixed(2) : singleLine.Ordem == '' ? singleLine.Ordem : parseFloat(singleLine.Ordem).toFixed(2),
                            hs: singleLine.Servico == null ? parseFloat(0).toFixed(2) : singleLine.Servico == '' ? singleLine.Servico : parseFloat(singleLine.Servico).toFixed(2),
                            ha: singleLine.Avariado == null ? parseFloat(0).toFixed(2) : singleLine.Avariado == '' ? singleLine.Avariado : parseFloat(singleLine.Avariado).toFixed(2),
                            km: singleLine.Contador2 == null ? parseFloat(0).toFixed(2) : singleLine.Contador2 == '' ? singleLine.Contador2 : parseFloat(singleLine.Contador2).toFixed(1),
                            h:  singleLine.Contador1 == null ? parseFloat(0).toFixed(2) : singleLine.Contador1 == '' ? singleLine.Contador1 : parseFloat(singleLine.Contador1).toFixed(1),
                            local: singleLine.Obs,
                            user: singleLine.CodigoMO,
                            hn: singleLine.HNormal == null ? parseFloat(0).toFixed(2) : singleLine.HNormal == '' ? singleLine.HNormal : parseFloat(singleLine.HNormal).toFixed(2),
                            hext: singleLine.HExtra == null ? parseFloat(0).toFixed(2) : singleLine.HExtra == '' ? singleLine.HExtra : parseFloat(singleLine.HExtra).toFixed(2),
                            diesel: singleLine.Combustivel == null ? parseFloat(0).toFixed(2) : singleLine.Combustivel == '' ? singleLine.Combustivel : parseFloat(singleLine.Combustivel).toFixed(2),
                            oil: singleLine.oil,
                            adblue: singleLine.adblue,
                            options: "",

                        })
                    });
                    this.allLoaded = true
                })
                .catch(err => {

                    console.log(err)
            });
            
        
    },
    watch:{
        equipamentos(e){
            this.equipamentos = e
            this.hasEquipamentos = true
        }
    },
    methods: {
        isDisabled(item){
            if(item.accessory)
                return true;

            if(this.VIEW_ONLY)
                return true;

            return false;
        },
        approveDiary(){
            //webAppLinkHash
            //id_user_creator
            //creationRole
           
            let approval = this.approvalObject

            if(approval != null){
                // Send hash from Header
                this.$store.dispatch("approvePD", approval)
                    .then(() => {
                        this.confirmationModal.item = null
                        this.confirmationModal.show = false
                        this.successApproval = true
                        window.location.reload()

                });
            }
        },
        confirmApprove(){
            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
            this.confirmationModal.show = true
        },
        lockEditing(data){
            // Se veio do ERP fica só view only
            if(data.webAppLinkHash == null || data.CreatedBy != 'partesdiarias')
                this.VIEW_ONLY = true


            // Se já está aprovada, é só view only
            if(data.WebappApproved == 1)
                this.VIEW_ONLY = true


            // Se já foi aprovada pelo administrativo é só view only para ele e outros inferiores
            // O engenheiro e super podem continuar a aprovar

           if(data.obra.approval_level == 2){
                if(data.current_approval != null){
                    if(parseInt(data.current_approval.level) == 1)
                        if(!this.$store.getters.hasRole(['super', 'engenheiro']))
                            this.VIEW_ONLY = true
                }
            }
            if(data.obra.approval_level == 3){
                if(data.current_approval != null){
                    if(parseInt(data.current_approval.level) == 1)
                        if(!this.$store.getters.hasRole(['super', 'engenheiro', 'administrativo']))
                            this.VIEW_ONLY = true

                    if(parseInt(data.current_approval.level) == 2)
                        if(!this.$store.getters.hasRole(['super', 'engenheiro']))
                            this.VIEW_ONLY = true
                }
            }

            this.sincronized = data.sincronized
            if(data.sincronized)
                this.VIEW_ONLY = true
                
        },
        checkIfCanApprove(approval){
            // Verficar primeiro o role... Se não tiver role de administrativo ou engenheiro, não pode aprovar
            if(!this.$store.getters.hasRole(['administrativo', 'engenheiro']))
                return false


            // Se está a null, significa que é preciso ser aprovado pelo ADMINISTRATIVO
            if(approval == null){
                if(this.$store.getters.hasRole(['engenheiro']))
                    return false

                return true
            }

            if(parseInt(approval.level) == 0){
                return this.$store.getters.hasRole(['administrativo'])
            }
            
            // Se tem approval, verificar qual o role de aprovação. 
            // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
            if(approval.level == 1){
                return this.$store.getters.hasRole(['engenheiro'])
            }
            
            // Se estiver a 2, significa que já foi totalmente aprovado e não precisa de mais aprovações
            return false

        },
        deleteLine(id){
            /*if(this.line.length == 1){
                this.error_message = "Não pode apagar a única linha que tem"
                this.error = true
                return
            }*/
                
            let index = this.line.findIndex(item => item.id == id);

            let item = this.line[index]
            if(!item.id.includes('new-')){
                this.$store.dispatch("deleteEquipmentLine", id)
                    .then(() => {
                        this.success = true;

                        this.error = false;

                        this.loading = false;

                        setTimeout(() => {
                        
                        }, 2000);
                    }).catch(err => {

                        if(err.response.status == 422){

                            this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                        }
                        
                        this.success = false;

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
                }


            this.line.splice(index, 1);
        },
        changeEditorModal(e){
            this.line.forEach(element => {
                if(element.id == this.inputEditorModalData.id)
                    element.local = e
            });
        },
        openEditorModal(item){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.editorModal=true
        },
        getEquipmentName(code){
            let eq_name = ''
            
            this.fields.lines.forEach(element => {
                if(element.Id == code)
                eq_name = element.EquipDescricao
            });
            return eq_name
        },
        addLineToTable(){
            this.line.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                accessory: false,
                equip: null,
                ho: 0,
                hs: 0,
                ha: 0,
                km: 0,
                h: 0,
                local: null,
                user: null,
                hn: 0,
                hext: 0,
                diesel: 0,
                oil: 0,
                adblue: 0,
                options: ""
            })
        },
        addAccessoryLine(){
            let lastLine = this.line[this.line.length-1];

            this.line.push({
                id: 'new-'+dayjs().unix().toString(),
                accessory: true,
                equip: null,
                ho: lastLine.ho,
                hs: lastLine.hs,
                ha: lastLine.ha,
                km: lastLine.km,
                h: lastLine.h,
                local: null,
                user: null,
                hn: null,
                hext: null,
                diesel: null,
                oil: null,
                adblue: null,
                options: ""
            })
        },
        fillEquipamentos() {           
            if(this.fields.frente_obra != null){
                let data = {
                    id: this.fields.frente_obra.SubEmpId, 
                    funcionarioCodigo: this.$store.state.user.data.funcionario.Codigo,
                    place: 'manobrador'
                };

                this.$store.dispatch("listFrenteObraEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data.interno.concat(res.data.externo);
                })
            }else{
                let data = {
                work_id: this.fields.obra.Id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'manobrador'
            }

                this.$store.dispatch("listObrasEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data.externo;
                })  
            }

            this.$store.dispatch("getUnassigned", this.fields.obra.Id)
            .then((res) => {
                
                this.unassignedMachines = res.data

            }).catch(err => {

                console.log(err)
            });
        },
        editPD() {
            this.loading = true;

          

            let lineItems = []
            let i = 1;
            this.line.forEach(l => {
                // Já não precisamos das random strings, vamos apagar e por somente a palavra new
                if(l.id.includes('new'))
                    l.id = 'new'

                lineItems.push({
                    lineID: l.id,
                    accessory: l.accessory,
                    IdEquipamento: l.equip.Id,
                    NumLinha: i,
                    local: l.local,
                    Servico: l.hs,
                    Ordem: l.ho,
                    Avariado: l.ha,
                    Contador2: l.km,
                    Contador1: l.h,
                    HNormal: l.hn,
                    HExtra: l.hext,
                    diesel: l.diesel,
                    oil: l.oil,
                    adblue: l.adblue
                })
                i++
            });
            
            


            let codigo_aux = this.fields.frente_obra
            if(this.fields.obra != null){
                codigo_aux = this.fields.obra.Codigo
            }
            
            if(codigo_aux == null){
                if(this.fields.frente_obra != null){
                    codigo_aux = this.fields.frente_obra.obra.Codigo
                }
            }
            
     

            let data = {
                hash: this.$route.params.hash,
                id: this.$route.params.id,
                type: this.$route.params.type,
                Codigo: codigo_aux,
                SubEmpID: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                SubEmp: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmp,
                Data: this.fields.Data,
                IDCabecMO: this.fields.IdCabecMO,
                lines: lineItems,
                id_user_creator: this.$route.params.id_user
            };

            this.$store.dispatch("updatePDEq", data)
            .then(() => {
                this.success = true;

                this.error = false;

                this.loading = false;

                setTimeout(() => {

                    location.reload()
                   
                }, 2000);
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
                text: 'Consultar Diários',
                disabled: false,
                to: '/diarios',
                exact: true,
            },
            {
                text: 'Registo Diário Manobrador',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>
::v-deep .v-data-table .v-select{
  width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}
</style>