import Obra from "@/api/Obra";

export const obra = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listObras() {
            return new Promise((resolve, reject) => {
                Obra.all()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async onlyWorks() {
            return new Promise((resolve, reject) => {
                Obra.onlyWorks()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getObraFuncionarios(params, fields) {
            return new Promise((resolve, reject) => {
                Obra.getObraFuncionarios(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getObrasByClassFuncionarios(params, fields){
            return new Promise((resolve, reject) => {
                Obra.getObrasByClassFuncionarios(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listObrasEquipamentos(params, fields) {
            return new Promise((resolve, reject) => {
                Obra.getObraEquipamentos(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listObrasPessoal(params, id) {
            return new Promise((resolve, reject) => {
                Obra.getObraPessoal(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listObrasPessoalTemplate(params, id) {
            return new Promise((resolve, reject) => {
                Obra.getObraPessoalTemplate(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listObrasPessoalById(params, id) {
            return new Promise((resolve, reject) => {
                Obra.getObraPessoalById(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },

        
    }
}