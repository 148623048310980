<template>
    <v-container fluid v-if="!loading">
        <validation-observer ref="workform" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12" md="6">            
                        <v-col cols="12" align="left" class="text-body-1">
                            <v-btn @click="addWorkUnit" class="success">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-card>
                            <v-card-title class="primary white--text">
                                Unidades de obra
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table class="d-none d-lg-block" fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" width="30%">
                                                    Referência
                                                </th>
                                                <th class="text-left" width="50%">
                                                    Descrição
                                                </th>
                                                <th class="text-left" width="20%">
                                                    Opções
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in work_units" :key="index+32001"
                                            >
                                                <td>
                                                    <validation-provider v-slot="{ errors }" vid="Referência" :name="'Referência'" rules="required">
                                                        <v-text-field
                                                            label="Referência"
                                                            v-model="item.reference"
                                                            dense outlined
                                                            :error-messages="errors"
                                                            class="mt-4"
                                                        />
                                                    </validation-provider>
                                                </td>
                                                    <validation-provider v-slot="{ errors }" vid="Descrição" :name="'Descrição'" rules="required">
                                                        <v-text-field
                                                            label="Descrição"
                                                            v-model="item.description"
                                                            :error-messages="errors"
                                                            dense outlined
                                                            class="mt-4"
                                                        />
                                                    </validation-provider>
                                                <td>
                                                </td>
                                                <td>
                                                    <IconRemove
                                                        class="mr-2"
                                                        @on-submit="deleteWorkUnit(item)"
                                                    />                                            
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>    
                    </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                        <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                            Gravar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
    </v-container>
</template>
<script>
import Obra from '@/api/Obra.js'
import dayjs from 'dayjs'
import IconRemove from '@/components/UI/IconRemove.vue';

export default{
    name:"Work",
    components:{
        IconRemove
    },
    props:{
        work: Object,
        method: String
    },
    data(){
        return{
            work_units: [],
            loading: true,
        }
    },
    mounted(){
        this.fillBaseData();
    },
    methods:{
        fillBaseData(){
            this.work_units = this.work.unidades_obra
            this.loading = false
        },
        deleteWorkUnit(item){
            let pos = -1
            let i = 0

            this.work_units.forEach(element => {
                if(element.id == item.id)
                    pos = i

                i++
            });

            if(pos != -1){
                this.work_units.splice(pos, 1)
            }
        },
        addWorkUnit(){
            this.work_units.push({
                id: dayjs().unix() + '-new',
                reference: '',
                description: '',
            });
        },
        submit(){
            let data = {
                unidades: this.work_units
            }
            this.$refs.workform.validate().then((result) => {
                Obra.addWorkUnits(this.$route.params.id, data).then((resp)   =>  {
                    console.log(resp)
                })
            })
        }
    }
}
</script>