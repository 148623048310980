<template style="background-color:white;">
     <v-card>
      <v-card-title class="primary">
        <span class="text-h5 white--text">{{title}}</span>
      </v-card-title>
        <v-card-text>
            <v-container class="mt-4">
                <v-row>
                    <v-col>
                        <ValidationProvider :vid="vid" :name="$attrs.label"  v-slot="{errors}">
                            <v-textarea
                                outlined
                                :label="label"
                                v-bind="$attrs"
                                v-on="$listeners"
                                name="input-7-4"
                                :error-messages="errors"
                                @change="changeValue"
                                :disabled="disabled"
                            ></v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'InputEditorModal',
    props:{
        vid: String,
        model:String,
        title:String,
        label:String,
        disabled: Boolean
    },
    methods:{
        changeValue(e){
            this.$emit('change-value', e)
        }
    }
}
</script>