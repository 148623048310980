import API from "./API";

export default class Equipamentos extends API
{
    static async getLastHistory(id) {
        return this.get("api/admin/equipamentos/history/" + id);
    }

    static async allUnassigned(){
        return this.get("api/admin/equipamentos/unassigned/list");
    }

    static async getUnassigned(id){
        return this.get("api/admin/equipamentos/equipamento-obra/" + id);        
    }

    static async createEquipamentoObra(fields){
        return this.post("api/admin/equipamentos/equipamento-obra", fields);
    }

    static async removeEquipamentoObra(fields){
        return this.delete("api/admin/equipamentos/equipamento-obra", {params: fields});
    }

    static async all() {
        return this.get("api/admin/equipamentos/list");
    }

    static async search(params){
        return this.get("api/admin/equipamentos", {params:params});
    }
}