import Classe from "@/api/Classe";

export const classe = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listClasseFuncionarios(params, data) {
            return new Promise((resolve, reject) => {
                Classe.getClasseFuncionarios(data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listClasseFuncionariosTemplate(params) {
            return new Promise((resolve, reject) => {
                Classe.getClasseFuncionariosTemplate()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listClasseFuncionariosById(params, data) {
            return new Promise((resolve, reject) => {
                Classe.getClasseFuncionariosById(data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}