<template>
    <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{errors}">
       
            <v-autocomplete
                v-if="!personalizedFilter"
                style="width: 100% !important;"
                v-bind="$attrs"
                v-on="$listeners"
                dense
                :multiple="multiple"
                color="primary"
                :error-messages="errors"
            ></v-autocomplete>

            <v-autocomplete
                v-else
                style="width: 100% !important;"
                v-bind="$attrs"
                v-on="$listeners"
                dense
                :multiple="multiple"
                color="primary"
                :error-messages="errors"
                :filter="customFilter"
            ></v-autocomplete>
    
    </ValidationProvider>
</template>
<script>
export default {
    name: 'SelectAutoComplete',
    props: {
        rules: String,
        vid: String,
        multiple:{
            typeof: Boolean,
            default: false
        },
        personalizedFilter:{
            typeof: Boolean,
            default: false
        }
    },
    computed: {
    },
    methods: {
        customFilter(item, queryText, itemText) {
            const words = queryText.split(' ')
            return words.every(word => item.Nome.toLowerCase().includes(word.toLowerCase()))
        }
    }
}
</script>
<style scoped>

</style>