<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <!-- EQUIPAMENTO INTERNO -->
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="warning white--text">
                        Equipamento Interno
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" width="15%">
                                            Equipamento
                                        </th>
                                        <th class="text-left" width="15%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="15%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="7%">
                                            HS / dia
                                        </th>
                                        <th class="text-left" width="7%">
                                            HO
                                        </th>
                                        <th class="text-left" width="11%">
                                            Local
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeEquipamentosInternos" :key="item.id"
                                    >
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="equipamento" name="equipamento" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="equipamentos.interno"
                                                    :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Equipamento"
                                                    :error-messages="errors"
                                                    v-model="item.equipamento"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HS"
                                                outlined dense
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HO"
                                                outlined dense
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        <td>
                                            <v-text-field 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteEquipamentoInterno(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                            
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn fab @click="addEquipamentoInterno" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!-- EQUIPAMENTO INTERNO -->

        <!-- EQUIPAMENTO EXTERNO -->
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="warning white--text">
                        Equipamento Externo
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" width="15%">
                                            Equipamento
                                        </th>
                                        <th class="text-left" width="15%">
                                            Frente Obra
                                        </th>
                                        <th class="text-left" width="15%">
                                            Classe
                                        </th>
                                        <th class="text-left" width="7%">
                                            HS / dia
                                        </th>
                                        <th class="text-left" width="7%">
                                            HO
                                        </th>
                                        <th class="text-left" width="11%">
                                            Local
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in computeEquipamentosExternos" :key="item.id"
                                    >
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="equipamento_externo" name="equipamento_externo" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="equipamentos.externo"
                                                    :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Equipamento"
                                                    :error-messages="errors"
                                                    v-model="item.equipamento"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <div v-if="showFrente(item)">
                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                    <v-autocomplete                                                                                
                                                        v-model="item.frente_id"
                                                        :items="frentes_obra"
                                                        outlined dense
                                                        class="mt-4"
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Frentes de Obra *"
                                                        @change="changeFrenteObraV2(item)"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="showClasse(item)">
                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                    <v-autocomplete
                                                        v-model="item.classe_id"
                                                        :items="item.available_classes"
                                                        class="mt-4"
                                                        outlined dense
                                                        :item-text="ob => ob.Descricao"
                                                        item-value="ID"
                                                        :error-messages="errors"
                                                        label="Classe *"
                                                    />
                                                </validation-provider>
                                            </div>
                                        </td>
                                        <td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HS"
                                                outlined dense
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="HO"
                                                outlined dense
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        <td>
                                            <v-text-field 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                            />
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteEquipamentoExterno(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>                            
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn fab @click="addEquipamentoExterno" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!-- EQUIPAMENTO EXTERNO -->
    </v-container>
</template>
<script>
import Obra from "@/api/Obra.js"
import Classe from "@/api/Classe.js"
import dayjs from 'dayjs'

export default{
    name:"Equipments",
    props:{
        fields: Object,
        frentes_obra: Array,
        hideFrentes: Boolean,
        template_classes: Array
    },
    data(){
        return{
            classes: [],
            equipamentos: null,
            loading: true,
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        showFrente(item){
            console.log(this.hideFrentes)
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true


            return false
        },
        async changeFrenteObraV2(item){

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }


                await Classe.getClasses(data).then((resp) =>  {
                    item.available_classes = resp.data

                    if(item.available_classes.length == 0){
                        item.classeNeeded = false
                    }else item.classeNeeded = true
                    
                })

                
            }
        },
        fillBaseData(){
            let data = {
                work_id: this.fields.obra_id,
                codigo: null,
                place:'encarregado'
            }

            Obra.getObraEquipamentos(data).then((resp)  =>  {
                this.equipamentos = resp.data                
                this.loading = false
            })

            console.log(this.template_classes)

            if(this.hideFrentes)
                this.classes = this.template_classes
        },
        addEquipamentoInterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            this.fields.equipamentosInternos.push({
                id: dayjs().unix() + '-new',
                codigo: null,
                equipamento: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        addEquipamentoExterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes){
                available_classes = this.classes;
                classeNeeded = true;
            }

            this.fields.equipamentosExternos.push({
                id: dayjs().unix() + '-new',
                codigo: null,
                equipamento: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        deleteEquipamentoInterno(id){
            let pos = -1    
            let i = 0
            this.fields.equipamentosInternos.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.equipamentosInternos.splice(pos, 1)
        },
        deleteEquipamentoExterno(id){
            let pos = -1    
            let i = 0
            this.fields.equipamentosExternos.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.equipamentosExternos.splice(pos, 1)
        }
    },
    computed:{        
        computeEquipamentosInternos(){
            let returner = []

            this.fields.equipamentosInternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.frentes_obra.length > 0){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.template_classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        },
        computeEquipamentosExternos(){
            let returner = []

            this.fields.equipamentosExternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.frentes_obra.length > 0){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.template_classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        }
    }
}
</script>