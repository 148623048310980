<template>
    <section class="mt-16">
        <v-row>
            <v-col cols="12" md="10">    
            <v-breadcrumbs
                large
                :items="crumbs"
                divider="/"
                class="pl-0"
            ></v-breadcrumbs>
        
            <h1 class="primary--text">
                <v-icon color="primary">mdi-apps-box</v-icon> <span>Preventivas</span>
            </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <v-col cols="12" md="8">
                <v-autocomplete
                    dense outlined
                    v-model="equipment"
                    :item-text="item => item.EntityKey + ' - ' + item.Descricao"
                    :item-value="item => item"
                    label="Equipamento"
                    :items="equipments"
                />
            </v-col>
            <v-col cols="12" v-if="equipment != null">                
              <Preventiva :equipment="equipment"></Preventiva>
            </v-col>
        </v-row>
    </section>
</template>
<script>
import Equipamentos from '@/api/Equipamentos.js'
import Preventiva from '@/components/Preventivas/Preventiva'

export default{
    components:{
        Preventiva
    },
    data(){
        return{
            equipments: [],
            loading: true,
            equipment: null
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            Equipamentos.all().then((resp)  =>  {
                this.equipments = resp.data
                this.loading = false
            })
        }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Preventivas',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
}
</script>