<template>
    <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{errors}">
        <v-text-field
            v-bind="$attrs"
            v-on="$listeners"
            dense
            color="primary"
            :error-messages="errors"
            @blur="setDefault"
        />
    </ValidationProvider>
</template>
<script>
export default {
    props: {
        rules: String,
        vid: String,
        default: String,  
    },
    mounted() {
    },
    methods: {
        setDefault: function() {
            if(this.$attrs.value != undefined &&
                this.$attrs.value != null &&
                this.$attrs.value != ""){
                    return;
            }

            if(this.default == undefined || this.default == null) {
                return;
            }

            this.$emit("input", this.default);
        }
    }
}
</script>
<style scoped>

</style>