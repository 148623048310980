import API from "./API";

export default class SubEmpreitada extends API
{
    static async all(id) {
        return this.get("api/admin/sub-empreitadas/list?id=" + id);
    }

    static async getSubempreitadasByFrente(SubEmpId){
        return this.get("api/admin/sub-empreitadas/by-frente/" + SubEmpId);
    }

    static async createSubEmpreitadaUnidade(fields) {
        return this.post("api/admin/sub-empreitadas/unidades", fields);
    }
    static async getListSubEmpreitadaUnidades() {
        return this.get("api/admin/sub-empreitadas/unidades");
    }
    static async deleteSubEmpreitadaUnidades(id) {
        return this.delete("api/admin/sub-empreitadas/unidades/" + id);
    }
}