<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <MenuTabs :headers="headers">
                    <template v-slot:general>
                    <v-card
                        tile
                    >
                        <div class="pa-10">
                          <Form
                              ref="profile"
                              submitText="Gravar"
                              @submited="updateProfile"
                              :loading="loading"
                          >
                              <Input
                              v-model="profileName"
                              type="name"
                              label="Nome"
                              rules="required"
                              vid="name"
                              dense outlined
                              />

                              <Input
                              v-model="profileEmail"
                              type="email"
                              label="Email"
                              rules="required|email"
                              vid="email"
                              dense outlined
                              />
                          </Form>
                          <v-row class="mt-8" justify="end">
                            <v-col cols="12" md="auto">
                              <v-btn
                                :disabled="fields.profile.microsoft_identifier != null"
                                color="#6E7A90"
                                @click="microsoftLogin"
                              >
                                <div v-if="!$msal.isAuthenticated()">
                                  <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
                                </div>
                                <div v-else>
                                  <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
                                </div>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="auto" v-if="fields.profile.microsoft_identifier != null">
                              <v-btn                                
                                @click="removeMicrosoft"
                                color="error"
                              >
                                Remover
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="auto" v-if="$msal.isAuthenticated() && fields.profile.microsoft_identifier == null">
                              <v-btn
                                @click="$msal.signOut()"                                
                              >
                                <v-icon>mdi-logout</v-icon>
                                Logout
                              </v-btn>
                            </v-col>
                            
                            
                            
                          </v-row>
                        </div>
                    </v-card>
                    </template>
                    <template v-slot:security>
                    <v-row>
                        <v-col cols="12" xl="6">
                        <v-card
                            tile
                            class="pa-10"
                        >
                            <Form
                            ref="security_pass"
                            submitText="Gravar"
                            @submited="updateSecurity"
                            :loading="loading"
                            >
                            <v-row>
                                <v-col cols="12">
                                <Input
                                    v-model="fields.security.current_password"
                                    type="password"
                                    label="Password Atual"
                                    rules="min:8"
                                    vid="current_password"
                                    dense outlined
                                />
                                </v-col>
                                <v-col cols="12" md="6">
                                <Input
                                    v-model="fields.security.password"
                                    type="password"
                                    label="Password"
                                    rules="min:8|confirmed:password_confirmation|required_with:@current_password"
                                    vid="password"
                                    dense outlined
                                />
                                </v-col>
                                <v-col cols="12" md="6">
                                <Input
                                    v-model="fields.security.password_confirmation"
                                    type="password"
                                    label="Confirmação Password"
                                    vid="password_confirmation"
                                    dense outlined
                                />
                                </v-col>
                            </v-row>
                            </Form>
                        </v-card>
                        </v-col>
                        <v-col cols="12" xl="6">
                        <v-card
                            tile
                            class="pa-10"
                        >
                            <Form
                              ref="security_mfa"
                              submitText="Gravar"
                              @submited="updateSecurity"
                              :loading="loading"
                            >
                            <div v-html="qrcode"></div>

                            <div class="otp">
                                <OTP
                                    rules="required|min:6"
                                    class="pt-4"
                                    label="Code"
                                    v-model="fields.security.mfa.code"
                                    v-if="qrcode"
                                    vid="mfa.code"
                                />
                            </div>
                            <UISwitch
                                v-model="mfaActive"
                                @change="renderMFA"
                                label="MFA"
                            />
                            </Form>
                        </v-card>
                        </v-col>
                        <v-col cols="12" md="6" v-if="$store.getters.hasRole(['super'])">
                          <v-card>
                            <v-card-title class="primary white--text">
                              Configurações Software
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-switch
                                    v-model="fields.system_config.maintenance"
                                    label="Ativar modo de manutenção"
                                    color="error"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-btn @click="saveSystemConfig" class="primary">Gravar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                    </v-row>
                    </template>
                    
                    <template v-slot:alerts>
                    <v-card
                        tile
                    >
                        <div class="pa-10">
                          <Form
                              ref="alerts"
                              submitText="Gravar"
                              @submited="updateProfile"
                              :loading="loading"
                          >
                          <v-row>
                            <UISwitch
                                v-model="fields.alerts.receive_approved"
                                label="Receber alertas aprovações"
                            />
                          </v-row>
                          <v-row
                              class="mt-8">
                              <Input
                              v-model="fields.alerts.days_between_last_creation"
                              type="days"
                              label="Dias entre criações"
                              dense outlined
                              />
                            </v-row>
                            <v-row>

                              <UISwitch
                                v-model="fields.alerts.disable_all_notifications"
                                label="Desativar notificações"
                            />
                          </v-row>  
                          </Form>
                        </div>
                    </v-card>
                    </template>
                    <template v-slot:testing v-if="this.$store.getters.hasPermission(['super'])">
                    <v-card
                        tile
                    >
                        <div class="pa-10">
                          <p class="title">Testar emails</p>
                          <p class="subtitle-1 mt-n6"><small>Escolha o tipo de email que quer testar, e receba-o no seu email</small></p>
                          <v-row>
                              <v-col cols="12" md="5">
                                <v-btn depressed color="primary" @click="sendEmail(1)">
                                  Verificar email
                                </v-btn>
                              </v-col>
                              <v-col>
                                <small>Envia o template da verificação de email. O funcionamento do botão não será o correto, por ser apenas um teste</small>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12" md="5">
                                <v-btn depressed color="primary" @click="sendEmail(2)">
                                  Recuperar password
                                </v-btn>
                              </v-col>
                            <v-col>
                              <small>Envia o template da recuperação de password. O funcionamento do botão não será o correto, por ser apenas um teste</small>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="5">
                                <v-btn depressed color="primary" @click="sendEmail(3)">
                                  Diárias por aprovar
                                </v-btn>
                              </v-col>
                            <v-col>
                              <small>Envia um template de partes diárias por aprovar para o utilizador</small>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="5">
                                <v-btn depressed color="primary" @click="sendEmail(4)">
                                  Diárias por elaborar
                                </v-btn>
                              </v-col>
                            <v-col>
                              <small>Envia um template de partes diárias que estão por elaborar</small>
                            </v-col>
                          </v-row>
                        </div>
                    </v-card>
                    </template>
                </MenuTabs>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>

        <DialogConfirmation 
          v-if="confirmMicrosoft" 
          title="Informação"  
          type="warning" 
          :opened="confirmMicrosoft" 
          @on-submit="goToMicrosoft" 
          @on-cancel="confirmMicrosoft = false">
          Iremos atualizar a página para carregar as informações necessárias para o seu registo. Pretende continuar?
        </DialogConfirmation>


    </v-container>
</template>

<script>
  import MenuTabs from "@/components/UI/Tabs/MenuTabs";

  import Form from "@/components/UI/Forms/Form";

  import Input from "@/components/UI/Inputs/Input";

  import UISwitch from "@/components/UI/Inputs/UISwitch";

  import OTP from "@/components/UI/Inputs/OTP";

  import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

  import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
  
  import User from '@/api/User.js'

  import { msalMixin } from 'vue-msal'; 
  import DialogConfirmation from '@/components/UI/DialogConfirmation.vue';

  export default {
    name: 'Profile',
    mixins: [msalMixin],
    components: {
      MenuTabs,
      DialogConfirmation,
      Form,
      Input,
      UISwitch,
      OTP,
      SuccessSnackbar,
      ErrorSnackbar,
    },
    mounted() {
      this.fields.profile.name = this.$store.state.user.data.name;

      this.fields.profile.email = this.$store.state.user.data.email;

      this.fields.security.mfa.enabled = this.$store.state.user.data.options.mfa_enabled;

      this.fields.profile.microsoft_identifier = this.$store.state.user.data.microsoft_identifier


      if(this.$store.state.user.data.configurations != null){
        this.fields.alerts.receive_approved = parseInt(this.$store.state.user.data.configurations.receive_approved)
        this.fields.alerts.days_between_last_creation = this.$store.state.user.data.configurations.days_between_last_creation
        this.fields.alerts.disable_all_notifications = parseInt(this.$store.state.user.data.configurations.disable_all_notifications)
      }

      let maintenance = this.$store.state.user.data.maintenance

      if(maintenance == null){
        this.fields.system_config.maintenance = false
      }else{
        this.fields.system_config.maintenance = maintenance.value == "0" ? false : true
      }

      if(this.$route.name == 'Perfil_Microsoft'){
        if(this.fields.profile.microsoft_identifier == null)
          this.microsoftLogin()
      }
    },
    computed: {
      profileName: {
        get() {
          return this.fields.profile.name == null ? this.$store.state.user.data.name
            : this.fields.profile.name;
        },
        set(val) {
          this.fields.profile.name = val;
        },
      },
      profileEmail: {
        get() {
          return this.fields.profile.email == null ? this.$store.state.user.data.email
            : this.fields.profile.email;
        },
        set(val) {
          
          this.fields.profile.email = val;
        },
      },
      mfaActive: {
        get() {
          return this.fields.security.mfa.enabled == null ? this.$store.state.user.data.options.mfa_enabled
            : this.fields.security.mfa.enabled;
        },
        set(val) {
          this.fields.security.mfa.enabled = val;
        },
      }
    },
    data: () => ({
      loadingAudits: true,
      auditsDisabled: false,
      headers: [
        {
          id: "general",
          name: "Geral",
        },
        {
          id: "security",
          name: "Segurança",
        },
        {
          id: "alerts",
          name: "Alertas"
        },
        {
          id: "testing",
          name: "Testar Emails"
        }
      ],
      confirmMicrosoft: false,
      fields: {
        profile: {
          name: null,
          email: null,
          microsoft_identifier: null,
        },
        security: {
          mfa: {
            enabled: null,
            code: null,
          },
          current_password: null,
          password: null,
          password_confirmation: null,
        },
        system_config:{
          maintenance: false
        },
        alerts:{
          receive_approved: true,
          days_between_last_creation: 2,
          disable_all_notifications: false
        }
      },
      qrcode: null,
      success: null,
      loading: false,
      error: null,
      error_message: "",
    }),
    methods: {
      goToMicrosoft(){        
        window.location.href = process.env.VUE_APP_API_URL + '/perfil/microsoft';
      },
      removeMicrosoft(){
        User.removeMicrosoft(this.$store.state.user.data.id).then(()  =>  {
          window.location.href = process.env.VUE_APP_API_URL + '/perfil#general';
        })
      },
      microsoftLogin(){
        if(this.$route.name == 'Perfil'){
          this.confirmMicrosoft = true
          return
        }

        if(this.$msal.isAuthenticated()){
          if(this.$msal.data.user.userName == this.$store.state.user.data.email){
            User.registerExternalLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid, 'microsoft', this.$store.state.user.data.id)
              .then(() => {

                
                window.location.reload()
              }).catch(err => {

                if(err.response.status == 422) {

                  this.$refs.login.setErrors(err.response.data.errors);
                  
                  return;
                }
            });
          }
        }else this.$msal.signIn()
      },
      sendEmail(n){
        let path = "sendEmailVerify";
        if(n == 1){
          path = "sendEmailVerify";
        }
        if(n == 2){
          path = "sendEmailPassword";
        }
        if(n == 3){
          path = "sendPDDiary";
        }
        if(n == 4){
          path = "sendPDMissing";
        }

        this.$store.dispatch(path, '')
          .then(() => {
            this.success = true;

            this.error = false;

            this.loading = false;
          }).catch(err => {
            
            this.success = false;

            this.error = true;

            this.error_message = err.response.data.message;

            this.loading = false;
          });
      },
      updateProfile: function(){

        let data = {
          name: this.profileName,
          email: this.profileEmail,
          alerts: this.fields.alerts
        }

        this.loading = true;

        this.$store.dispatch("updateProfileGeneral", data)
          .then(() => {
            this.success = true;

            this.error = false;

            this.loading = false;
          }).catch(err => {

            if(err.response.status == 422){

              this.$refs.profile.$refs.form.setErrors(err.response.data.errors);

            }
            
            this.success = false;

            this.error = true;

            this.error_message = err.response.data.message;

            this.loading = false;
          });
      },
      saveSystemConfig(){
        this.loading = true;
        User.updateSystemConfig(this.fields).then(()  =>  {
          this.loading = false

          window.location.reload()
        })
      },
      updateSecurity: function(){

        this.loading = true;

        let data = {
          current_password: this.fields.security.current_password,
          password: this.fields.security.password,
          password_confirmation: this.fields.security.password_confirmation,
          mfa: {
            enabled: this.fields.security.mfa.enabled == true,
            code: this.fields.security.mfa.code
          }
        }

        this.$store.dispatch("updateProfileSecurity", data)
          .then(() => {
            this.fields.security.current_password = null;

            this.fields.security.password = null;

            this.fields.security.password_confirmation = null;

            this.fields.security.mfa.code = null;

            this.qrcode = null;

            this.success = true;

            this.error = false;

            this.loading = false;
          }).catch(err => {

            if(err.response.status == 422){
             
              this.$refs.security_pass.$refs.form.setErrors(err.response.data.errors);

              this.$refs.security_mfa.$refs.form.setErrors(err.response.data.errors);
            }

            this.success = false;

            this.error = true;

            this.error_message = err.response.data.message;

            this.loading = false;
          });
      },
      renderMFA: function(val) {

        this.fields.security.mfa.enabled = val == true;

        if(this.$store.state.user.data.options.mfa_enabled){
          return;
        }

        if(!this.fields.security.mfa.enabled){

          this.qrcode = null;

          return;
        }

        this.$store.dispatch("getMFA")
            .then(res => {

              this.qrcode = res.data.svg;

            }).catch(err => {
              this.success = false;

              this.error = true;

              this.error_message = err.response.data.message;
          });
      },
      
    }
  }
</script>
<style scoped>
.otp {
  max-width: 300px;
  width: 100%;
}

.toggle{
  background-color: transparent;
}
</style>