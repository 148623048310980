<template>
    <div @click="propActive" v-bind="$attrs" v-on="$listeners">
        <div class="d-inline">
            <v-icon color="green darken-1" x-large v-if="this.$attrs.value">mdi-toggle-switch</v-icon>
            <v-icon color="red darken-1" x-large v-else>mdi-toggle-switch-off</v-icon>
        </div>
        <p class="text-body-1 d-inline ml-2">{{ label }}</p>
    </div>
    
</template>

<script>
export default {
    name: "UISwitch",
    props: {
        label: String
    },
    methods: {
        propActive: function() {
            this.$emit("input", !this.$attrs.value);

            this.$emit("change", !this.$attrs.value);
        }
    }
}
</script>

<style scoped>
</style>