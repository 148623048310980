import { render, staticRenderFns } from "./CreateODCMachine.vue?vue&type=template&id=ffcdb068&scoped=true"
import script from "./CreateODCMachine.vue?vue&type=script&lang=js"
export * from "./CreateODCMachine.vue?vue&type=script&lang=js"
import style0 from "./CreateODCMachine.vue?vue&type=style&index=0&id=ffcdb068&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffcdb068",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBreadcrumbs,VCol,VCombobox,VContainer,VDivider,VRow})
