<template>
  <v-btn 
        v-bind="$attrs"
        v-on="$listeners"
        icon>
    <Tooltip
      color="primary"
      text="Ver"
      top
    >
      <v-icon color="primary">mdi-eye</v-icon>

    </Tooltip>
  </v-btn>
</template>

<script>
  import Tooltip from '@/components/UI/Tooltip.vue';
  export default {
    name: 'uiIconView',
    components: {
      Tooltip
    },
  }
</script>

<style lang="scss" scoped>
</style>