<template>
    <v-card>
        <v-card-title class="text-h5 primary white--text">
        Unidades de Obra
        </v-card-title>

        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Pos.
                        </th>
                        <th class="text-left">
                            Descrição
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item, index) in selectedWork.unidades"
                    :key="item.Id"
                    >
                        <td>{{ index+1 }}</td>
                        <td>{{ item.Descricao }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
        <v-spacer></v-spacer>
        
        </v-card-actions>
    </v-card>
</template>
<script>
export default{
    name:"Unidades",
    props:{
        selectedWork: Object
    },
    mounted(){
        
    }
}
</script>
    