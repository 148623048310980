<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">    
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-apps-box</v-icon> <span>Listagem de equipas</span>
          </h1>
          
          <div style="position:relative">
            <v-container>
                <v-speed-dial
                    absolute
                    v-model="speedDial"
                    top
                    right
                    direction="bottom"
                    :open-on-hover="true"
                >
                    <template v-slot:activator>
                        <v-tooltip right color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs" v-on="on"
                                v-model="speedDial"
                                color="primary"
                                dark
                                x-large
                                fab
                                >
                                <v-icon v-if="speedDial">
                                    mdi-close
                                </v-icon>
                                <v-icon v-else>
                                    mdi-dots-vertical
                                </v-icon>
                                </v-btn>
                            </template>
                            <span>Opções</span>
                        </v-tooltip>
                    </template>
                    <v-tooltip right color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            fab
                            dark
                            small
                            color="success"
                            large
                            @click="$router.push('equipas/create')"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        </template>
                        <span>Adicionar</span>
                    </v-tooltip>
                </v-speed-dial>
            </v-container>
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16"
              locale="pt-pt"
            >

            <template v-slot:item.actions="{item}">
              <v-icon
                  small
                  color="primary"
                  class="mr-2"
                  @click="$router.push('equipas/' + item.id)"
              >
                  mdi-eye
              </v-icon>

              <v-icon
                  small
                  color="error"
                  class="mr-2"
                  @click="deleteTeam(item)"
              >
                  mdi-delete
              </v-icon>
            </template>
              
            </v-data-table>
          </div>
        </v-col>
      </v-row>

      <DialogConfirmation 
            v-if="toDelete.show"
            title="Alterar nível de aprovação" 
            type="warning" 
            :opened="toDelete.show" 
            :elevation="0"
            @on-submit="deleteConfirmed" 
            @on-cancel="toDelete.show = false;">
                Tem a certeza que deseja eliminar esta equipa?
        </DialogConfirmation> 
    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import Teams from "@/api/Teams.js";
  import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';
  
  export default {
    components: {
      DialogConfirmation
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchTeams()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "ODC - Listagem de equipas";
    },
    data: () => ({      
      headers: [
        {
          text: 'Equipa',
          sortable: true,
          value: 'name',
        },
        {
            text: "Opções",
            align: "center",
            value: "actions"
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      toDelete:{
        show: false,
        object: null
      },
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      },
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "teams.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
    },
    methods:{      
      deleteConfirmed(){
        let parent = this
        Teams.deleteTeam(this.toDelete.object.id).then(() =>  {
          parent.toDelete.show = false
          parent.searchTeams()
        })
      },
      fillBaseData() {
  
        if(localStorage["teams-list-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["teams-list-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchTeams(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["teams-list-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Teams.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetTeams()
      {
        this.filter = {
        };
  
        this.searchTeams();
      },
      deleteTeam(item){
        this.toDelete.object = item
        this.toDelete.show = true
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Listagem de equipas',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  