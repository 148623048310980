import API from "./API";

export default class Exportation extends API
{
    static async export_file(params) {
        return this.post("api/admin/export", params);
    }

    static async deleteExportation(id) {
        return this.delete("api/admin/export/file/" + id)
    }
}