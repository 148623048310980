import API from "./API";

export default class GarantiasTarefas extends API
{
    static async all() {
        return this.get("api/admin/garantias-tarefas/list");
    }

    static async search(filter) {
        return this.get("api/admin/garantias-tarefas",{params: filter});
    }

    static async create(fields) {
        return this.post("api/admin/garantias-tarefas", fields);
    }

    static async update(fields, id) {
        return this.put("api/admin/garantias-tarefas/" + id, fields);
    }

    static async deleteTask(id) {
        return this.delete("api/admin/garantias-tarefas/" + id);
    }

    static async find(id) {
        return this.get("api/admin/garantias-tarefas/" + id);
    }
}