<template>
    <v-card style="height: 100% !important;">
        <v-card-title class="primary white--text">
            Pré-visualizar diária
            <v-progress-linear
                v-if="loading"
                color="warning"
                indeterminate
            ></v-progress-linear>
        </v-card-title>
        <v-card-text v-if="!loading">
            <section v-if="fields.diaria.creation_role == 'manobrador'">
                <v-row class="mt-4 text-body-1 font-weight-bold black--text" align="center" justify="center">
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{fields.diaria.data}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{fields.obra.Codigo}} - {{fields.obra.Descricao}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center" v-if="fields.diaria.parte_diaria.frente != null">
                        <span>{{fields.diaria.parte_diaria.frente.Descricao}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center" v-if="fields.diaria.parte_diaria.classe != null">
                        <span>{{fields.diaria.parte_diaria.classe.Descricao}}</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <v-row class="mt-4" no-gutters>
                    <v-col cols="12" align="start">
                        <span class="mr-4">Máquina - <v-icon color="success">mdi-bulldozer</v-icon></span>
                        <span>Acessório - <v-icon color="warning">mdi-tag</v-icon></span>
                    </v-col>
                    <v-col cols="12">
                        <!-- DESKTOP -->
                        <v-simple-table class="d-none d-lg-block">
                            <template v-slot:default>
                                <thead class="primary">
                                    <tr>
                                        <th>
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Equipamento
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HS
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HO
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HA
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            KMi
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            KM
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Hi
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            H
                                        </th>
                                        <th  v-if="false" class="text-center white--text text-caption font-weight-bold">
                                            Nota
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Utilizador
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HN
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            HExt
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Diesel
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Óleo
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Adblue
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in fields.diaria.parte_diaria.linhas_eq" v-bind:key="item.id">
                                        <td>
                                            <v-icon color="warning" v-if="isAccessory(item)">mdi-tag</v-icon>
                                            <v-icon color="success" v-else>mdi-bulldozer</v-icon>
                                        </td>
                                        <td class="text-center">  
                                            <span class="text-caption font-weight-bold">{{getVehicleName(item)}}</span>                                                
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.Servico)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.Ordem)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.Avariado)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.KmInicial)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.Contador2)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.HoraInicial)}}
                                        </td>
                                        <td class="text-center">
                                            {{getNumber(item.Contador1)}}
                                        </td>
                                        <td  v-if="false" class="text-center">
                                            {{item.Obs}}
                                        </td>
                                        <td class="text-center">
                                            <Tooltip
                                                color="warning"
                                                :text="fields.diaria.creator_name"
                                                top
                                            >
                                                {{fields.diaria.creator}}
                                            </Tooltip>
                                        </td>
                                        <td class="text-center" v-if="!isAccessory(item)">
                                            {{getNumber(item.HNormal)}}
                                        </td>
                                        <td class="text-center" v-if="!isAccessory(item)">
                                            {{getNumber(item.HExtra)}}
                                        </td>
                                        <td class="text-center" v-if="!isAccessory(item)">
                                            {{getNumber(item.Combustivel)}}
                                        </td>
                                        <td class="text-center" v-if="!isAccessory(item)">
                                            {{getNumber(item.oil)}}
                                        </td>
                                        <td class="text-center" v-if="!isAccessory(item)">
                                            {{getNumber(item.adblue)}}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!-- DESKTOP -->
                        <!-- MOBILE -->
                        <v-row>
                            <v-col cols="12">
                                <v-data-iterator
                                    :items="fields.diaria.parte_diaria.linhas_eq"
                                    item-key="id"
                                    :items-per-page="-1"
                                    hide-default-footer
                                    class="d-block d-lg-none ma-0 pa-0"
                                >
                                <template v-slot:default="{ items }">
                                    <v-row class="ma-0 pa-0">
                                        <v-col
                                            class="ma-0 pa-0"
                                            v-for="item in items"
                                            :key="item.id"
                                            cols="12"
                                        >
                                            <v-card elevation="0" class="mt-2 ma-0 pa-0" outlined>
                                                <v-list
                                                    dense
                                                >
                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-icon color="warning" v-if="isAccessory(item)">mdi-tag</v-icon>
                                                            <v-icon color="success" v-else>mdi-bulldozer</v-icon>
                                                        </v-list-item-content>                                                                            
                                                    </v-list-item>
                                                    
                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="HO"
                                                                :value="getNumber(item.Ordem)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    
                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-textarea
                                                                class="mt-4"
                                                                :label="isAccessory(item) ? 'Acessório' : 'Máquina'"
                                                                :value="getVehicleName(item)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="HS"
                                                                :value="getNumber(item.Servico)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="HA"
                                                                :value="getNumber(item.Avariado)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="KMi"
                                                                :value="getNumber(item.KmInicial)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="KM"
                                                                :value="getNumber(item.Contador2)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="H"
                                                                :value="getNumber(item.Contador1)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="Utilizador"
                                                                :value="fields.diaria.creator + ' - ' + fields.diaria.creator_name"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="HN"
                                                                :value="getNumber(item.HNormal)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="HExt"
                                                                :value="getNumber(item.HExtra)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="Diesel"
                                                                :value="getNumber(item.diesel)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item>
                                                        <v-list-item-content class="align-center">
                                                            <v-text-field
                                                                class="mt-4"
                                                                label="Óleo"
                                                                :value="getNumber(item.oil)"
                                                                outlined dense
                                                            />
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    
                                                </v-list>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                                </v-data-iterator>
                            </v-col>
                        </v-row>
                        <!-- MOBILE -->
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="primary white--text">
                        Trabalhos efetuados / Observações
                    </v-col>
                    <v-col cols="12" v-if="fields.diaria.parte_diaria != null">
                        <v-textarea
                            :value="fields.diaria.parte_diaria.observations != null ? fields.diaria.parte_diaria.observations.obs_equipments : ''"
                            outlined
                        />
                    </v-col>
                </v-row>
            </section>
            <section v-if="fields.diaria.creation_role == 'encarregado'">
                <v-row class="mt-4 text-body-1 font-weight-bold black--text" align="center" justify="center">
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{fields.diaria.data}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{fields.obra.Codigo}} - {{fields.obra.Descricao}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs
                            v-model="tab"
                            dark
                            fixed-tabs
                            background-color="primary"
                            icons-and-text                            
                        >
                            <v-tab :key="0" :disabled="$store.getters.hasRole(['parque'])">
                                Mão Obra
                                <v-icon>mdi-account-hard-hat</v-icon>
                            </v-tab>
                            <v-tab :key="1">
                                <v-progress-circular
                                    v-if="heavyLoading"
                                    :size="50"
                                    indeterminate
                                    color="warning"
                                ></v-progress-circular>
                                Equipamentos
                                <v-icon>mdi-bulldozer</v-icon>
                            </v-tab>
                            <v-tab :key="2" :disabled="$store.getters.hasRole(['parque'])">
                                <v-progress-circular
                                    v-if="heavyLoading"
                                    :size="50"
                                    indeterminate
                                    color="warning"
                                ></v-progress-circular>
                                Material
                                <v-icon>mdi-archive</v-icon>
                            </v-tab>
                            <v-tab :key="3" :disabled="$store.getters.hasRole(['parque'])">
                                <v-progress-circular
                                    v-if="heavyLoading"
                                    :size="50"
                                    indeterminate
                                    color="warning"
                                ></v-progress-circular>
                                Subempreitadas
                                <v-icon>mdi-briefcase</v-icon>
                            </v-tab>
                            <v-tab :key="4">
                                <v-progress-circular
                                    v-if="heavyLoading"
                                    :size="50"
                                    indeterminate
                                    color="warning"
                                ></v-progress-circular>
                                Observações
                                <v-badge
                                    v-if="checkBadge"
                                    color="error"
                                    dot
                                    overlap
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-badge>
                                <v-icon v-else>mdi-eye</v-icon>
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <!-- Mão Obra Interna -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Mão Obra Interna
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Nome
                                                                </th>
                                                                <th class="text-center">
                                                                    Frente Obra
                                                                </th>
                                                                <th class="text-center">
                                                                    Classe
                                                                </th>
                                                                <th class="text-center">
                                                                    HN
                                                                </th>
                                                                <th class="text-center">
                                                                    HExt
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="fields.diaria.parte_diaria.pdencMO.interno != null">
                                                            <tr v-for="item in fields.diaria.parte_diaria.pdencMO.interno.linhas">
                                                                <td>
                                                                    <span class="text-caption font-weight-bold">{{item.funcionario}} - {{item.Nome}}</span>       
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.frente_obra == null ? '-' : item.frente_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.classe_obra == null ? '-' : item.classe_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hn)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hext)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.local}}
                                                                </td>                                                               
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- Mão Obra Interna -->

                                <!-- Mão Obra Externa -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Mão Obra Externa
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Nome
                                                                </th>
                                                                <th class="text-center">
                                                                    Frente Obra
                                                                </th>
                                                                <th class="text-center">
                                                                    Classe
                                                                </th>
                                                                <th class="text-center">
                                                                    HN
                                                                </th>
                                                                <th class="text-center">
                                                                    HExt
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="fields.diaria.parte_diaria.pdencMO.externo != null">
                                                            <tr v-for="item in fields.diaria.parte_diaria.pdencMO.externo.linhas">
                                                                <td>
                                                                    <span class="text-caption font-weight-bold">{{item.funcionario}} - {{item.Nome}}</span>       
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.frente_obra == null ? '-' : item.frente_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.classe_obra == null ? '-' : item.classe_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hn)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hext)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.local}}
                                                                </td>                                                               
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- Mão Obra Externa -->
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Equipamento Interno
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Equipamento
                                                                </th>
                                                                <th class="text-center">
                                                                    Frente Obra
                                                                </th>
                                                                <th class="text-center">
                                                                    Classe
                                                                </th>
                                                                <th class="text-center">
                                                                    HS / dia
                                                                </th>
                                                                <th class="text-center">
                                                                    HO
                                                                </th>
                                                                <th class="text-center">
                                                                    HA
                                                                </th>
                                                                <th class="text-center">
                                                                    HP
                                                                </th>
                                                                <th class="text-center">
                                                                    Horas
                                                                </th>
                                                                <th class="text-center">
                                                                    KM
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="fields.diaria.parte_diaria.pdenc.interno != null">
                                                            <tr v-for="item in fields.diaria.parte_diaria.pdenc.interno.linhas">
                                                                <td class="text-left">
                                                                    <span class="text-caption font-weight-bold">{{item.EquipCode}} - {{item.EquipDescricao}}</span>
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.frente_obra == null ? '-' : item.frente_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.classe_obra == null ? '-' : item.classe_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hs)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.ho)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.ha)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.hp)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.horas)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.km)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{item.local}}
                                                                </td>                                                    
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- EQUIPAMENTO INTERNO -->

                                <!-- EQUIPAMENTO EXTERNO -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Equipamento Externo
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Equipamento
                                                                </th>
                                                                <th class="text-center">
                                                                    Frente Obra
                                                                </th>
                                                                <th class="text-center">
                                                                    Classe
                                                                </th>
                                                                <th class="text-center">
                                                                    HS / dia
                                                                </th>
                                                                <th class="text-center">
                                                                    HO
                                                                </th>
                                                                <th class="text-center">
                                                                    HA
                                                                </th>
                                                                <th class="text-center">
                                                                    HP
                                                                </th>
                                                                <th class="text-center">
                                                                    Horas
                                                                </th>
                                                                <th class="text-center">
                                                                    KM
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="fields.diaria.parte_diaria.pdenc.externo != null">
                                                            <tr v-for="item in fields.diaria.parte_diaria.pdenc.externo.linhas">
                                                                <td class="text-left">
                                                                    <span class="text-caption font-weight-bold">{{item.EquipDescricao}}</span>
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.frente_obra == null ? '-' : item.frente_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.classe_obra == null ? '-' : item.classe_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.hs)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{getNumber(item.ho)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.ha)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.hp)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.horas)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{getNumber(item.km)}}
                                                                </td> 
                                                                <td class="text-center">
                                                                    {{item.local}}
                                                                </td>                                                    
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Materiais
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Frente Obra
                                                                </th>
                                                                <th class="text-center">
                                                                    Classe
                                                                </th>
                                                                <th class="text-center">
                                                                    Artigo
                                                                </th>
                                                                <th class="text-center">
                                                                    Un
                                                                </th>
                                                                <th class="text-center">
                                                                    QTD
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in fields.diaria.parte_diaria.material">
                                                                <td class="text-center">
                                                                    {{item.frente_obra}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.classe_obra}}
                                                                </td>
                                                                <td class="text-left">
                                                                    <span class="text-caption font-weight-bold">{{item.material}}</span>
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.unidade}}
                                                                </td>                                                                    
                                                                <td class="text-center">
                                                                    {{getNumber(item.qty)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{item.local}}                   
                                                                </td>                                                            
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="elevation-0">
                                            <v-card-title class="warning white--text">
                                                Subempreitadas
                                            </v-card-title>
                                            <v-card-text>
                                                <v-simple-table fixed-header>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">
                                                                    Trabalho
                                                                </th>
                                                                <th class="text-center">
                                                                    QTD
                                                                </th>
                                                                <th class="text-center">
                                                                    Nota
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in fields.diaria.parte_diaria.subempreitada">
                                                                <td class="text-left">
                                                                    <span class="text-caption font-weight-bold">{{item.Descricao}}</span>
                                                                </td>                                                                  
                                                                <td class="text-center">
                                                                    {{getNumber(item.qty)}}
                                                                </td>                                                                     
                                                                <td class="text-center">
                                                                    {{item.local}}
                                                                </td>                                                         
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row v-if="fields.diaria.parte_diaria.observations != null && !$store.getters.hasRole(['parque'])">
                                    <v-col cols="12">
                                        <v-toolbar
                                            dark
                                            color="warning"
                                            class="rounded-lg"
                                            >
                                            <v-toolbar-title>Observações de Mão de Obra:</v-toolbar-title>
                                        </v-toolbar>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            readonly
                                            :value="fields.diaria.parte_diaria.observations.obs_work_hand"
                                            outlined dense
                                            label="Observações"
                                            hint="Escreva as suas observações relativas à mão de obra"
                                            persistent-hint
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row v-if="fields.diaria.parte_diaria.observations != null">
                                    <v-col cols="12">
                                        <v-toolbar
                                            dark
                                            color="warning"
                                            class="rounded-lg"
                                            >
                                            <v-toolbar-title>Observações de Equipamentos:</v-toolbar-title>
                                        </v-toolbar>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            readonly
                                            :value="fields.diaria.parte_diaria.observations.obs_equipments"
                                            outlined dense
                                            label="Observações"
                                            hint="Escreva as suas observações relativas aos equipamentos"
                                            persistent-hint
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-toolbar
                                            dark
                                            color="warning"
                                            class="rounded-lg"
                                            >
                                            <v-toolbar-title class="d-none d-lg-block">Observações Obra/Trabalhos Efetuados:</v-toolbar-title>
                                            <v-toolbar-title class="d-block d-lg-none text-caption">Observações Obra/Trabalhos Efetuados:</v-toolbar-title>
                                        </v-toolbar>
                                    </v-col>
                                    <v-col cols="12" v-for="(item,index) in fields.diaria.parte_diaria.work_order_obs" :key="index + 85552">
                                        <v-row class="ma-0 pa-0">
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="item.work_order_service"
                                                    outlined dense
                                                    label="Nº Autorização Trabalho/Ordem Serviço"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="item.description"
                                                    outlined dense
                                                    label="Descrição"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>       
                <v-row>
                    <v-col cols="12" md="6" class="d-none d-lg-block" align="start">                                
                        <v-btn :disabled="$store.getters.hasRole(['parque']) ? tab == 1 : tab == 0" @click="backward()" fab class="mt-4 primary">
                            <v-icon small>mdi-less-than</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="d-none d-lg-block" align="end">                           
                        <v-btn :disabled="tab == 4" @click="forward()" fab class="mt-4 primary">
                            <v-icon small>mdi-greater-than</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </section>     
        </v-card-text>

    </v-card>
</template>
<script>
import Diaries from "@/api/Diaries.js"
import Obra from "@/api/Obra.js"
import Equipamentos from "@/api/Equipamentos.js"
import Tooltip from '@/components/UI/Tooltip.vue';

export default{
    name:"PreviewDiary",
    components:{
        Tooltip
    },
    props:{
        diary_id: String
    },
    data(){
        return{
            tab: 0,
            loading: true,
            heavyLoading: true,
            fields:{},
            equipamentos:[],
            unassignedMachines:[]
        }
    },
    mounted(){
        if(this.$store.getters.hasRole(['parque']))
            this.tab = 1
        this.fillBaseData()
    },
    methods:{
        backward(){
            if(this.$store.getters.hasRole(['parque'])){
                this.tab = 1
                return 
            }

            this.tab = this.tab - 1
        },
        forward(){
            if(this.$store.getters.hasRole(['parque'])){
                this.tab = 4
                return 
            }

            this.tab = this.tab + 1
        },
        isAccessory(item){
            let flag = true;

            console.log(item)

            if(item.HNormal != null)
                flag = false;

            if(item.HExtra != null)
                flag = false;

            if(item.oil != null)
                flag = false;

            if(item.Combustivel != null)
                flag = false;

            return flag;
        },
        getNumber(n){
            let num = parseFloat(n)
            num = isNaN(num) ? 0 : num;

            return num
        },
        getVehicleName(item){
            let object = null;

            return item.EntityKey + ' - ' + item.Descricao;
            /*

            this.equipamentos.forEach(element => {
                if(element.Id == item.IdEquipamento){
                    object = element.Descricao
                }
            });

            if(object == null){
                this.unassignedMachines.forEach(element => {
                    if(element.Id == item.IdEquipamento)
                        object = element.Descricao
                });
            }*/
            //return object
        },
        async fillBaseData(){
            let data = {
                type: 'light'
            }
            await Diaries.preview(this.diary_id, data).then((data)  =>  {
                this.fields = data.data
                this.loading = false
            })

            data = {
                work_id: this.fields.obra.Id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'manobrador'
            }
            Obra.getObraEquipamentos(data).then((resp)  =>  {
                // Manobrador acede apenas a equipamentos externos
                this.equipamentos = resp.data.externo
            })
            Equipamentos.getUnassigned(this.fields.obra.Id).then((resp)    =>  {
                this.unassignedMachines = resp.data
            })

            this.heavyLoad()
        },
        heavyLoad(){
            let data = {
                type: 'heavyLoad'
            }
            Diaries.preview(this.diary_id, data).then((data)  =>  {
                this.fields = data.data
                this.heavyLoading = false
            })
        }
    },
    computed:{        
        checkBadge(){
            if(this.fields.diaria.parte_diaria.observations == null)
                return false;
                
            return this.fields.diaria.parte_diaria.observations.obs_work_hand != null || this.fields.diaria.parte_diaria.observations.obs_equipments
        },
    }
}
</script>