import API from "./API";

export default class WorkSchedule extends API
{
    static async getWorksScheduled(filter){
        return this.get("api/admin/work_Schedule", {params: filter});
    }
    
    static async createWorkSchedule(fields) {
        return this.post("api/admin/work_Schedule", fields);
    }

    static async getWorkSchedule(id) {
        return this.get("api/admin/work_Schedule/" + id);
    }

    static async updateWorkSchedule(fields, id) {
        return this.put("api/admin/work_Schedule/" + id, fields);
    }

    static async deleteWorkSchedule(id){
        return this.delete("api/admin/work_Schedule/" + id);
    }

    
}