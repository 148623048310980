import API from "./API";

export default class Obra extends API
{
    static async all(data) {
        return this.get("api/admin/obras/list", {params:data});
    }

    static async onlyWorks(){
        return this.get("api/admin/obras/onlyWorks");
    }

    // This function uses the unique identifier
    static async getObraFuncionarios(fields) {
        return this.get("api/admin/obras/" + fields.id + "/funcionarios/list", {params: fields});
    }

    static async getObrasByClassFuncionarios(fields){
        return this.get("api/admin/obras/" + fields.id + "/funcionarios/all-by-class/list", {params: fields});
    }

    static async getObraEquipamentos(params) {
        return this.get("api/admin/obras/" + params.work_id + "/equipamentos/list", {params: params});
    }

    static async getManobradoresDeObra(id) {
        return this.get("api/admin/obras/get-manobradores/" + id);
    }

    static async getObraPessoal(id) {
        return this.get("api/admin/obras/" + id + "/pessoal/list");
    }

    static async byEntidade(id) {
        return this.get("api/admin/obras/" + id + "/pessoal/list/by-entidade");
    }

    static async byObra(id){
        return this.get("api/admin/obras/" + id + "/pessoal/list/by-obra");
    }
    
    static async getObraPessoalTemplate(id) {
        return this.get("api/admin/obras/" + id + "/pessoal/list/template");
    }

    static async getObraPessoalById(id) {
        return this.get("api/admin/obras/" + id + "/pessoal/list/ByID");
    }

    static async getObra(id){
        return this.get("api/admin/obras/get-obra/" + id);
    }
    
    static async addWorkUnits(id, units){
        return this.post("api/admin/obras/work-units/" + id, units);
    }
}

