<template>
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <router-view v-if="user != null"/>

                    <v-container fluid v-else>
                        <v-row>
                            <v-col cols="12" offset="0" md="10" offset-md="1">
                                <v-skeleton-loader
                                    width="100%"
                                    type="image"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-main>    
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch("getSession")
            .catch(err => {
                this.$router.push("/login");
            });
    },
    methods:{
        changeData(){
            this.$emit('user-data', this.$store.state.user.data)
        }
    },
    computed: {
        user: function() {
            this.changeData()
            return this.$store.state.user.data;
        }
    }
}
</script>

<style scoped>

</style>