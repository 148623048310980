<template>
    <v-btn
        :color="$attrs.color || 'primary'"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot/>
    </v-btn>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>