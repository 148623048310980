<template>
    <v-stepper v-model="step" style="overflow: visible;">
        <v-stepper-header>
            <template v-for="(header, i) in headers">
                <v-stepper-step
                    v-bind:key="i"
                    :complete="step > (i + 1)"
                    :step="i+1"
                >
                    {{header}}
                </v-stepper-step>

                <v-divider v-bind:key="'div_' + i"/>
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content 
                v-for="(header, i) in headers"
                v-bind:key="i"
                :step="i+1"
            >
                <div class="mt-5">
                    <slot :name="i+1" />

                    <div class="mt-8">
                        <v-row>
                            <v-col class="text-left">
                                <v-btn 
                                    fab
                                    text
                                    @click="step = i"
                                    v-if="step > 1"
                                >
                                    <v-icon>
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn
                                    fab
                                    color="primary"
                                    @click="step = i + 2"
                                    v-if="step < headers.length"
                                    elevation="0"
                                >
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>    
</template>

<script>
export default {
    props: {
        headers: Array
    },
    data: () => ({
        step: 1,
    })
}
</script>

<style scoped>

</style>