<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="primary white--text">
                Mapa de Quantidades de Trabalho
            </v-card-title>
            <v-card-text class="text-center">
                <span class="text-h6 black--text">{{articulado.obra.Codigo}} - {{articulado.obra.Descricao}}</span>
            </v-card-text>
            <v-card-text>
                <v-row class="font-weight-bold">
                    <v-col cols="12" md="1" class="text-start">
                        Código
                    </v-col>
                    <v-col cols="12" md="6" class="text-start">
                        Descrição
                    </v-col>
                    <v-col cols="12" md="1" class="text-start">
                        Quant.
                    </v-col>
                    <v-col cols="12" md="2" class="text-start">
                        Classe
                    </v-col>
                    <v-col cols="12" md="2" class="text-start">
                        Frente
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-for="(item, index) in articulado.articulado" :key="index+952">
                    <v-col cols="12" md="1" class="text-start">
                        {{item.ItemCod}}
                    </v-col>
                    <v-col cols="12" md="6" class="text-start">
                        {{item.ItemDesc}}
                    </v-col>
                    <v-col cols="12" md="1" class="text-start">
                        {{item.Quant}}
                    </v-col>
                    <v-col cols="12" md="2" class="text-start">
                        {{item.classe == null ? '-' : item.classe.Descricao}}
                    </v-col>
                    <v-col cols="12" md="2" class="text-start">
                        {{item.frente == null ? '-' : item.frente.Descricao}}
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    name:"Articulados",
    props:{
        articulado: Object,
        method: String
    },
    data(){
        return{
            loading: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){

            this.loading = false
        }
    }
}
</script>