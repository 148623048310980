import API from "./API";

export default class KPIS extends API
{

    static async search(data) {
        return this.get("api/admin/mapa-quantidade-trabalhos", {params: data});
    }

    static async find(id) {
        return this.get("api/admin/mapa-quantidade-trabalhos/" + id);
    }

    static async getByWork(id) {
        return this.get("api/admin/mapa-quantidade-trabalhos/byWork/" + id);
    }

    static async deleteMQTHeader(id){
        return this.delete("api/admin/mapa-quantidade-trabalhos/header/"+ id);
    }
    
}