import API from "./API";

export default class ODCMachines extends API
{
    static async getMachines() {
        return this.get("api/admin/odc_machines/list");
    }

    static async createMachine(fields) {
        return this.post("api/admin/odc_machines", fields);
    }

    static async getODCMachine(id) {
        return this.get("api/admin/odc_machines/" + id);
    }

    static async updateODCMachine(fields, id) {
        return this.put("api/admin/odc_machines/" + id, fields);
    }

    static async deleteODCMachine(id){
        return this.delete("api/admin/odc_machines/" + id);
    }

    
}