import ExternalCommunications from "@/api/ExternalCommunications";

export const external_communications = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getExternalCommunications(params, data) {
            return new Promise((resolve, reject) => {
                ExternalCommunications.getExternalCommunications(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async hasExternalCommInDate(params, data){
            return new Promise((resolve, reject) => {
                ExternalCommunications.hasExternalCommInDate(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}