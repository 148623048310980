<template>
    <v-menu
        v-model="opened"
        :close-on-content-click="false"
        max-width="290"
    >
        <template v-slot:activator="{on, attrs}">
            <Input 
                :rules="rules"
                :value="$attrs.value"
                :label="label"
                :placeholder="placeholder"
                readonly
                :outlined="outlined"
                dense
                v-bind="attrs"
                v-on="on"
                @click:clear="cleared"
                :clearable="clearable"
                :vid="vid"
            />
        </template>
        <v-date-picker
            v-bind="$attrs"
            v-on="$listeners"
            color="primary"
            :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
            @change="opened = false"
        />
    </v-menu>
</template>
<script>
import Input from "@/components/UI/Inputs/Input"

export default {
    components: {
        Input
    },
    props: {
        label: String,
        placeholder: String,
        clearable: Boolean,
        rules: String,
        vid: String,
        outlined:{
            typeof:Boolean,
            default: false
        }
    },
    data: () => ({
        opened: false
    }),
    methods: {
        cleared() {
            this.$emit("clear", true);
        }
    }
}
</script>
<style scoped>

</style>