<template>
    <v-row>
        <v-col cols="12" md="12">
            <v-card
                tile
            >
            <v-card-title class="primary--text">
                Ver
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="auto" v-for="header in headers" v-bind:key="header.id">
                        <v-btn :prepend-icon="header.icon" :to="'#' + header.id" class="warning">
                            <v-icon class="mr-2">{{header.icon}}</v-icon>{{header.name}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
        <template v-for="header in headers">
            <v-scroll-y-reverse-transition v-bind:key="header.id" leave-absolute hide-on-leave>
                <v-col md="12" cols="12" v-if="$route.hash == '#' + header.id">
                    <slot :name="header.id" />
                </v-col>
            </v-scroll-y-reverse-transition>
        </template>
    </v-row>
</template>
<script>

import Button from "@/components/UI/Buttons/Button";

export default {
    name: 'MenuTabs',
    components: {
        Button
    },
    watch:{
        '$route':{
            immediate: true,
            handler(val){                
                if(val == null)
                    return
                if(val == undefined)
                    return 
                if(val.hash == undefined)
                    return

                let hash = val.hash
                if(hash == ""){
                    this.$router.push("#" + this.headers[0].id);
                }
            }
        }
    },
    props: {
        title: String,
        headers: Array
    },
    mounted() {
        let hash = this.$route.hash;
        
        hash = hash.replace("#", "");

        if(hash == ""){
            this.$router.push("#" + this.headers[0].id);
        }
    }
}
</script>