<template>
    <div>
        <v-simple-table fixed-header class="d-none d-lg-block">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="header in headers" :style="header.width != undefined ? 'width: ' + header.width : ''" v-bind:key="header.text" :class="'text-' + header.alignment">
                            {{ header.text }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="item in items" v-bind:key="getKeyValue(item)">
                        <td v-for="key in getItemKeys(item)" v-bind:key="key" v-if="key != 'accessory'">
                            <slot :name="key" :item="item">
                                {{ item[key] }}
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="items"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
                <v-row>
                    <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                    >
                        <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                            <v-list
                                dense
                            >
                            <v-list-item v-for="(header, i) in headers" v-bind:key="header.text">
                                <v-list-item-content v-if="false">{{ header.text }}</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <template>
                                        <slot :item="item" :name="getItemKeys(item)[i]" v-if="getItemKeys(item)[i] != 'accessory'">
                                            {{ item[getItemKeys(item)[i]] }}
                                        </slot>
                                    </template>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </div>  
</template>

<script>
export default {
    props: {
        headers: Array,
        items: Array,
        itemValue: String,
    },
    watch:{
        items(v){
            if(v == undefined) return

            this.items = v
        }
    },
    mounted() {
        
    },
    methods: {
        getKeyValue(item) {
            return item[this.itemValue];
        },
        getItemKeys(item) {
            return Object.keys(item).filter(key => key != this.itemValue);
        },
    }
}
</script>

<style scoped>

</style>