import Role from "@/api/Role";

export const role = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async listRoles() {
            return new Promise((resolve, reject) => {
                Role.all()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async createRole(params, fields) {
            return new Promise((resolve, reject) => {
                Role.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getRole(params, id) {
            return new Promise((resolve, reject) => {
                Role.getRole(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateRole(params, data) {
            return new Promise((resolve, reject) => {
                Role.updateRole(data.fields, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteRole(params, id) {
            return new Promise((resolve, reject) => {
                Role.deleteRole(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async searchRoles(params, data) {
            return new Promise((resolve, reject) => {
                Role.search(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}