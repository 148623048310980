<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Comunicações Externas</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>
                <v-row>
                    <v-col cols="12">
                        <PaginatedTable
                            :headers="headers"
                            :items="external_comms"
                            :loading="loading"
                            :server-items-length="total"
                            @optionsChanged="searchCommunications"
                            class="mt-4"
                            locale="pt-pt"
                            :dataSearch="false"
                            :canView="false"
                            :canDelete="false"
                            
                        >
                        </PaginatedTable>    
                    </v-col>
                </v-row>
                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"
import PaginatedTable from "@/components/UI/Tables/PaginatedTable";

export default {
    components:{
        PaginatedTable,
        Tooltip
    },
    data: () => ({
        loading: false,
        success: false,
        updateDialog: {
            show: false,
            linha: null
        },
        total: 0,
        filter: {
            name: null,
            email:null
        },
        options: {},
        external_comms: [],
        headers:[
            {
                text: "ID Máq.",
                align: "center",
                value: "machine_id",
            },
            {
                text: "Máquina",
                align: "center",
                value: "machine.name",
            },
            {
                text: "Data",
                align: "center",
                value: "data",
            },
            {
                text: "Horas Inicio",
                align: "center",
                value: "init_hours"
            },
            {
                text: "Horas Fim",
                align: "center",
                value: "final_hours"
            },
            {
                text: "Diesel",
                align: "center",
                value: "fuel"
            },
            {
                text: "ID Worker",
                align: "center",
                value: "id_worker",
            },
            {
                text: "Created at",
                align: "center",
                value: "created_at",
            },
        ],
        tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
        },
    }),
    methods:{
        searchCommunications(options){
            this.options = options;

            this.loading = true;

            let filter = {...this.filter};

            filter.page = options.page;

            filter.limit = options.itemsPerPage;

            this.$store.dispatch("getExternalCommunications", filter)
                .then(response => {

                    let items = response.data;

                    this.total = items.total;

                    this.external_comms = items.data;

                    this.loading = false;

            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Comunicações externas',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>