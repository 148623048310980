<template>
    <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <div class="mb-4">
            <v-otp-input
                v-bind="$attrs"
                v-on="$listeners"
                dense
                color="red darken-1"
                length="6"
                plain
            />
            <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message" v-bind:key="key" v-for="(error, key) in errors">
                            {{error}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
export default {
    name: "OTP",
    props: {
        rules: String,
        vid: String
    }
}
</script>

<style scoped>
</style>