import API from "./API";

export default class Clients extends API
{
    static async all() {
        return this.get("api/admin/clients/list");
    }

    static async search(filter) {
        return this.get("api/admin/clients",{params: filter});
    }
}