import WorkerCard from "@/api/WorkerCard";

export const worker_cards = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getWorkerCards(params, fields) {
            return new Promise((resolve, reject) => {
                WorkerCard.getWorkerCards(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async createWorkerCard(params, fields) {
            return new Promise((resolve, reject) => {
                WorkerCard.createWorkerCard(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getWorkerCard(params, id) {
            return new Promise((resolve, reject) => {
                WorkerCard.getWorkerCard(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateWorkerCard(params, data) {
            return new Promise((resolve, reject) => {
                WorkerCard.updateWorkerCard(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteWorkerCard(params, id){
            return new Promise((resolve, reject) => {
                WorkerCard.deleteWorkerCard(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}