<template>
    <v-footer
        app
        dark
        absolute
        color="#333333"
        inset
    >
        <v-container fluid>
            <v-row>
                <v-col class="white--text darken-1--text">
                    {{ (new Date()).getFullYear() }} - ODC {{getVersion()}}
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    methods:{
        getVersion(){
            return process.env.VUE_APP_SECRET_VERSION
        }
    }
}
</script>
