<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text"><v-icon class="mr-2">mdi-laser-pointer</v-icon>Registo Diário Equipamentos - ERP</h2>
                
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                
                <Form :viewOnly="VIEW_ONLY" ref="pd" :loading="loading" :submitText="'Guardar'">
                    <v-row>
                        <v-col cols="12" md="4">
                            <Input 
                                label="Data"
                                placeholder="Insira Data"
                                v-model="fields.Data"
                                disabled
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <Select
                                v-model="fields.frente_obra"
                                :items="$store.state.user.data.frentes_obra"
                                item-text="Descricao"
                                label="Frentes de Obra"
                                rules="required"
                                vid="frentes_obra"
                                return-object
                                disabled
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <Input 
                                label="Unidade Obra"
                                v-model="fields.unidadeObra"
                                disabled
                            />
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <h3 class="primary--text">Equipamento Interno</h3>
                            <Table v-if="itemsEquipInternal.length > 0" :headers="equipHeaders" :items="itemsEquipInternal" item-value="id">
                                <template v-slot:equipamento="{item}">
                                    <p>{{item.equipamento}}</p>
                                </template>
                                <template v-slot:hs="{item}">
                                    <Input 
                                        label="HS"
                                        placeholder="Insira HS"
                                        v-model="item.hs"
                                    />
                                </template>
                                <template v-slot:ho="{item}">
                                    <Input 
                                        label="HO"
                                        placeholder="Insira HO"
                                        v-model="item.ho"
                                    />
                                </template>
                                <template v-slot:local="{item}">
                                    <Input 
                                        label="Local"
                                        placeholder="Insira Local"
                                        v-model="item.local"
                                    />
                                </template>
                                <template v-slot:options="{item}">                                            
                                    <p class="text-caption">No options available</p>
                                </template>
                            </Table>
                            <div v-else>
                                <p>Nothing to show</p>
                            </div>
                            
                        </v-col>
                    </v-row>

                    <v-divider class="mt-12 mb-12"/>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="primary--text">Equipamento Externo</h3>
                            <Table v-if="itemsEquipExternal.length > 0" :headers="equipHeaders" :items="itemsEquipExternal" item-value="id">
                                <template v-slot:equipamento="{item}">                                            
                                    <p>{{item.equipamento}}</p>
                                </template>
                                <template v-slot:hs="{item}">
                                    <Input 
                                        label="HS"
                                        placeholder="Insira HS"
                                        v-model="item.hs"
                                    />
                                </template>
                                <template v-slot:ho="{item}">
                                    <Input 
                                        label="HO"
                                        placeholder="Insira HO"
                                        v-model="item.ho"
                                    />
                                </template>
                                <template v-slot:local="{item}">
                                    <Input 
                                        label="Local"
                                        placeholder="Insira Local"
                                        v-model="item.local"
                                    />
                                </template>
                                <template v-slot:options="{item}">
                                    <p class="text-caption">No options available</p>
                                </template>
                            </Table>
                            <div v-else>
                                <p>Nothing to show</p>
                            </div>
                        </v-col>
                    </v-row>
                </Form>   
            </v-col>
        </v-row>
    </v-container>    
</template>

<script>
import Input from "@/components/UI/Inputs/Input"
import DateInput from "@/components/UI/Inputs/DateInput"
import SearchUserById from "@/components/UI/Inputs/SearchUserById"
import Table from "@/components/UI/Tables/Table"
import Stepper from "@/components/UI/Steppers/Stepper"
import dayjs from 'dayjs'
import Select from "@/components/UI/Inputs/Select";
import Form from "@/components/UI/Forms/Form";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";

export default {
    components: {
        Input,
        DateInput,
        Table,
        Stepper,
        Select,
        SelectAutoComplete,
        Form,
        SearchUserById,
    },
    props:{
        preview_diary: {
            typeof: Boolean,
            default: false
        },
        diary_helper: {
            typeof: Object,
            defautl: null
        }
    },
    data: () => ({
        VIEW_ONLY: false,
        deleteArrays:{
            equipment: [],
            MO: [],
            material: [],
            subEmpreitadas:[]
        },
        allLoaded: false,
        lookById: true,
        searchByIdName: '',
        classes: [],
        render0: 0,
        render1: 0,
        render2: 0,
        render3: 0,
        render4: 0,
        render5: 0,
        success: null,
        loading: false,
        error: null,
        error_message: "",
        maoObra: [],
        fields: {
            frente_obra: null,
            Data: null,
            classe: null,
            unidadeObra: null,
            classeID: null
        },
        moHeaders: [
            {
                alignment: "center",
                text: "Nome"
            },
            {
                alignment: "center",
                text: "HN"
            },
            {
                alignment: "center",
                text: "HExt"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMOInternal: [{
            id: dayjs().unix(),
            funcionario: null,
            hn: 0,
            hext: 0,
            local: null,
            options: ""
        }],
        itemsMOExternal: [{
            id: dayjs().unix(),
            entidade_pessoal: null,
            hn: 0,
            hext: 0,
            local: null,
            options: ""
        }],
        equipHeaders: [
            {
                alignment: "center",
                text: "Nome"
            },
            {
                alignment: "center",
                text: "HS"
            },
            {
                alignment: "center",
                text: "HO"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsEquipInternal: [{
            id: dayjs().unix(),
            equipamento: null,
            hs: 0,
            ho: 0,
            local: null,
            options: ""
        }],
        itemsEquipExternal: [{
            id: dayjs().unix(),
            equipamento: null,
            hs: 0,
            ho: 0,
            local: null,
            options: ""
        }],
        materialHeaders: [
            {
                alignment: "center",
                text: "Artigo"
            },
            {
                alignment: "center",
                text: "UN"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMaterial: [{
            id: dayjs().unix(),
            product: null,
            un: "-",
            qty: 0,
            local: null,
            options: ""
        }],
        subcontractHeaders: [
            {
                alignment: "center",
                text: "Trabalho"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsSubcontract: [{
            id: dayjs().unix(),
            work: null,
            qty: 0,
            local: null,
            options: ""
        }],
        funcionarios: [],
        entidades_pessoal: [],
        equipamentos: {
            interno: [],
            externo: []
        },
        sub_familias: [],
        sub_empreitadas: [],
    }),
    mounted(){
        this.fillInformationToEdit()
        
    },
    methods: {
        fillInformationToEdit(){
            let ID = this.$route.params.id
            let data = {
                id: ID,
                labor: false,
            }
            if(this.preview_diary){
                this.VIEW_ONLY = true
                ID = this.diary_helper.diary_id
                data = {
                    id: ID,
                    hash: this.diary_helper.diary_web_hash,
                    id_user: this.diary_helper.diary_user
                }
            }

            this.$store.dispatch("getPDEquipmentERP", data)
                .then(res => {
                    let data = res.data
                    
                    // Se veio do ERP fica só view only
                    this.VIEW_ONLY = true

                   
                    let pdEQ_Interno = data.pdeq['interno']
                    let pdEQ_Externo = data.pdeq['externo']

                    if(pdEQ_Interno != undefined){
                        this.fields.Data = pdEQ_Interno.Data.split(' ')[0]
                        this.fields.unidadeObra = pdEQ_Interno.ObraClasseDescricao
                        this.fields.classeID = pdEQ_Interno.ClasseID
                    }else if(pdEQ_Externo != undefined){
                        this.fields.Data = pdEQ_Externo.Data.split(' ')[0]
                        this.fields.unidadeObra = pdEQ_Externo.ObraClasseDescricao
                        this.fields.classeID = pdEQ_Externo.ClasseID
                    }
                        
              
                    // Preencher Equipamento Interno
                    this.itemsEquipInternal = []
                    if(pdEQ_Interno != null){
                        pdEQ_Interno.linhas.forEach(pdel => {
                            this.itemsEquipInternal.push({
                                id: pdel.Id,
                                equipamento: pdel.EquipDescricao,
                                hs: parseFloat(pdel.Servico).toFixed(2),
                                ho: parseFloat(pdel.Ordem).toFixed(2),
                                local: pdel.Obs,
                                options: ""
                            })
                        });
                    }
                    // Fim Equipamento Interno

                    // Preencher Equipamento Externo
                    this.itemsEquipExternal = []
                    if(pdEQ_Externo != null){
                        pdEQ_Externo.linhas.forEach(pdel => {
                            this.itemsEquipExternal.push({
                                id: pdel.Id,
                                equipamento: pdel.EquipDescricao,
                                hs: parseFloat(pdel.Servico).toFixed(2),
                                ho: parseFloat(pdel.Ordem).toFixed(2),
                                local: pdel.Obs,
                                options: ""
                            })
                        });
                    }
                    // Fim Equipamento Externo

                    
                        
                    this.allLoaded = true
                })
                .catch(err => {

                    console.log(err)
            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
                text: 'Consultar Diários',
                disabled: false,
                to: '/diarios',
                exact: true,
            },
            {
                text: 'Registo Diário Equipamentos - ERP',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>
::v-deep .v-data-table .v-select{
  width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}
</style>