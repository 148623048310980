var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"10","offset-md":"1"}},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-laser-pointer")]),_vm._v("Registo Diário Equipamentos - ERP")],1),_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('v-divider',{staticClass:"mb-12"}),_c('Form',{ref:"pd",attrs:{"viewOnly":_vm.VIEW_ONLY,"loading":_vm.loading,"submitText":'Guardar'}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('Input',{attrs:{"label":"Data","placeholder":"Insira Data","disabled":""},model:{value:(_vm.fields.Data),callback:function ($$v) {_vm.$set(_vm.fields, "Data", $$v)},expression:"fields.Data"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('Select',{attrs:{"items":_vm.$store.state.user.data.frentes_obra,"item-text":"Descricao","label":"Frentes de Obra","rules":"required","vid":"frentes_obra","return-object":"","disabled":""},model:{value:(_vm.fields.frente_obra),callback:function ($$v) {_vm.$set(_vm.fields, "frente_obra", $$v)},expression:"fields.frente_obra"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('Input',{attrs:{"label":"Unidade Obra","disabled":""},model:{value:(_vm.fields.unidadeObra),callback:function ($$v) {_vm.$set(_vm.fields, "unidadeObra", $$v)},expression:"fields.unidadeObra"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"primary--text"},[_vm._v("Equipamento Interno")]),(_vm.itemsEquipInternal.length > 0)?_c('Table',{attrs:{"headers":_vm.equipHeaders,"items":_vm.itemsEquipInternal,"item-value":"id"},scopedSlots:_vm._u([{key:"equipamento",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.equipamento))])]}},{key:"hs",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"HS","placeholder":"Insira HS"},model:{value:(item.hs),callback:function ($$v) {_vm.$set(item, "hs", $$v)},expression:"item.hs"}})]}},{key:"ho",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"HO","placeholder":"Insira HO"},model:{value:(item.ho),callback:function ($$v) {_vm.$set(item, "ho", $$v)},expression:"item.ho"}})]}},{key:"local",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"Local","placeholder":"Insira Local"},model:{value:(item.local),callback:function ($$v) {_vm.$set(item, "local", $$v)},expression:"item.local"}})]}},{key:"options",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-caption"},[_vm._v("No options available")])]}}],null,false,2277334437)}):_c('div',[_c('p',[_vm._v("Nothing to show")])])],1)],1),_c('v-divider',{staticClass:"mt-12 mb-12"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"primary--text"},[_vm._v("Equipamento Externo")]),(_vm.itemsEquipExternal.length > 0)?_c('Table',{attrs:{"headers":_vm.equipHeaders,"items":_vm.itemsEquipExternal,"item-value":"id"},scopedSlots:_vm._u([{key:"equipamento",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.equipamento))])]}},{key:"hs",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"HS","placeholder":"Insira HS"},model:{value:(item.hs),callback:function ($$v) {_vm.$set(item, "hs", $$v)},expression:"item.hs"}})]}},{key:"ho",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"HO","placeholder":"Insira HO"},model:{value:(item.ho),callback:function ($$v) {_vm.$set(item, "ho", $$v)},expression:"item.ho"}})]}},{key:"local",fn:function(ref){
var item = ref.item;
return [_c('Input',{attrs:{"label":"Local","placeholder":"Insira Local"},model:{value:(item.local),callback:function ($$v) {_vm.$set(item, "local", $$v)},expression:"item.local"}})]}},{key:"options",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-caption"},[_vm._v("No options available")])]}}],null,false,2277334437)}):_c('div',[_c('p',[_vm._v("Nothing to show")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }