<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Registo de Máquinas</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <Form ref="pd" :loading="loading" @submited="addODCMachine">
                    <v-row>
                        <v-col cols="12" md="4">
                            <Input 
                                label="ID Máquina"
                                placeholder="Insira um ID para a máquina"
                                v-model="fields.code"
                                rules="required"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-combobox
                                :items="equipamentos"
                                item-text="EntityKey"
                                label="Código"
                                vid="equipamentos"
                                hint="Pesquise o equipamento pelo seu código"
                                persistent-hint
                                class="basic"
                                v-model="fields.machine"
                                return-object
                            />
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-autocomplete
                                :items="equipamentos"
                                item-text="Descricao"
                                label="Equipamentos"
                                vid="equipamentos"
                                hint="Pesquise o equipamento pelo seu nome"
                                persistent-hint
                                class="basic"
                                v-model="fields.machine"
                                return-object
                            />
                        </v-col>
                        
                    </v-row>
                    
                </Form>
            </v-col>
        </v-row>
        <SuccessSnackbar message="Dados gravados!" v-model="success"/>

        <ErrorSnackbar v-model="error" :message="error_message"/>
    </v-container>
</template>
<script>
import dayjs from 'dayjs'
import DateInput from "@/components/UI/Inputs/DateInput"
import Table from "@/components/UI/Tables/Table"
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Form from "@/components/UI/Forms/Form";
import Input from "@/components/UI/Inputs/Input"
import Select from "@/components/UI/Inputs/Select";

export default {
    components: {
        DateInput,
        Table,
        SelectAutoComplete,
        SuccessSnackbar,
        ErrorSnackbar,
        Form,
        Select,
        Input
    },
    data: () => ({
        render0:0,
        success: null,
        error: null,
        error_message: "",
        loading: false,
        classes: [],
        equipamentos: [],
        fields:{
            code: null,
            machine: null
        }

    }),
    async mounted(){
        await this.$store.dispatch("listEquipamentos")
            .then((res) => {
                this.equipamentos = res.data
        }); 

        if(this.$route.params.id != undefined){
            
            this.$store.dispatch("getODCMachine", this.$route.params.id)
                .then((res) => {
                    this.fields.code = res.data.code
                    this.fields.machine = res.data.machine
                    this.fields.id = res.data.id
            }); 
            
        }


    },
    methods:{
        addODCMachine(){
            if(this.$route.params.id){
                this.$store.dispatch("updateODCMachine", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/odc_machines");
                        }, 2000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }else{
                this.$store.dispatch("createMachine", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/odc_machines");
                        }, 2000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }
        },
        
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Registo de Máquina',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>
<style scoped>
::v-deep .v-data-table .v-select{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}
</style>