<template>
    <ValidationObserver ref="form" v-slot="{invalid, reset}">
        <v-form
            @submit.prevent="submit"
            @reset.prevent="reset"
            :disabled="viewOnly ? true : loading"
        >
        <v-card elevation="0">
            <v-card-text>
            <slot />
        </v-card-text>
        <v-card-actions>

            <v-row justify="center">
                <Button
                    v-if="!viewOnly"
                    type="submit"
                    :disabled="invalid"
                    :loading="loading"
                    class="mr-2"
                >
                    {{submitText || 'Submeter'}}
                </Button>

                <v-btn
                    v-if="toApprove"
                    @click.prevent="approveSender()"
                    :disabled="invalid"
                    :loading="loading"
                    class="mr-2"
                    color="success"
                >
                    Aprovar
                </v-btn>

                <Button
                    type="reset"
                    :loading="loading"
                    v-if="clearable || !viewOnly"
                    class="ml-2"
                    color="warning"
                >
                    {{clearText || 'Limpar'}}
                </Button>
            </v-row>
        </v-card-actions>
        </v-card>
        </v-form>
    </ValidationObserver>
</template>
<script>
import '@/plugins/validations.js'
import Button from "@/components/UI/Buttons/Button";

export default {
    components: {
        Button
    },
    props: {
        toApprove: {
            Typeof: Boolean,
            default: false,
        },
        submitText: String,
        clearText: String,
        clearable: Boolean,
        loading: Boolean,
        viewOnly: {
            typeof: Boolean,
            default: false
        }
    },
    mounted(){
    },
    methods: {
        approveSender: function(){
            this.$emit('approve-diary');
        },
        submit: function() {
            this.$emit("submited");
        },
        reset: function() {
            this.$emit("reseted");
        }
    }
}
</script>
<style scoped>

</style>