import User from "@/api/User";

export const user = {
    state: () => ({
        data: null
    }),
    mutations: {
        SET_USER(state, user) {
            state.data = user
        }
    },
    getters: {
        hasPermission: (state) => (permissions) => {
            if(state.data == null){
                return false;
            }
            if(state.data.permissions == undefined)
                return false;

            return state.data.permissions.some(permission => permissions.indexOf(permission.name) >= 0);
        },
        hasRole: (state) => (roles) => {
            if(state.data == null){
                return false;
            }
            
            if(state.data.roles == undefined)
                return false;

            return state.data.roles.some(role => roles.indexOf(role.name) >= 0);
        },
    },
    actions: {
        async login(params, data) {
            return new Promise((resolve, reject) => {
                User.login(data.email, data.password)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async searchUsers(params, data) {
            return new Promise((resolve, reject) => {
                User.search(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getAllUsers(params, data) {
            return new Promise((resolve, reject) => {
                User.all(data)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getUser(params, id) {
            return new Promise((resolve, reject) => {
                User.getUser(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },    
        async getUserRole(params, id) {
            return new Promise((resolve, reject) => {
                User.getUserRole(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },  
        async loginMFA(params, data) {
            return new Promise((resolve, reject) => {
                User.loginMFA(data.code)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        async logout({commit}, data) {
            let sender = data
            if(data == undefined)
                sender = null;

            if(sender != null)
                if(data.data == undefined)
                    sender = null;
                
            return new Promise((resolve, reject) => {
                User.logout(sender)
                    .then(response => {
                        commit("SET_USER", {});

                        resolve(response);
                    }).catch(err => {
                        commit("SET_USER", {});

                        reject(err);
                    });
            });
        },
        async createUser(params, fields) {
            return new Promise((resolve, reject) => {
                User.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateUser(params, data) {
            return new Promise((resolve, reject) => {
                User.updateUser(data.fields, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateUserObras(params, data) {
            return new Promise((resolve, reject) => {
                User.updateObras(data.fields, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteUser(params, id) {
            return new Promise((resolve, reject) => {
                User.deleteUser(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateProfileGeneral(params, fields) {
            return new Promise((resolve, reject) => {
                User.updateProfileGeneral(fields.name, fields.email, fields.alerts)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateProfileSecurity({ commit }, data) {
        
            return new Promise((resolve, reject) => {
              User.updateProfileSecurity(data.current_password, data.password, data.password_confirmation, data.mfa)
                .then(response => {
                  resolve(response);
                }).catch(err => {
                  reject(err);
                });
            })
          },
        async getMFA() {
            return new Promise((resolve, reject) => {
                User.enable2FA()
                    .then(response => {
                        return User.get2FA()
                            .then(response => {
                                resolve(response);
                            }).catch(err => {
                                reject(err);
                            });
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getSession({commit}) {
            return new Promise((resolve, reject) => {
                User.getSession()
                    .then(response => {
                        commit("SET_USER", response.data);

                        resolve(response);
                    }).catch(err => {
                        commit("SET_USER", {});

                        reject(err);
                    });
            });
        },
        async resetUser(params, email) {
            return new Promise((resolve, reject) => {
                User.reset(email)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateUserPassword(params, fields) {
            return new Promise((resolve, reject) => {
                User.updatePassword(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async verifyUser(params, fields) {
            return new Promise((resolve, reject) => {
                User.verify(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}