<template>
    <v-container fluid>        
        <v-card class="elevation-0">
            <v-card-title class="d-none d-lg-block warning white--text">
                Observações
            </v-card-title>
            <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                Observações
            </v-card-title>
            <v-card-text>
                <v-simple-table class="d-none d-lg-block" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-left">
                                    Criador por
                                </th>
                                <th class="text-left">
                                    Obs. Equip.
                                </th>
                                <th class="text-left">
                                    Obs. Mão Obra
                                </th>
                                <th class="text-left">
                                    Trabalho efetuado
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in fields.observations" :key="item.id"
                            >
                                <td></td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.obs_equipments == null ? '-' : item.obs_equipments}}                                    
                                </td>
                                <td>                                   
                                    {{item.obs_work_hand == null ? '-' : item.obs_work_hand}}    
                                </td>  
                                <td v-if="concatData(item).length > 0">
                                    <v-container fluid v-for="(item2, index) in concatData(item)" :key="index">              
                                        <v-icon>mdi-circle-small</v-icon>{{item2 == '' ? '-' : item2}}
                                    </v-container>
                                </td>     
                                <td v-else>
                                    -
                                </td>                          
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    name:"Observations",
    props:{
        fields: Object,
    },
    data(){
        return{

        }
    },
    mounted(){
        console.log("OBSRVACOE")
        console.log(this.fields)
    },
    methods:{
        concatData(data){
            let string = []
            data.work_order_details.forEach(element => {
                string.push(element.work_order_service + ': ' + element.description)
            });

            return string
        }
    }
}
</script>