import API from "./API";

export default class GarantiasObras extends API
{
    static async all() {
        return this.get("api/admin/garantias-obras/list");
    }

    static async search(filter) {
        return this.get("api/admin/garantias-obras",{params: filter});
    }

    static async create(fields) {
        return this.post("api/admin/garantias-obras", fields);
    }

    static async update(fields, id) {
        return this.put("api/admin/garantias-obras/" + id, fields);
    }

    static async deleteObraGuarantee(id) {
        return this.delete("api/admin/garantias-obras/" + id);
    }

    static async find(id) {
        return this.get("api/admin/garantias-obras/" + id);
    }
}