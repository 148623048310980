import Statistics from "@/api/Statistics";

export const statistics = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getStatistics(params) {
            return new Promise((resolve, reject) => {
                Statistics.getStatistics()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}