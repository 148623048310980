<template>
    <v-container fluid>
        <MiniAlerts style="white-space: pre-line" v-if="alert.showAlert" :message="alert.message" />
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Registo Diário Manobrador</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>

                <Form :loading="loading" ref="pd" @submited="createPD_validator">
                    <v-row>
                        <v-col cols="12" md="2">
                            <DateInput 
                                label="Data"
                                placeholder="Insira Data"
                                v-model="fields.Data"
                                @change="defaultDataChanged"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <Select
                                v-model="fields.obra"
                                :items="obras_user_tem_acesso"
                                item-text="Descricao"
                                label="Obras"
                                rules="required"
                                vid="obras"
                                return-object
                                @change="choosed_work"
                            />
                        </v-col>
                        <v-col cols="12" md="4" v-if="fields.obra != null && fields.obra.classes != null && fields.obra.classes.length > 0">
                            <Select
                                v-model="fields.classe"
                                :items="fields.obra.classes"
                                item-text="Descricao"
                                label="Classes"
                                rules="required"
                                vid="classe"
                                return-object
                                @change="choosedClass"
                            />
                        </v-col>
                        <v-col cols="12" md="6"  v-if="choosedWorkFlag && computeFrentesObra.length > 0">
                            <Select
                                v-model="fields.frente_obra"
                                :items="computeFrentesObra"
                                item-text="Descricao"
                                label="Frentes de Obra"
                                rules="required"
                                vid="frentes_obra"
                                @change="fillEquipamentos"
                                return-object
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!workChoosed">
                        <v-col cols="12">
                            <v-alert
                            icon="mdi-alert-box"
                            prominent
                            text
                            type="error"
                            >
                                Escolha uma <strong>data</strong> e uma <strong>frente de obra</strong> para prosseguir
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-show="workChoosed">
                        <v-col cols="12">
                            <Table :headers="headers" :items="line" item-value="id">
                                <template v-slot:equip="{item}">
                                    <SelectAutoComplete
                                        v-if="item.accessory == false"
                                        :items="equipamentos"
                                        item-text="Descricao"
                                        label="Equipamento"
                                        rules="required"
                                        vid="frentes_obra"
                                        class="basic"
                                        v-model="item.equip"
                                        return-object
                                        @change="equipmentChanged(item)"
                                    />

                                    <SelectAutoComplete
                                        v-else
                                        :items="unassignedMachines"
                                        item-text="Descricao"
                                        label="Acessório"
                                        rules="required"
                                        vid="frentes_obra"
                                        class="basic"
                                        v-model="item.equip"
                                        return-object
                                    />
                                </template>
                                <template v-slot:ho="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="HO"
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:hs="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="HS"
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:ha="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="HA"
                                                placeholder="Insira HA"
                                                v-model="item.ha"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:km="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="KM"
                                                placeholder="Insira KM"
                                                v-model="item.km"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:h="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="H"
                                                placeholder="Insira H"
                                                v-model="item.h"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:local="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item)"   
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:user="{item}">
                                    <span :key="render">{{ item.equip != null ? item.equip.manobrador : "-"}}</span>
                                </template>
                                <template v-slot:hn="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="HN"
                                                placeholder="Insira HN"
                                                v-model="item.hn"
                                                :disabled="item.accessory"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:hext="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="HExt"
                                                placeholder="Insira HExt"
                                                v-model="item.hext"
                                                :disabled="item.accessory"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:diesel="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="Qtd. Diesel"
                                                placeholder="Insira Qty"
                                                v-model="item.diesel"
                                                :disabled="item.accessory"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:oil="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="Qtd. Óleo"
                                                placeholder="Insira Qty"
                                                :disabled="item.accessory"
                                                v-model="item.oil"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:adblue="{item}">
                                    <v-row>
                                        <v-col cols="12">
                                            <Input 
                                                label="Qtd. Adblue"
                                                placeholder="Insira Qty"
                                                :disabled="item.accessory"
                                                v-model="item.adblue"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:options="{item}">
                                    <v-row>
                                        <v-col cols="12" md="auto">
                                            <v-btn
                                                dark
                                                small
                                                color="error"
                                                @click="deleteLine(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </Table>
                                <v-divider></v-divider>
                            <v-row justify="center">
                                <v-col cols="12" md="auto">
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        class="mt-4 mb-4"
                                        @click="addLineToTable"
                                    >
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        class="mt-4 mb-4"
                                        color="warning"
                                        @click="addAccessoryLine"
                                        >
                                        <v-icon small>mdi-bulldozer</v-icon>
                                    </v-btn>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </Form>   
            </v-col>
        </v-row>

        <SuccessSnackbar message="Dados gravados!" v-model="success"/>

        <ErrorSnackbar v-model="error" :timeout="timeout" :message="error_message" @input="timeout = 2000;"/>

        <ConfirmModal @yes="createPD" @close-modal="confirmationModal.show = false" v-show="confirmationModal.show" :title="confirmationModal.title" :message="confirmationModal.message"/>

        <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
        <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>

    </v-container>    
</template>

<script>
import Input from "@/components/UI/Inputs/Input"
import DateInput from "@/components/UI/Inputs/DateInput"
import Table from "@/components/UI/Tables/Table"
import dayjs from 'dayjs'
import Select from "@/components/UI/Inputs/Select";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import Form from "@/components/UI/Forms/Form";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import MiniAlerts from '@/components/Alerts/MiniAlerts.vue';
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import User from "@/api/User.js"


export default {
    components: {
        InputEditorModal,
        MiniAlerts,
        Input,
        DateInput,
        Table,
        Select,
        Form,
        SuccessSnackbar,
        ErrorSnackbar,
        SelectAutoComplete,
        ConfirmModal
    },
    data: () => ({
        choosedWorkFlag: false,
        workChoosed: false, 
        timeout: 2000,
        inputEditorModalData:{
            itemModel:null,
            id: null,
        },        
        editorModal:false,
        confirmationModal: {
            show: false,
            title: '',
            message: ''
        },
        alert:{
            showAlert: false,
            message: 'dsafdasfdasfsdsa'
        },
        render: 0,
        success: null,
        loading: false,
        error: null,
        error_message: "",
        headers: [
            {
                alignment: "center",
                text: "Equip."
            },
            {
                alignment: "center",
                text: "HO"
            },
            {
                alignment: "center",
                text: "HS"
            },
            {
                alignment: "center",
                text: "HA"
            },
            {
                alignment: "center",
                text: "KM"
            },
            {
                alignment: "center",
                text: "H"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Utilizador"
            },
            {
                alignment: "center",
                text: "HN"
            },
            {
                alignment: "center",
                text: "HExt"
            },
            {
                alignment: "center",
                text: "Diesel"
            },
            {
                alignment: "center",
                text: "Óleo"
            },
            {
                alignment: "center",
                text: "Adblue"
            },
            {
                alignment: "center",
                text: ""
            },
        ],
        fields: {
            frente_obra: null,
            Data: null,
            obra: null,
            classe: null
        },
        equipamentos: [],
        unassignedMachines: [],
        line: [{
            id: dayjs().unix(),
            accessory: false,
            equip: null,
            ho: parseFloat(0).toFixed(2),
            hs: parseFloat(0).toFixed(2),
            ha: parseFloat(0).toFixed(2),
            km: parseFloat(0).toFixed(2),
            h: parseFloat(0).toFixed(2),
            local: null,
            user: null,
            hn: parseFloat(8).toFixed(2),
            hext: parseFloat(0).toFixed(2),
            diesel: parseFloat(0).toFixed(2),
            oil: 0,
            adblue: 0,
            options: ""
        }],
        rules_error_messages:{
            confirmation_message: 'Preencheu o formulario e está prestes a submeter o mesmo. \nTem a certeza que preencheu tudo bem? \n\nPretende prosseguir?',
            rule0: 'Há campos por preencher. Campos obrigatórios: HO, HS, HA, KM, H, HN e HExt',
            rule1: 'Horas normais + Horas extra devem ser superiores ou iguais a zero',
            rule2: 'As horas normais do equipamento não devem ser superiores ás horas de trabalho',
            rule3: 'Horas extra devem ser sempre iguais ou superiores às da linha anterior',
            rule4: 'Horas normais devem ser inferiores a 24',
            rule5: 'Campo dos KM ou Horas deve ser preenchido',
            rule6: 'Tem valores a 0 (zero). \n\nTenha atenção e confira se pretende mesmo que estes dados sejam registados. \n\nPretende prosseguir?'
        },
        obras_user_tem_acesso: []
    }),
    mounted(){
        this.fillBaseData()
    },
    methods: {   
        fillBaseData(){
            //\Route::name("admin.users.get-obras")->get("get-obras/{id}", "Obras\GetObras");
            User.getUserObras(this.$store.state.user.data.id).then((resp)    =>  {
                this.obras_user_tem_acesso = resp.data
            })
            
        },
        defaultDataChanged(){
            if(this.fields.frente_obra == null)
                return
            if(this.fields.Data == null)
                return

            this.workChoosed = true
        },
        deleteLine(id){
            if(this.line.length == 1){
                this.error_message = "Não pode apagar a única linha que tem"
                this.error = true
                return
            }
                
            let index = this.line.findIndex(item => item.id == id);

            this.line.splice(index, 1);
        },
        changeEditorModal(e){
            this.line.forEach(element => {
                if(element.id == this.inputEditorModalData.id)
                    element.local = e
            });
        },
        openEditorModal(item){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.editorModal=true
        },
        equipmentChanged(item){
            this.render++
            // Verificar qual o último valor inserido nos KM do equipamento, dentro de uma PD EQ
            this.$store.dispatch("getLastHistory", item.equip.Id)
                .then(res => {
                    item.km = parseInt(res.data.Contador2)
                })


                let data={
                    'date': this.fields.Data,
                    'id':  item.equip.Id,
                    'work_id': this.fields.obra.Id
                };

            this.$store.dispatch("hasExternalCommInDate", data)
                .then((res) => {
                    if(res.data.hasObject != null){
                        let ob = res.data.hasObject
                        let diesel = res.data.diesel
                        let work = res.data.work_schedule
                        if(work != null){
                            item.ho = work.difference - ob
                            item.hs = ob
                            item.diesel = diesel
                        }
                    }
                }).catch(err => {

                    console.log(err)
            });
        },
        addLineToTable(){
            this.line.push({
                id: dayjs().unix(),
                accessory: false,
                equip: null,
                ho: parseFloat(0).toFixed(2),
                hs: parseFloat(0).toFixed(2),
                ha: parseFloat(0).toFixed(2),
                km: parseFloat(0).toFixed(2),
                h: parseFloat(0).toFixed(2),
                local: null,
                user: null,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                diesel: parseFloat(0).toFixed(2),
                oil: 0,
                adblue: 0,
                options: ""
            })
        }, 
        addAccessoryLine(){
            let lastLine = this.line[this.line.length-1];

            this.line.push({
                id: 'new-'+dayjs().unix().toString(),
                accessory: true,
                equip: null,
                ho: lastLine.ho,
                hs: lastLine.hs,
                ha: lastLine.ha,
                km: lastLine.km,
                h: lastLine.h,
                local: null,
                user: null,
                hn: null,
                hext: null,
                diesel: null,
                oil: null,
                adblue: null,
                options: ""
            })
        },
        choosed_work(){
            this.choosedWorkFlag = true
            this.loading = true


            let data = {
                work_id: this.fields.obra.Id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'manobrador'
            }

            // Manobrador acede apenas a equipamentos externos
            this.$store.dispatch("listObrasEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data.externo;
                })  

          
            this.$store.dispatch("getUnassigned", this.fields.obra.Id)
            .then((res) => {
                
                this.unassignedMachines = res.data

            }).catch(err => {

                console.log(err)
            });

            this.loading = false

            if(this.fields.obra.classes.length == 0){
                if(this.fields.obra.frentes_obra.length > 0)
                    this.workChoosed = false
                else{
                    this.error_message = 'Esta obra não tem uma classe associada. Para criar a diária é preciso ter, no mínimo, uma classe a ela associada'
                    this.timeout = 10000
                    this.error = true
                    this.loading = false
                }
            }else{
                if(this.fields.obra.classes.length == 1){
                    this.fields.classe = this.fields.obra.classes[0]
                    this.workChoosed = true
                }
            }
        },
        choosedClass(){
            this.workChoosed = true
        },
        async fillEquipamentos() {
            let data = {
                id: this.fields.frente_obra.SubEmpId, 
                funcionarioCodigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'manobrador'
            };

            await this.$store.dispatch("listFrenteObraEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data.interno.concat(res.data.externo);
                })

            if(this.equipamentos.length == 0){
                this.error_message = "Não existem equipamentos disponíveis para esta frente de obra"
                this.timeout = 4000
                this.error = true
                this.fields.frente_obra = null
                this.workChoosed = false
                return
            }else this.workChoosed = true

            this.defaultDataChanged()
        },
        validateFormData(){
            let errors = []
            let last_lower_value = 0
            let has_error = false
            let line = 1
            this.alert.message = ''
            let has_valores_zero = false
            this.line.forEach(l => {
                if(l.accessory == false){
                    let stepper = []

                    // Verificar se há valores a zero
                    if(
                        l.km == 0 ||
                        l.ho == 0 ||
                        l.hs == 0 || 
                        l.ha == 0 || 
                        l.h == 0  || 
                        l.hn == 0 || 
                        l.hext == 0 ||
                        l.diesel == 0
                    ){
                        has_valores_zero = true
                    }

                    // Rule N# 0 - Campos por preencher
                    if(
                        l.ho == '' || l.ho == null ||
                        l.hs == '' || l.hs == null ||
                        l.ha == '' || l.ha == null ||
                        l.h == ''  || l.h == null ||
                        l.hn == '' || l .hn == null ||
                        l.hext == '' || l.hext == null ||
                        l.diesel == '' || l.diesel == null
                    ){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule0 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    // Rule N# 1 - Normal Hours + Extra hours (HN+HE) should be ≥ 0;
                    if((l.hn + l.hext) < 0){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule1 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    // Rule N# 2 -  The system should detect if the Normal hours of the driver is lower than the
                    //              equipments working hours. The equipment working hours is never superior of
                    //              the drivers. Total hours of the machine (HO+HS+HA) cannot be superior of
                    //              the drivers (HN+HE).
                    if((parseFloat(l.ho)+parseFloat(l.hs)+parseFloat(l.ha)) > (parseFloat(l.hn)+parseFloat(l.hext))){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule2 + "\n\n";
                    }else{
                        stepper.push(0)
                    }   
                        
                    // Rule N# 3 - The system must detect if the machines hours is less than the previous made diary.
                    if(l.hext < last_lower_value){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule3 + "\n\n";
                    }else{
                        stepper.push(0)
                        last_lower_value = l.hext
                    }

                    // Rule N# 4 - Normal Hours (HN) (The value should not be higher than 24)
                    if(l.hn > 24){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule4 + "\n\n";
                    }else{
                        stepper.push(0)
                    }

                    // Rule N# 5 -  The field “km or hours” must be filled.
                    if(l.km == '' || l.km == null){
                        stepper.push(1)
                        has_error = true
                        this.alert.message += "[Linha " + line + "] - " + this.rules_error_messages.rule5 + "\n\n";
                    }else{
                        stepper.push(0)
                    }
                    line++
                    errors.push(stepper)
                }
                
            });
            return {
                has_error: has_error,
                has_valores_zero: has_valores_zero
            }
        },
        createPD_validator(){
            let validation = this.validateFormData()
            
            if(validation.has_error){
                this.alert.showAlert = true 
                setTimeout(() => {
                    this.alert.showAlert = false 
                }, "10000")

                return
            }

            if(validation.has_valores_zero){
                this.confirmationModal.show = true
                this.confirmationModal.title = 'Tem a certeza?'
                this.confirmationModal.message = this.rules_error_messages.rule6
            }else{
                this.confirmationModal.show = true
                this.confirmationModal.title = 'Tem a certeza?'
                this.confirmationModal.message = this.rules_error_messages.confirmation_message                
            }
        },
        createPD() {

            this.loading = true;

            this.confirmationModal.show = false
     

            let lineItems = []
            let i = 1;
            this.line.forEach(l => {
                lineItems.push({
                    IdEquipamento: l.equip.Id,
                    accessory: l.accessory,
                    NumLinha: i,
                    local: l.local,
                    Servico: l.hs,
                    Ordem: l.ho,
                    Avariado: l.ha,
                    Contador2: l.km,
                    Contador1: l.h,
                    HNormal: l.hn,
                    HExtra: l.hext,
                    diesel: l.diesel,
                    oil: l.oil,
                    adblue: l.adblue,
                })
                i++
            });
            
        
            let data = {
                SubEmpId: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                classeID: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmp,
                Codigo: this.fields.obra.Codigo,
                Data: this.fields.Data,
                line: lineItems,
                classeID_manual: null
            };

            if(data.classeID == null){
                if(this.fields.classe == null){
                    this.error_message = 'Esta obra não tem uma classe associada. Para criar a diária é preciso ter, no mínimo, uma classe a ela associada'
                    this.timeout = 10000
                    this.error = true
                    this.loading = false
                    return
                }

                data.classeID_manual = this.fields.classe.ClasseId
            }

            this.$store.dispatch("createPDEq", data)
            .then((resp) => {
                let response = resp.data
                if(response.success == false){
                    this.error_message = response.error
                    this.error = true
                    this.timeout = 5000
                    this.loading = false;

                    return;
                }
                this.success = true;

                this.error = false;

                this.loading = false;

                setTimeout(() => {
                    this.$router.push("/diarios");
                }, 2000);
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });
        }
    },
    computed: {
        computeFrentesObra(){
            let returner = []


            /*this.$store.state.user.data.frentes_obra.forEach(element => {
                if(element.obra.Id == this.fields.obra.Id)
                    returner.push(element)
            });*/

            this.fields.obra.frentes_obra.forEach(element => {
                returner.push(element)
            });

            return returner
        },
        crumbs: function() {
        return [
            {
                text: 'Registo Diário Manobrador',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>
::v-deep .v-data-table .v-select{
  width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}
</style>