<template>
    <v-container fluid>
        <Loader v-if="!allLoaded"/>
        <v-row v-if="allLoaded">
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Registo Diário Encarregado</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-row v-if="sincronized">
                    <v-col cols="12" md="3">
                        <v-alert type="warning" shaped prominent dark v-show="sincronized" transition="scale-transition">
                            Diária sincronizada!
                        </v-alert>
                    </v-col>
                </v-row>
                <v-divider class="mb-12"></v-divider>


                <Form class="pa-0 ma-0" :viewOnly="VIEW_ONLY" ref="pd" :loading="loading" @submited="editPD" @approve-diary="confirmApprove" :toApprove="checkIfCanApprove(fields.current_approval)" :submitText="'Guardar'">
                    <v-row>
                        <v-col cols="12" md="4">
                            <Input 
                                label="Data"
                                placeholder="Insira Data"
                                v-model="fields.Data"
                                disabled
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <Input
                                v-model="fields.obra_nome"
                                :disabled="true"
                                label="Obras"
                            />
                        </v-col>
                        <v-col cols="12" md="4" v-if="fields.frente_obra != null">
                            <Select
                                v-model="fields.frente_obra"
                                :items="$store.state.user.data.frentes_obra"
                                item-text="Descricao"
                                label="Frentes de Obra"
                                rules="required"
                                vid="frentes_obra"
                                return-object
                                disabled
                            />
                        </v-col>
                        <v-col cols="12" md="4" v-if="fields.unidadeObra != null">
                            <Input 
                                label="Unidade Obra"
                                v-model="fields.unidadeObra"
                                disabled
                            />
                        </v-col>
                    </v-row>
                    
                    <Stepper :headers="['MO', 'EQ', 'MAT', 'SUB', 'Observações']" v-if="allLoaded">
                        <template v-slot:1>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="12">                                    
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">MO Interna</h3>
                                    </v-toolbar>
                                    <Table :headers="moHeaders" :items="itemsMOInternal" item-value="id">                                        
                                        <template v-slot:funcionario="{item}">     
                                            <v-row v-if="checkIfNew(item)">
                                                <v-col cols="12" md="auto">
                                                    <SearchUserById 
                                                        :funcionarios="funcionarios"
                                                        :userName="searchByIdName"     
                                                        :selectedFunc="item.funcionario"                                         
                                                        v-model="item.funcionario"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="auto">
                                                    <SelectAutoComplete
                                                        :items="funcionarios"
                                                        item-text="Nome"
                                                        label="Funcionário"
                                                        rules="required"
                                                        vid="funcionario"
                                                        class="basic"
                                                        v-model="item.funcionario"
                                                        return-object
                                                        @change="render0++"
                                                    />
                                                </v-col>
                                            </v-row>   
                                        <p v-else>{{item.funcionario.Funcionario}}</p>                                  
                                        </template>
                                        <template v-slot:hn="{item}">
                                            <Input 
                                                label="HN"
                                                placeholder="Insira HN"
                                                v-model="item.hn"
                                            />
                                        </template>
                                        <template v-slot:hext="{item}">
                                            <Input 
                                                label="HExt"
                                                placeholder="Insira HExt"
                                                v-model="item.hext"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 1)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deleteFuncionario(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addFuncionario"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-12 mb-12"/>

                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">MO Externa</h3>
                                    </v-toolbar>
                                    <Table :headers="moHeaders" :items="itemsMOExternal" item-value="id">
                                        <template v-slot:entidade_pessoal="{item}">
                                            <div v-if="checkIfNew(item)">
                                                <Select
                                                    :items="entidades_pessoal"
                                                    item-text="Nome"
                                                    label="Pessoal"
                                                    rules="required"
                                                    vid="pessoal"
                                                    class="basic"
                                                    v-model="item.entidade_pessoal"
                                                    return-object
                                                    @change="render1++"
                                                />
                                            </div>
                                            <p v-else>{{item.entidade_pessoal.Numero}} - {{item.entidade_pessoal.Nome}}</p>     
                                        </template>
                                        <template v-slot:hn="{item}">
                                            <Input 
                                                label="HN"
                                                placeholder="Insira HN"
                                                v-model="item.hn"
                                            />
                                        </template>
                                        <template v-slot:hext="{item}">
                                            <Input 
                                                label="HExt"
                                                placeholder="Insira HExt"
                                                v-model="item.hext"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 2)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deletePessoal(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addPessoal"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>   
                        </template>
                        <template v-slot:2>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Equipamento Interno</h3>
                                    </v-toolbar>
                                    <Table :headers="equipHeaders" :items="itemsEquipInternal" item-value="id">
                                        <template v-slot:equipamento="{item}">
                                            <div v-if="checkIfNew(item)">
                                                <SelectAutoComplete
                                                    :items="equipamentos.interno"
                                                    item-text="Descricao"
                                                    label="Equipamento"
                                                    rules="required"
                                                    vid="equipamento"
                                                    class="basic"
                                                    v-model="item.equipamento"
                                                    return-object
                                                    @change="render2++"
                                                />
                                            </div>
                                            <p v-else>{{getEquipmentName(item.equipamento)}}</p>
                                        </template>
                                        <template v-slot:hs="{item}">
                                            <Input 
                                                label="HS/dia"
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </template>
                                        <template v-slot:ho="{item}">
                                            <Input 
                                                label="HO"
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 3)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deleteEquipamentoInterno(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addEquipamentoInterno"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-12 mb-12"/>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Equipamento Externo</h3>
                                    </v-toolbar>
                                    <Table :headers="equipHeaders" :items="itemsEquipExternal" item-value="id">
                                        <template v-slot:equipamento="{item}">
                                            <div v-if="checkIfNew(item)">
                                                <SelectAutoComplete
                                                    :items="equipamentos.externo"
                                                    item-text="Descricao"
                                                    label="Equipamento"
                                                    rules="required"
                                                    vid="equipamento"
                                                    class="basic"
                                                    v-model="item.equipamento"
                                                    return-object
                                                    @change="render3++"
                                                />
                                            </div>
                                            <p v-else>{{getEquipmentName(item.equipamento)}}</p>
                                        </template>
                                        <template v-slot:hs="{item}">
                                            <Input 
                                                label="HS/dia"
                                                placeholder="Insira HS"
                                                v-model="item.hs"
                                            />
                                        </template>
                                        <template v-slot:ho="{item}">
                                            <Input 
                                                label="HO"
                                                placeholder="Insira HO"
                                                v-model="item.ho"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 4)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deleteEquipamentoExterno(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addEquipamentoExterno"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:3>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Material</h3>
                                    </v-toolbar>
                                    <Table :headers="materialHeaders" :items="itemsMaterial" item-value="id">
                                        <template v-slot:product="{item}">
                                            <SelectAutoComplete v-if="checkIfNew(item)"
                                                :items="sub_familias"
                                                item-text="SubFamilia.Descricao"
                                                label="Artigo"
                                                rules="required"
                                                vid="product"
                                                class="basic"
                                                v-model="item.product"
                                                return-object
                                                @change="render4++"
                                            />
                                            <p v-else>{{item.product}}</p>
                                        </template>
                                        <template v-slot:un="{item}">
                                            {{ item.un || "-" }}
                                        </template>
                                        <template v-slot:qty="{item}">
                                            <Input 
                                                label="Quantidade"
                                                placeholder="Insira Quantidade"
                                                v-model="item.qty"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 5)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deleteMaterial(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addMaterial"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:4>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar class="primary rounded-lg">
                                        <h3 class="white--text">Subempreitada</h3>
                                    </v-toolbar>
                                    <Table :headers="subcontractHeaders" :items="itemsSubcontract" item-value="id">
                                        <template v-slot:work="{item}">
                                            <SelectAutoComplete  v-if="checkIfNew(item)"
                                                :items="sub_empreitadas"
                                                item-text="Descricao"
                                                label="Trabalho"
                                                rules="required"
                                                vid="work"
                                                class="basic"
                                                v-model="item.work"
                                                return-object
                                                @change="render5++"
                                            />
                                            <p v-else>{{item.work}}</p>
                                        </template>
                                        <template v-slot:qty="{item}">
                                            <Input 
                                                label="Quantidade"
                                                placeholder="Insira Quantidade"
                                                v-model="item.qty"
                                            />
                                        </template>
                                        <template v-slot:local="{item}">
                                            <Input 
                                                label="Local"
                                                placeholder="Insira Local"
                                                v-model="item.local"
                                                @click="openEditorModal(item, 6)"
                                            />
                                        </template>
                                        <template v-slot:options="{item}">
                                            <v-btn
                                            v-if="!VIEW_ONLY"
                                            dark
                                            small
                                            color="error"
                                            @click="deleteSubEmpreitada(item.id)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p v-else class="text-caption">No options available</p>
                                        </template>
                                    </Table>
                                    <v-row justify="center">
                                        <v-btn
                                            v-if="!VIEW_ONLY"
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            class="mt-7"
                                            @click="addSubEmpreitada"
                                        >
                                            <v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:5>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar
                                        dark
                                        color="primary"
                                        class="rounded-lg"
                                        >
                                        <v-toolbar-title>Observações de Mão de Obra:</v-toolbar-title>
                                    </v-toolbar>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="fields.obsMO"
                                        label="Observações"
                                        hint="Escreva as suas observações relativas à mão de obra"
                                        persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-toolbar
                                        dark
                                        color="primary"
                                        class="rounded-lg"
                                        >
                                        <v-toolbar-title>Observações de Equipamentos:</v-toolbar-title>
                                    </v-toolbar>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="fields.obsEQ"
                                        label="Observações"
                                        hint="Escreva as suas observações relativas aos equipamentos"
                                        persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </template>
                    </Stepper>
                </Form>   
            </v-col>
        </v-row>
        <SuccessSnackbar message="Dados gravados!" v-model="success"/>

        
        <SuccessSnackbar message="Diária aprovada com sucesso!" v-model="successApproval"/>

        <ErrorSnackbar v-model="error" :message="error_message"/>

        <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
        <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>
        <DialogConfirmation 
            v-if="confirmationModal.show"
            :title="confirmationModal.title" 
            type="warning" 
            :opened="confirmationModal.show" 
            :elevation="0"
            @on-submit="approveDiary" 
            @on-cancel="confirmationModal.item = null; confirmationModal.show = false;">
                {{ confirmationModal.message }}
        </DialogConfirmation> 
        
    </v-container>    
</template>

<script>
import Input from "@/components/UI/Inputs/Input"
import DateInput from "@/components/UI/Inputs/DateInput"
import SearchUserById from "@/components/UI/Inputs/SearchUserById"
import Table from "@/components/UI/Tables/Table"
import Stepper from "@/components/UI/Steppers/Stepper"
import dayjs from 'dayjs'
import Select from "@/components/UI/Inputs/Select";
import Form from "@/components/UI/Forms/Form";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
import Loader from "@/components/UI/Loaders/Loader";
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

export default {
    components: {
        DialogConfirmation,
        Input,
        DateInput,
        Table,
        Stepper,
        Select,
        SelectAutoComplete,
        Form,
        SuccessSnackbar,
        ErrorSnackbar,
        SearchUserById,
        Loader,
        InputEditorModal
    },
    props:{
        preview_diary: {
            typeof: Boolean,
            default: false
        },
        diary_helper: {
            typeof: Object,
            defautl: null
        }
    },
    data: () => ({
        confirmationModal:{
            show: false,
            title: '',
            message: '',
            approvalItem: null
        },
        sincronized: false,
        editorModal:false,
        inputEditorModalData:{
            itemModel:null,
            id: null,
            type: null
        },
        VIEW_ONLY: false,
        deleteArrays:{
            equipment: [],
            MO: [],
            material: [],
            subEmpreitadas:[]
        },
        allLoaded: false,
        lookById: true,
        searchByIdName: '',
        classes: [],
        render0: 0,
        render1: 0,
        render2: 0,
        render3: 0,
        render4: 0,
        render5: 0,
        success: null,
        successApproval: false,
        loading: false,
        error: null,
        error_message: "",
        maoObra: [],
        fields: {
            frente_obra: null,
            obra_nome: null,
            Data: null,
            classe: null,
            obra_codigo: null,
            unidadeObra: null,
            classeID: null,
            current_approval: null,
            newObservationsClasses: null,
            obra: null
        },
        approvalObject:{
            id_user_creator: null,
            creationRole: null,
            webAppLinkHash: null
        },
        moHeaders: [
            {
                alignment: "center",
                text: "Nome"
            },
            {
                alignment: "center",
                text: "HN"
            },
            {
                alignment: "center",
                text: "HExt"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMOInternal: [{
            id: dayjs().unix(),
            funcionario: null,
            hn: 0,
            hext: 0,
            local: null,
            options: ""
        }],
        itemsMOExternal: [{
            id: dayjs().unix(),
            entidade_pessoal: null,
            hn: 0,
            hext: 0,
            local: null,
            options: ""
        }],
        equipHeaders: [
            {
                alignment: "center",
                text: "Nome"
            },
            {
                alignment: "center",
                text: "HS/dia"
            },
            {
                alignment: "center",
                text: "HO"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsEquipInternal: [{
            id: dayjs().unix(),
            equipamento: null,
            hs: 0,
            ho: 0,
            local: null,
            options: ""
        }],
        itemsEquipExternal: [{
            id: dayjs().unix(),
            equipamento: null,
            hs: 0,
            ho: 0,
            local: null,
            options: ""
        }],
        materialHeaders: [
            {
                alignment: "center",
                text: "Artigo"
            },
            {
                alignment: "center",
                text: "UN"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsMaterial: [{
            id: dayjs().unix(),
            product: null,
            un: "-",
            qty: 0,
            local: null,
            options: ""
        }],
        subcontractHeaders: [
            {
                alignment: "center",
                text: "Trabalho"
            },
            {
                alignment: "center",
                text: "Qtd"
            },
            {
                alignment: "center",
                text: "Local"
            },
            {
                alignment: "center",
                text: "Opções"
            },
        ],
        itemsSubcontract: [{
            id: dayjs().unix(),
            work: null,
            qty: 0,
            local: null,
            options: ""
        }],
        funcionarios: [],
        entidades_pessoal: [],
        equipamentos: {
            interno: [],
            externo: []
        },
        sub_familias: [],
        sub_empreitadas: [],
    }),
    mounted(){
        this.fillInformationToEdit()
    },
    methods: {
        checkIfNew(item){
            if(typeof item.id === 'number')
                return false
                
            return item.id.includes('new')
        },
        approveDiary(){
            //webAppLinkHash
            //id_user_creator
            //creationRole
           
            let approval = this.approvalObject

           
            
            if(approval != null){
                // Send hash from Header
                this.$store.dispatch("approvePD", approval)
                    .then(() => {
                        this.confirmationModal.item = null
                        this.confirmationModal.show = false
                        this.successApproval = true
                        window.location.reload()

                });
            }
        },
        confirmApprove(){
            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
            this.confirmationModal.show = true
        },
        checkIfCanApprove(approval){
            // Verficar primeiro o role... Se não tiver role de administrativo ou engenheiro, não pode aprovar
            if(!this.$store.getters.hasRole(['administrativo', 'engenheiro']))
                return false


            // Se está a null, significa que é preciso ser aprovado pelo ADMINISTRATIVO
            if(approval == null){
                if(this.$store.getters.hasRole(['engenheiro']))
                    return false

                return true
            }

            if(parseInt(approval.level) == 0){
                return this.$store.getters.hasRole(['administrativo'])
            }
            
            // Se tem approval, verificar qual o role de aprovação. 
            // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
            if(approval.level == 1){
                return this.$store.getters.hasRole(['engenheiro'])
            }
            
            // Se estiver a 2, significa que já foi totalmente aprovado e não precisa de mais aprovações
            return false

        },
        changeEditorModal(e){
            //this.inputEditorModalData.type
            //1 - MO Interna
            //2 - MO Externa
            //3 - EQ Interno
            //4 - EQ Externo
            //5 - Materiais
            //6 - Subempreitadas

            if(this.inputEditorModalData.type == 1){
                this.itemsMOInternal.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.type == 2){
                this.itemsMOExternal.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.type == 3){
                this.itemsEquipInternal.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.type == 4){
                this.itemsEquipExternal.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.type == 5){
                this.itemsMaterial.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.type == 6){
                this.itemsSubcontract.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
        },
        openEditorModal(item, type){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.inputEditorModalData.type = type
            this.editorModal=true
        },
        async fillInformationToEdit(){
            let ID = this.$route.params.id
            let webHash = this.$route.params.hash
            let data = {
                id: ID,
                hash: webHash,
                id_user: this.$route.params.id_user
            }

            let pedido = 'getPDEnc'
            if(this.preview_diary){
                pedido = 'getPDEncHalfLoader'
                this.VIEW_ONLY = true
                ID = this.diary_helper.diary_id
                webHash = this.diary_helper.diary_web_hash
                data = {
                    id: ID,
                    hash: webHash,
                    id_user: this.diary_helper.diary_user
                }
            }

            
            if(this.preview_diary){
                await this.loadPD(pedido, data, true)

                this.loadPD('getPDEnc', data)
            }else{
                this.loadPD('getPDEnc', data)
            }

        },
        async loadPD(pedido, data, halfLoader = false){
            await this.$store.dispatch(pedido, data)
                .then(res => {
                    let data = res.data

                    this.fields.obra = data.obra

                    
                    // Check if is view only
                    
                    // Se veio do ERP fica só view only
                    
                    if(data.webAppLinkHash == null || data.CreatedBy != 'partesdiarias')
                            this.VIEW_ONLY = true


                    // Se já está aprovada, é só view only
                    if(data.WebappApproved == 1)
                        this.VIEW_ONLY = true

            

                    if(data.approval_level_needed.approval_level == '2'){
                        // Se já foi aprovada pelo administrativo é só view only para ele e outros inferiores
                        // O engenheiro e super podem continuar a aprovar
                        if(data.current_approval != null){
                            if(parseInt(data.current_approval.level) == 1)
                                if(!this.$store.getters.hasRole(['super', 'engenheiro']))
                                    this.VIEW_ONLY = true
                        }
                    }

                    if(data.approval_level_needed.approval_level == '3'){
                        // Se já foi aprovada pelo administrativo é só view only para ele e outros inferiores
                        // O engenheiro e super podem continuar a aprovar
                        if(data.current_approval != null){
                            if(parseInt(data.current_approval.level) == 1)
                                if(!this.$store.getters.hasRole(['super', 'engenheiro', 'administrativo']))
                                    this.VIEW_ONLY = true
                        }

                        if(data.current_approval != null){
                            if(parseInt(data.current_approval.level) == 2)
                                if(!this.$store.getters.hasRole(['super', 'engenheiro']))
                                    this.VIEW_ONLY = true
                        }
                    }

                    
                    this.sincronized = data.sincronized
                    if(data.sincronized)
                        this.VIEW_ONLY = true


                    this.fields.current_approval = data.current_approval


                    if(this.fields.current_approval == null){
                        this.approvalObject.id_user_creator = data.id_user
                        this.approvalObject.creationRole = data.creationRole
                        this.approvalObject.webAppLinkHash = data.webAppLinkHash
                    }else{
                        this.approvalObject.id_user_creator = data.current_approval.id_user_creator
                        this.approvalObject.creationRole = data.current_approval.creation_role
                        this.approvalObject.webAppLinkHash = data.current_approval.pd_hash
                    }

                  
                    let pdencEQ_Interno = data.pdencEQ['interno']
                    let pdencEQ_Externo = data.pdencEQ['externo']

                    let pdencMO_Interno = data.pdencMO['interno']
                    let pdencMO_Externo = data.pdencMO['externo']

                    this.fields.id_user_creator = data.id_user

                    if(pdencEQ_Interno != undefined){
                        this.fields.Data = pdencEQ_Interno.Data.split(' ')[0]
                        this.fields.unidadeObra = pdencEQ_Interno.ObraClasseDescricao
                        this.fields.classeID = pdencEQ_Interno.ClasseID
                    }else if(pdencEQ_Externo != undefined){
                        this.fields.Data = pdencEQ_Externo.Data.split(' ')[0]
                        this.fields.unidadeObra = pdencEQ_Externo.ObraClasseDescricao
                        this.fields.classeID = pdencEQ_Externo.ClasseID
                    }else if(pdencMO_Interno != undefined){
                        this.fields.Data = pdencMO_Interno.Data.split(' ')[0]
                        this.fields.unidadeObra = pdencMO_Interno.ObraClasseDescricao
                        this.fields.classeID = pdencMO_Interno.ClasseID
                    }else{
                        this.fields.Data = pdencMO_Externo.Data.split(' ')[0]
                        this.fields.unidadeObra = pdencMO_Externo.ObraClasseDescricao
                        this.fields.classeID = pdencMO_Externo.ClasseID
                    }

                    if(data.pdencEQ.obs != undefined){
                        this.fields.obsEQ = data.pdencEQ.obs
                    }
                    if(data.pdencMO.obs != undefined){
                        this.fields.obsMO = data.pdencMO.obs
                    }

                    this.fields.obra_nome = data.obra_nome
                    this.fields.obra_codigo = data.obra_codigo
                    this.fields.frente_obra = data.pdencEQ.empreitada

                    if(halfLoader == false){
                        this.fillData()
                        this.fillMO()
                    }


                    // Preencher Mao Obra Interna
                    this.itemsMOInternal = []
                    if(pdencMO_Interno != null){
                        pdencMO_Interno.linhas.forEach(pdml => {
                            if(pdml.Id.includes('new'))
                                pdml.Id = 'new';

                            this.itemsMOInternal.push({
                                //id: pdml.ObraPessoal.PessoalID,
                                id: pdml.Id,
                                funcionario: pdml.ObraPessoal,
                                hn: parseFloat(pdml.HNormal).toFixed(2), 
                                hext: parseFloat(pdml.HExtra).toFixed(2),
                                local: pdml.Obs,
                                options:''
                            })
                        });
                    }
                    // Fim Mão obra Interna

                    // Preencher Mao Obra Externa
                    this.itemsMOExternal = []
                        if(pdencMO_Externo != null){
                        pdencMO_Externo.linhas.forEach(pdml => {
                            if(pdml.Id.includes('new'))
                                pdml.Id = 'new';

                            this.itemsMOExternal.push({
                                //id: pdml.funcionario_externo.ID,
                                id: pdml.Id,
                                entidade_pessoal: pdml.funcionario_externo,
                                hn: parseFloat(pdml.HNormal).toFixed(2), 
                                hext: parseFloat(pdml.HExtra).toFixed(2),
                                local: pdml.Obs,
                                options:''
                            })
                        });
                    }

                    // Fim Mao Obra Externa

                    if(halfLoader == false){
                        // Preencher Equipamento Interno
                        this.itemsEquipInternal = []
                        if(pdencEQ_Interno != null){
                            pdencEQ_Interno.linhas.forEach(pdel => {
                                if(pdel.Id.includes('new'))
                                    pdel.Id = 'new';
                                this.itemsEquipInternal.push({
                                    id: pdel.Id,
                                    equipamento: pdel.IdEquipamento,
                                    hs: parseFloat(pdel.Servico).toFixed(2),
                                    ho: parseFloat(pdel.Ordem).toFixed(2),
                                    local: pdel.Obs,
                                    options: ""
                                })
                            });
                        }
                        // Fim Equipamento Interno

                        // Preencher Equipamento Externo
                        this.itemsEquipExternal = []
                        if(pdencEQ_Externo != null){
                            pdencEQ_Externo.linhas.forEach(pdel => {
                                if(pdel.Id.includes('new'))
                                    pdel.Id = 'new';
                                this.itemsEquipExternal.push({
                                    id: pdel.Id,
                                    equipamento: pdel.IdEquipamento,
                                    hs: parseFloat(pdel.Servico).toFixed(2),
                                    ho: parseFloat(pdel.Ordem).toFixed(2),
                                    local: pdel.Obs,
                                    options: ""
                                })
                            });
                        }
                        // Fim Equipamento Externo

                        // Preencher Material
                        let material = data.material
                        this.itemsMaterial = []
                        if(material != null){
                            material.forEach(mat => {                            
                                if(mat.id.includes('new'))
                                    mat.id = 'new';
                                this.itemsMaterial.push({
                                    id: mat.id,
                                    product: mat.Descricao,
                                    un: mat.Unidade_Material,
                                    qty: mat.qty,
                                    local: mat.local,
                                    options: ''
                                })
                            });
                        }
                        // Fim Material

                        // Preencher SubEmpreitadas
                        let subEmpreitadas = data.subempreitada
                        this.itemsSubcontract = []
                        if(subEmpreitadas != null){
                            subEmpreitadas.forEach(sEmp => {
                                if(sEmp.id.includes('new'))
                                    sEmp.id = 'new';

                                this.itemsSubcontract.push({
                                    id: sEmp.id,
                                    product: sEmp.Descricao,
                                    un: "-",
                                    qty: sEmp.qty,
                                    local: sEmp.local,
                                    options: ''
                                })
                            });
                        }
                        // Fim 
                    }

                    this.fields.newObservationsClasses = data.newObservationsClasses

                    if(this.fields.newObservationsClasses != null){
                        this.fields.obsEQ = this.fields.newObservationsClasses.obs_equipments
                        this.fields.obsMO = this.fields.newObservationsClasses.obs_work_hand
                    }
                        
                    this.allLoaded = true
                })
                .catch(err => {

                    console.log(err)
            });
            this.choosed_work()
        },
        getEquipmentName(code){
            let eq_name = ''
            this.equipamentos.interno.forEach(element => {
                if(element.Id == code)
                    eq_name = element.Descricao
            });
            if(eq_name == '')
            {
                this.equipamentos.externo.forEach(element => {
                    if(element.Id == code)
                        eq_name = element.Descricao
                });
            }
            return eq_name
        },
        search_for_user(id){
            
            let parent = this
            this.funcionarios.forEach(func => {

                if(func.Codigo == id){
                    parent.itemsMOInternal.funcionario = func
                    parent.searchByIdName = func.Nome
                }
            });

            this.render0++
        },  
        fillData() {    
            if(this.fields.frente_obra == null) return

            let data = {
                id: this.fields.frente_obra.SubEmpId,
                place: 'encarregado'
            };   
            
            this.$store.dispatch("listFrenteObraClasses", data)
                .then(res => {
                    this.classes = res.data;
                })  

            this.$store.dispatch("listSubEmpreitadas", data.id)
                .then(res => {
                    this.sub_empreitadas = res.data;
                })     

            let materialsData ={
                id_frente_obra: this.fields.frente_obra.SubEmpId,
                id_obra_classe: this.fields.classeID
            }

            this.$store.dispatch("getMaterials", materialsData)
                .then(res => {
                    this.sub_familias = res.data;
                })      
        },
        choosed_work(){
            this.choosedWorkFlag = true
            this.loading = true
            

            let data = {
                work_id: this.fields.obra,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'encarregado'
            }


            this.$store.dispatch("listObrasEquipamentos", data)
                .then(res => {
                    this.equipamentos = res.data;
                })  

            let dt = {
                id: this.fields.obra,
                frente: null
            }


            this.$store.dispatch("getObraFuncionarios", dt)
                .then(res => {
                    this.funcionarios = res.data;
                })  


            this.$store.dispatch("listObrasPessoal", this.fields.obra)
                .then(res => {
                    this.entidades_pessoal = res.data;
                })  

            let object_data = {
                id: this.fields.obra,
                frente: this.fields.frente_obra == null ? null : this.fields.frente_obra.ID,
                unidade: this.fields.classe == null ? this.fields.classeID : this.fields.classe.ID,
                short_id: true
            }

            this.$store.dispatch("getObrasByClassFuncionarios", object_data)
                .then(res => {
                    this.funcionarios = res.data.interno
                    this.entidades_pessoal = res.data.externo
                })

            this.loading = false
            this.workChoosed = true
        },
        fillMO() {
            if(this.fields.frente_obra == null) return
            let data = {
                id: this.fields.classeID
            };


            let dataListFrenteObra = {
                classe: this.fields.classeID,
                id: this.fields.frente_obra.SubEmpId,
                place: 'encarregado'
            }

            this.$store.dispatch("listFrenteObraEquipamentos", dataListFrenteObra)
                .then(res => {
                    this.equipamentos = res.data;
                })  


            this.$store.dispatch("listClasseFuncionariosById", data)
                .then(res => {
                    
                    this.funcionarios = res.data;
                })

           this.$store.dispatch("listObrasPessoalById", this.fields.classeID)
                .then(res => {
                    this.entidades_pessoal = res.data;
                }) 
                
            let materialsData ={
                id_frente_obra: this.fields.frente_obra.SubEmpId,
                id_obra_classe: this.fields.classeID
            }

            this.$store.dispatch("getMaterials", materialsData)
                .then(res => {
                    this.sub_familias = res.data;
                    
                })  
        },
        addFuncionario() {
            this.itemsMOInternal.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                funcionario: null,
                hn: 0,
                hext: 0,
                local: null,
                options: ""
            });
        },
        deleteFuncionario(id) {
            let index = this.itemsMOInternal.findIndex(item => item.id == id);

            this.deleteArrays.MO.push(id)
            
            this.itemsMOInternal.splice(index, 1);
        },
        addPessoal() {
            this.itemsMOExternal.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                entidade_pessoal: null,
                hn: 0,
                hext: 0,
                local: null,
                options: ""
            });
        },
        deletePessoal(id) {
            let index = this.itemsMOExternal.findIndex(item => item.id == id);

            this.deleteArrays.MO.push(id)

            this.itemsMOExternal.splice(index, 1);
        },
        addEquipamentoInterno() {
            this.itemsEquipInternal.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                equipamento: null,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        deleteEquipamentoInterno(id) {
            let index = this.itemsEquipInternal.findIndex(item => item.id == id);

            this.deleteArrays.equipment.push(id)

            this.itemsEquipInternal.splice(index, 1);
        },
        addEquipamentoExterno() {
            this.itemsEquipExternal.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                equipamento: null,
                hs: 0,
                ho: 0,
                local: null,
                options: ""
            });
        },
        deleteEquipamentoExterno(id) {
            let index = this.itemsEquipExternal.findIndex(item => item.id == id);

            this.deleteArrays.equipment.push(id)

            this.itemsEquipExternal.splice(index, 1);
        },
        addMaterial() {
            this.itemsMaterial.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                product: null,
                un: "-",
                qty: 0,
                local: null,
                options: ""
            });
        },
        deleteMaterial(id) {
            let index = this.itemsMaterial.findIndex(item => item.id == id);

            this.deleteArrays.material.push(id)

            this.itemsMaterial.splice(index, 1);
        },
        addSubEmpreitada() {
            this.itemsSubcontract.push({
                id: 'new-'+(Math.random() + 1).toString(36).substring(7),
                work: null,
                qty: 0,
                local: null,
                options: ""
            });
        },
        deleteSubEmpreitada(id) {
            let index = this.itemsSubcontract.findIndex(item => item.id == id);

            this.deleteArrays.subEmpreitadas.push(id)

            this.itemsSubcontract.splice(index, 1);
        },
        editPD() {

            this.loading = true;

            this.itemsEquipInternal.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            this.itemsEquipExternal.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            this.itemsMOInternal.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            this.itemsMOExternal.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            this.itemsMaterial.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            this.itemsSubcontract.forEach(element => {
                if(element.id.includes('new'))
                    element.id = 'new'
            });

            

            let data = {
                id: this.$route.params.id,
                SubEmpId: this.fields.frente_obra == null ? null : this.fields.frente_obra.SubEmpId,
                Codigo: this.fields.frente_obra == null ? null : this.fields.frente_obra.ObraPai,
                Data: this.fields.Data,
                maoObraInterna: this.itemsMOInternal,
                classeID: this.fields.classeID,
                maoObraExterna: this.itemsMOExternal,
                equipamentoInterno: this.itemsEquipInternal,
                equipamentoExterno: this.itemsEquipExternal,
                material: this.itemsMaterial,
                subEmpreitadas: this.itemsSubcontract,
                hash: this.$route.params.hash,
                toDelete: this.deleteArrays,
                id_user_creator: this.$route.params.id_user,
                obsEQ: this.fields.obsEQ,
                obsMO: this.fields.obsMO
            };

            if(data.Codigo == null)
                data.Codigo = this.fields.obra_codigo

            this.$store.dispatch("updatePDEnc", data)
            .then(() => {
                this.success = true;

                this.error = false;

                this.loading = false;

                /*setTimeout(() => {
                    this.$router.push("/diarios");
                }, 2000);*/
                this.fillInformationToEdit()
            }).catch(err => {

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loading = false;
            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
                text: 'Consultar Diários',
                disabled: false,
                to: '/diarios',
                exact: true,
            },
            {
                text: 'Registo Diário Encarregado',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>
::v-deep .v-data-table .v-select{
  width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-data-table .v-data-table__wrapper td{
 height: 200px !important;
}


</style>