import axios from "axios";
import router from "@/router";

export default class API
{
    static getApiUrl() {
        return process.env.VUE_APP_API_DOMAIN;
    }

    static async startRequest() {
        await API.getAxiosInstance().get(process.env.VUE_APP_API_DOMAIN + "sanctum/csrf-cookie");
    }

    static getAxiosInstance()
    {
        let instance = axios.create({
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            },
            baseURL: process.env.VUE_APP_API_DOMAIN
        });

        instance.interceptors.response.use((response) =>  response, (error) => {
            const statusCode = error.response ? error.response.status : null;

            const errorPages = {
                e401: "/login",
                e404: "/404",
                e500: "/500"
            }

            const errorPage = errorPages["e" + statusCode];

            if(errorPage != undefined) {
                router.push(errorPage);
            }

            throw error;
        });

        return instance;
    }

    

    static async get(url, data)
    {
        return this.getAxiosInstance().get(url, data);
    }

    static async post(url, data)
    {
        return this.getAxiosInstance().post(url, data);
    }

    static async put(url, data)
    {
        return this.getAxiosInstance().put(url, data);
    }

    static async patch(url, data)
    {
        return this.getAxiosInstance().patch(url, data);
    }

    static async delete(url, data)
    {
        return this.getAxiosInstance().delete(url, data);
    }
}