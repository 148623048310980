<template>
    <div class="modal-overlay" style="white-space: pre-line">
      <div class="modal">
        <h6>{{title}}</h6>
        <p>{{message}}</p>
        <v-divider class="mb-4 mt-4"></v-divider>
        <v-btn @click="$emit('yes')" color="primary" class="mr-4">Sim</v-btn>
        <v-btn @click="$emit('close-modal')" color="danger">Não</v-btn>
      </div>
      <div class="close">
      </div>
    </div>
  </template>
  
  <script>
    export default {
        props:{
            title: String,
            message: String
        }
  }
  </script>
<style scoped>

.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

</style>