<template>
    <v-card>
        <v-card-title class="text-h5 primary white--text">
        Subempreitadas
        </v-card-title>

        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Pos.
                        </th>
                        <th class="text-left">
                            Código
                        </th>
                        <th class="text-left">
                            Descrição
                        </th>
                        <th class="text-left">
                            Tipo
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item, index) in selectedWork.subempreitadas"
                    :key="item.Id"
                    >
                        <td>{{index + 1}}</td>
                        <td>{{ item.Codigo }}</td>
                        <td>{{ item.Descricao }}</td>
                        <td>{{ item.Tipo }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
        <v-spacer></v-spacer>
        
        </v-card-actions>
    </v-card>
</template>
<script>
export default{
    name:"Subempreitadas",
    props:{
        selectedWork: Object
    },
    mounted(){
        
    }
}
</script>