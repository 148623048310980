import Notifications from "@/api/Notifications";

export const notifications = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async markAsViewed(params, id) {
            return new Promise((resolve, reject) => {
                Notifications.markAsViewed(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
    }
}