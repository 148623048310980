<template>
    <div>
        <v-row justify="center">
            <v-col cols="12">
                <v-expansion-panels accordion class="elevation-0" v-if="dataSearch">
                    <v-expansion-panel dark
                    >
                        <v-expansion-panel-header color="primary">
                            <div class="font-weight-bold white--text"><v-icon dark class="mr-2">mdi-magnify</v-icon>Filtros <small>(Clique para abrir)</small></div>
                            <template v-slot:actions>
                                <v-icon color="white">
                                mdi-arrow-down-bold
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4" v-if="false"
                                        >
                                        <v-select
                                            class="mt-4"
                                            v-if="dataSearch"
                                            @change="$emit('changed-local', local_selected)"
                                            v-model="local_selected"
                                            label="Local"
                                            :items="['Web App', 'ERP']"
                                            dense
                                            prepend-icon="mdi-database"
                                            color="primary"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="4" sm="4" v-if="dataSearch">
                                        <v-select
                                            class="mt-4"
                                            v-if="dataSearch"
                                            @change="changeState"
                                            v-model="options.approved_state"
                                            label="Estado Diárias"
                                            :items="[
                                                {text: 'Todas', value: 0},
                                                {text: 'Por aprovar', value: 1},
                                                {text: 'Aprovadas administrativo', value: 2},
                                                {text: 'Totalmente aprovadas', value: 3}, 
                                            ]"
                                            dense
                                            menu-props="auto"
                                            item-text="text"
                                            item-value="value"
                                            prepend-icon="mdi-notebook"
                                            color="primary"
                                        />
                                    </v-col>
                                    <v-col cols="12">                
                                        <DateBetweenPicker
                                            :key="reloadDater"
                                            @filter-data="filterData"
                                            @reset-dates="start_date=null; end_date=null; reloadDater++; $emit('reset-data')"
                                            :start_date="start_date"
                                            :end_date="end_date"
                                            v-if="dataSearch"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <!-- EXPANDABLE TABLE -->
        <v-data-table
            v-if="expandable"
            v-bind="$attrs"
            v-on="$listeners"
            :footer-props="tableFooter"
            :options.sync="options"
            locale="pt-pt"        
            :expanded.sync="expanded"
            show-expand
            single-expand
            item-key="CreatedOn"
        >
        

        <template v-slot:item.approval_status="{item}">
            <v-row>                
                <v-col cols="12" md="auto" v-if="local_selected != 'ERP'">
                    <v-icon v-if="checkApprovalStatus(item) == 'total'" color="success">mdi-check-decagram</v-icon>
                    <span v-else class="text-caption">{{checkApprovalStatus(item)}}</span>
                </v-col>
                <v-col cols="12" md="auto">
                    <v-icon small v-if="item.webAppLinkHash != null || item.CreatedBy == 'partesdiarias'" color="primary">mdi-cloud</v-icon>
                    <v-icon small v-else>mdi-laser-pointer</v-icon>
                </v-col>
                <v-col cols="12" md="auto">
                    <v-icon small class="ml-2" :color="item.creationRole == 'manobrador' ? 'warning' : 'black'">{{getIcon(item.creationRole)}}</v-icon>
                </v-col>
                <v-col cols="12" md="auto" v-if="local_selected != 'ERP'">
                    <v-icon color="success" v-if="item.sync">mdi-sync</v-icon>
                    <v-icon v-else>mdi-sync-off</v-icon>
                </v-col>
            </v-row>
        </template>


            <template v-slot:item.Data="{ item }">
                {{formatData(item.Data, false)}}
            </template>

            <template v-slot:item.Responsavel="{ item }">
                {{ item.responsavel == null ? '-' : item.responsavel }}
            </template>

            <template v-slot:item.CreatedOn="{ item }">
                {{formatData(item.CreatedOn, true)}}
            </template>

            

            <template v-slot:item.actions="{item}">
                <Tooltip
                    class="my-4 mr-4"
                    color="primary"
                    text="Visualizar"
                    top
                >
                    <v-icon
                        small
                        color="primary"
                        class="mr-2"
                        v-if="canView"
                        @click="viewed(item)"
                    >
                        mdi-eye
                    </v-icon>
                </Tooltip>

                <slot name="options" :item="item"/>

                <Tooltip
                    class="my-4 mr-4"
                    color="error"
                    text="Remover"
                    top
                    v-if="canDelete && !item.is_super"
                >
                    <v-icon
                        small
                        color="error"
                        class="mr-2"
                        @click="tryDeletion(item)"
                    >
                        mdi-delete
                    </v-icon>
                </Tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div class="row sp-details">
                    <v-row>
                            <v-col cols="12" md="4">
                                <v-banner
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-clipboard-text-clock
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Criada a:</p>
                                    <p>{{expandableFormatData(item.CreatedOn)}}</p>
                                </v-banner> 
                                <v-banner
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-swap-horizontal
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Modificada a:</p>
                                    <p>{{expandableFormatData(item.ModifiedOn)}}</p>
                                </v-banner>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-banner
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-account
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Responsável:</p>
                                    <p v-if="item.current_approval != null">{{ item.current_approval.user }}</p>
                                    <p v-else>-</p>
                                </v-banner>     
                                <v-banner
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-swap-horizontal
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Modificada por:</p>
                                    <p>{{ item.modified_user == null ? '-' : item.modified_user.name }}</p>
                                </v-banner>                       
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-banner
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-list-status
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Aprovação:</p>
                                    <p>{{calculateStatus(item)}}</p>
                                </v-banner>
                                <v-banner  v-if="item.current_approval != null"
                                    single-line
                                >
                                <v-icon
                                    slot="icon"
                                    color="warning"
                                    small
                                    >
                                    mdi-list-status
                                    </v-icon>
                                    <p class="mt-4 font-weight-medium">Por:</p>
                                    <p>{{item.current_approval.user}}</p>
                                </v-banner>                                  
                            </v-col>
                            
                    </v-row>                   
                    </div>
                </td>
            </template>
        </v-data-table> 
        <!-- END EXPANDABLE TABLE -->



        
        <!-- TABLE -->

        <v-data-table
            v-else
            v-bind="$attrs"
            v-on="$listeners"
            :footer-props="tableFooter"
            :options.sync="options"
            locale="pt-pt"        
        >
        

        <template v-slot:item.Place="{ item }">
            <v-icon small v-if="item.webAppLinkHash != null || item.CreatedBy == 'partesdiarias'" color="primary">mdi-cloud</v-icon>
            <v-icon small v-else>mdi-laser-pointer</v-icon>
            
            <v-icon small class="ml-2" :color="item.creationRole == 'manobrador' ? 'warning' : 'black'">{{getIcon(item.creationRole)}}</v-icon>
            <v-icon v-if="item.WebappApproved == 1" small class="ml-2" color="success">mdi-check-decagram</v-icon>
            <v-icon v-if="item.current_approval != null && item.current_approval.level == 1" small class="ml-2" color="red">mdi-star-half-full</v-icon>
        </template>


            <template v-slot:item.Data="{ item }">
                {{formatData(item.Data, false)}}
            </template>

            <template v-slot:item.CreatedOn="{ item }">
                {{formatData(item.CreatedOn, true)}}
            </template>

            <template v-slot:item.roles="{item}">
                <slot name="roles" :item="item"/>
            </template>

            

            <template v-slot:item.actions="{item}">
                <Tooltip
                    class="my-4 mr-4"
                    color="primary"
                    text="Visualizar"
                    top
                >
                    <v-icon
                        small
                        color="primary"
                        class="mr-2"
                        v-if="canView"
                        @click="viewed(item)"
                    >
                        mdi-eye
                    </v-icon>
                </Tooltip>

                <slot name="options" :item="item"/>

                <Tooltip
                    class="my-4 mr-4"
                    color="error"
                    text="Remover"
                    top
                    v-if="canDelete && !item.is_super"
                >
                    <v-icon
                        small
                        color="error"
                        class="mr-2"
                        @click="tryDeletion(item)"
                    >
                        mdi-delete
                    </v-icon>
                </Tooltip>
            </template>
        </v-data-table> 
        <!-- END TABLE -->

        <!--<WarningSnackbar @okClicked="deleted" message="Deseja Remover o Registo!" v-model="askForConfirmation"/>
-->
        <DialogConfirmation 
            v-if="askForConfirmation"
            :title="'Remover registo'" 
            type="warning" 
            :opened="askForConfirmation" 
            :elevation="0"
            @on-submit="deleted" 
            @on-cancel="askForConfirmation = false">
                Deseja remover o registo?
        </DialogConfirmation> 

        <SuccessSnackbar message="Ação bem sucedida" v-model="success"/>
    </div>   
</template>

<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"

import DateBetweenPicker from "@/components/UI/Inputs/DateBetweenPicker"
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';

import WarningSnackbar from "@/components/UI/Snackbars/WarningSnackbar";
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";


export default {
    components: {
        Tooltip,
        DateBetweenPicker,
        WarningSnackbar,
        DateBetweenPicker,
        DialogConfirmation,
        SuccessSnackbar
    },
    watch: {
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.optionsChanged()}, 10);
            },
            deep: true,
        },
    },
    props: {
        lStorage: null,
        canView: Boolean,
        canDelete: Boolean,
        dataSearch:{
            type: Boolean,
            default: true
        },
        expandable:{
            type:Boolean,
            default:false
        }
    },
    data: () => ({
        reloadDater: 9000,
        success: false,
        expanded: [],
        local_selected: "Web App",
        options: {
            approved_state: 0
        },
        tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
        },
        askForConfirmation: false,
        itemToDelete: null,
        start_date: null,
        end_date: null
    }),
    mounted(){
        if(this.lStorage != null){
            if(localStorage[this.lStorage] != undefined){

                let data = JSON.parse(localStorage[this.lStorage]);

                this.filter = data.filter;

                this.options = data.options;

                if(this.options != undefined)
                    this.start_date = this.options.start_date

                if(this.options != undefined)
                    this.end_date = this.options.end_date

                if(this.options != undefined)
                    this.local_selected = this.options.local

                if(this.options != undefined){
                    if(typeof this.options.approved_state == 'object'){
                        this.options.approved_state = this.options.approved_state.approved_state
                    }
                }

            }
        }
    },
    methods: {
        checkApprovalStatus(item){
            if(item.creationRole == 'encarregado' && item.level_needed_approve == '3'){
                item.level_needed_approve = 2
                if(item.current_approval != null){
                    if(item.current_approval.level == "1")
                        item.current_approval.level = 0         
                    if(item.current_approval.level == "2")
                        item.current_approval.level = 1   
                }      
            }

            //console.log(item)

            if(item.current_approval == null)
                return '0 / ' + item.level_needed_approve

            if(item.current_approval.level >= item.level_needed_approve)
                return 'total'
            
            return item.current_approval.level + ' / ' + item.level_needed_approve
        },
        checkDiaryApprovance(item){
            if(item.current_approval == null)
                return false

            if(item.current_approval.level == 2)
                return true
        },
        calculateStatus(i){
            if(i.current_approval == null)
                return "N/D"
                
            
            return new Date(i.current_approval.created_at).toLocaleString("pt-PT")
            
        },
        getIcon(creationRole){
            if(creationRole == null)
                return ''
            if(creationRole == 'manobrador')
                return 'mdi-steering'
            else return 'mdi-account-hard-hat'
        },
        formatData(value, withHours){
            if(!withHours)
                return new Date(value).toLocaleDateString("pt-PT")

            return new Date(value).toLocaleString("pt-PT")
        },
        expandableFormatData(v){
            return new Date(v).toLocaleString("pt-PT")
        },
        filterData(data1, data2){
            this.$emit('filter-data', data1, data2)
        },
        tryDeletion(item) {
            this.askForConfirmation = true;

            this.itemToDelete = item;
        },
        viewed(item) {
            this.$emit("viewed", item);
        },
        deleted() {
            this.$emit("deleted", this.itemToDelete);
            this.askForConfirmation = false
            this.success = true
        },
        optionsChanged() {
            this.$emit("optionsChanged", this.options);
        },
        changeState(){
            this.$emit("changeState", this.options);
        }
    }
}
</script>

<style scoped>

</style>