<template>
    <v-snackbar
        v-bind="$attrs"
        v-on="$listeners"
        centered
        color="warning"
        timeout="-1"
        multi-line
    >
        {{message || "Sucesso!"}}

        <div>
            <Button
                class="mb-2 mt-2 mr-2"
                @click="cancelClicked"
                color="secondary"
                small
            >
                Cancelar
            </Button>

            <Button
                class="mb-2 mt-2 ml-2"
                @click="okClicked"
                color="primary"
                small
            >
                Ok
            </Button>
        </div>
    </v-snackbar>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
    components: {
        Button
    },
    props: {
        message: String
    },
    methods: {
        okClicked: function() {
            this.$emit("okClicked");

            this.close();
        },
        cancelClicked: function() {
            this.$emit("cancelClicked");

            this.close();
        },
        close: function() {
            this.$emit("input", false)
        },
    }
}
</script>