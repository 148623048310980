import Equipamentos from "@/api/Equipamentos";

export const equipamentos = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async getLastHistory(params, id) {
            return new Promise((resolve, reject) => {
                Equipamentos.getLastHistory(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listEquipamentos() {
            return new Promise((resolve, reject) => {
                Equipamentos.all()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async listEquipamentosUnassigned(){
            return new Promise((resolve, reject) => {
                Equipamentos.allUnassigned()
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });            
        },
        async createEquipamentoObra(params, fields){
            return new Promise((resolve, reject) => {
                Equipamentos.createEquipamentoObra(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async removeEquipamentoObra(params, fields){
            return new Promise((resolve, reject) => {
                Equipamentos.removeEquipamentoObra(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }, 
        async getUnassigned(params, fields){
            return new Promise((resolve, reject) => {
                Equipamentos.getUnassigned(fields)
                .then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            })
        }
    }
}