import API from "./API";

export default class PDMO extends API
{
    static async search(filter) {
        return this.get("api/admin/consulting/mo",{params: filter});
    }
    static async getMaoObra(params) {
        return this.get("api/admin/pdmo/" + params.ID, {params: params});
    }
}