import Testings from "@/api/Testings";

export const testings = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async sendEmailVerify(params, fields) {
            return new Promise((resolve, reject) => {
                Testings.sendEmailVerify(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async sendEmailPassword(params, fields) {
            return new Promise((resolve, reject) => {
                Testings.sendEmailPassword(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async sendPDDiary(params, fields) {
            return new Promise((resolve, reject) => {
                Testings.sendPDDiary(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async sendPDMissing(params, fields) {
            return new Promise((resolve, reject) => {
                Testings.sendPDMissing(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}