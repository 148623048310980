import { render, staticRenderFns } from "./DialogV2.vue?vue&type=template&id=5c520864&scoped=true"
import script from "./DialogV2.vue?vue&type=script&lang=js"
export * from "./DialogV2.vue?vue&type=script&lang=js"
import style0 from "./DialogV2.vue?vue&type=style&index=0&id=5c520864&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c520864",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardActions,VCardText,VDialog,VDivider})
