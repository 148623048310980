import API from "./API";

export default class Alerts extends API
{
    static async getAlerts() {
        return this.get("api/admin/alerts");
    }

}

