import API from "./API";

export default class Teams extends API
{
    static async search(filter) {
        return this.get("api/admin/teams",{params: filter});
    }

    static async all(filter) {
        return this.get("api/admin/teams/list",{params: filter});
    }

    static async create(params) {
        return this.post("api/admin/teams", params);
    }

    static async nextReference() {
        return this.get("api/admin/teams/next-reference");
    }

    static async find(id) {
        return this.get("api/admin/teams/" + id);
    }

    static async deleteTeam(id) {
        return this.delete("api/admin/teams/" + id);
    }

    static async update(params, id) {
        return this.put("api/admin/teams/" + id,params);
    }

    static async getTeam(id){
        return this.get("api/admin/teams/" + id);
    }
}