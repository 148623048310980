<template>
    <v-container fluid>
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="d-none d-lg-block warning white--text">
                        Subempreitadas
                    </v-card-title>
                    <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                        Subempreitadas
                    </v-card-title>
                    <v-card-text :key="reloader">
                        <v-simple-table class="d-none d-lg-block" fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-if="aggregator" width="5%"></th>
                                        <th class="text-left" :width="aggregator ? '60%' : '40%'">
                                            Trabalho
                                        </th>
                                        <th class="text-left" width="7%">
                                            QTD
                                        </th>
                                        <th class="text-left" width="5%">
                                            Nota
                                        </th>
                                        <th class="text-left" width="10%">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in fields.subempreitadas" :key="item.id"
                                    >
                                        <td v-if="aggregator">
                                            <v-icon v-if="lineTotalApproved(item)" color="success">mdi-check-decagram</v-icon>
                                            <span v-else>{{lineApprovalState(item)}}</span>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="subempreitada" name="subempreitada" rules="required">
                                                <v-autocomplete
                                                    class="mt-4"
                                                    outlined dense
                                                    :items="subempreitadas"
                                                    :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                                    item-value="Id"
                                                    label="Subempreitada"
                                                    :error-messages="errors"
                                                    v-model="item.subempreitada"
                                                />
                                            </validation-provider>
                                        </td>
                                            <v-text-field 
                                                class="mt-4"
                                                label="QTD"
                                                outlined dense
                                                placeholder="Insira qtd."
                                                v-model="item.qty"
                                            />
                                        <td>
                                            <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab x-small @click="$emit('nota', item, 'subempreitada')">
                                                <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                <v-badge
                                                    v-else
                                                    color="orange"
                                                    dot
                                                    overlap
                                                >
                                                    <v-icon>mdi-note-plus</v-icon>
                                                </v-badge>
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-btn
                                                v-if="!aggregator"
                                                dark
                                                small
                                                color="error"
                                                @click="deleteSubempreitada(item.id)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>    
                                            <v-btn
                                                v-if="aggregator"
                                                fab
                                                dark
                                                x-small
                                                outlined
                                                color="warning"
                                                @click="edit(item)"
                                            >
                                                <v-icon dark>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>                         
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="fields.subempreitadas"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none"
                        >
                        <template v-slot:default="{ items }">
                            <v-row>
                                <v-col
                                    v-for="item in fields.subempreitadas"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                        <v-list
                                            dense
                                        >
                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <validation-provider v-slot="{ errors }" vid="subempreitada" name="subempreitada" rules="required">
                                                        <v-autocomplete
                                                            class="mt-4"
                                                            outlined dense
                                                            :items="subempreitadas"
                                                            :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                                            item-value="Id"
                                                            label="Subempreitada"
                                                            :error-messages="errors"
                                                            v-model="item.subempreitada"
                                                        />
                                                    </validation-provider>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-text-field 
                                                        class="mt-4"
                                                        label="QTD"
                                                        outlined dense
                                                        placeholder="Insira qtd."
                                                        v-model="item.qty"
                                                    />
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="$emit('nota', item, 'subempreitada')">
                                                        <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                        <v-badge
                                                            v-else
                                                            color="orange"
                                                            dot
                                                            overlap
                                                        >
                                                            <v-icon>mdi-note-plus</v-icon>
                                                        </v-badge>
                                                    </v-btn>
                                                </v-list-item-content>                                                                            
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content class="align-center">
                                                    <v-btn
                                                        v-if="!aggregator"
                                                        dark
                                                        small
                                                        color="error"
                                                        @click="deleteSubempreitada(item.id)"
                                                        >
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>    
                                                    <v-btn
                                                        v-if="aggregator"
                                                        fab
                                                        dark
                                                        x-small
                                                        outlined
                                                        color="warning"
                                                        @click="edit(item)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>             
                                                </v-list-item-content>                                                                            
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        </v-data-iterator>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" align="center"  v-if="newLines">
                <v-btn fab @click="addSubempreitada" class="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SubEmpreitada from "@/api/SubEmpreitada.js"
import dayjs from 'dayjs'

export default{
    name:"Subcontractors",
    props:{
        newLines: {
            typeof: Boolean,
            default: true
        },
        aggregator: {
            typeof: Boolean,
            default: false
        },
        obra_selecionada:{
            typeof: Object,
            default: null
        },
        fields: Object
    },
    data(){
        return{
            subempreitadas: [],
            loading: true,
            reloader: 0
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        lineApprovalState(item){            
            if(this.obra_selecionada == null)
                return null;
                

            let obra_nivel = this.obra_selecionada.approval_level == null ? 2 : this.obra_selecionada.approval_level
            return (item.approval == null ? 0 : item.approval) + '/' + obra_nivel
        },
        lineTotalApproved(item){  
            if(this.obra_selecionada == null)
                return false;

            if(item.approval == null)
                return false
                console.log(this.obra_selecionada)
                
            if(parseInt(this.obra_selecionada.obra.approval_level) == parseInt(item.approval))
                return true

            return false
        },
        edit(item){
            window.open(process.env.VUE_APP_API_URL + '/diarios/encarregado/editar/' + item.webAppLinkHash + "/" + item.user_id, '_blank')
        },
        deleteSubempreitada(id){
            let pos = -1    
            let i = 0
            this.fields.subempreitadas.forEach(element => {
                if(element.id == id){
                    this.fields.deleteSubempreitada.push(id)
                    pos = i
                }

                i++
            });

            this.fields.subempreitadas.splice(pos, 1)
            this.reloader++
        },
        addSubempreitada(){
            this.fields.subempreitadas.push({
                id: dayjs().unix() +'-new',
                subempreitada: null,
                qty: 0,
                local: null,
                options: ""
            });
            this.reloader++
        },
        fillBaseData(){
            SubEmpreitada.all(this.fields.obra_id).then((resp)    =>  {
                this.subempreitadas = resp.data
                
                this.loading = false
            })

        }
    }
}
</script>