import API from "./API";

export default class Preventivas extends API
{
    static async search(params ){
        return this.get("api/admin/preventivas", {params:params});
    }

    static async find(id_equipamento) {
        return this.get("api/admin/preventivas/equipamento/" + id_equipamento);
    }

}