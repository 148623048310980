<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">
          <v-breadcrumbs
              large
              :items="crumbs"
              divider="/"
              class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-account-multiple</v-icon> <span>Consultar Utilizadores</span>
          </h1>
    
    
          <div style="position:relative">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="filter.code"
                        dense outlined
                        prepend-inner-icon="mdi-account-details"
                        label="Código/Nome"
                        clearable
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn 
                      class="success"
                      @click="searchUsers"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn 
                      class="error"
                      @click="resetUsers"
                    >
                      Limpar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-container>
              <v-speed-dial
                absolute
                v-model="speedDial"
                top
                right
                direction="bottom"
                :open-on-hover="true"
              >
                <template v-slot:activator>
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs" v-on="on"
                        v-model="speedDial"
                        color="primary"
                        dark
                        x-large
                        fab
                      >
                        <v-icon v-if="speedDial">
                          mdi-close
                        </v-icon>
                        <v-icon v-else>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Opções</span>
                  </v-tooltip>
                </template>
                <v-tooltip right color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      fab
                      dark
                      small
                      color="warning"
                      large
                      @click="searching = true"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <span>Pesquisar</span>
                </v-tooltip>                
              </v-speed-dial>
            </v-container>
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
              locale="pt-pt"
            >
    
              <template v-slot:item.is_active="{ item }">
                <v-container class="px-0" fluid>
                  <v-row align="center"
                      justify="center">
                      <v-col>
                        <v-switch
                          style="margin-top:0px"
                          :disabled="!$store.getters.hasPermission(['super', 'users.update']) || item.username == 'super'"
                          v-model="item.is_active"
                          color="success"
                          @change="toggleUser(item)"
                          hide-details
                        ></v-switch>
                      </v-col>
                  </v-row>
                </v-container>
              </template>
    
              <template v-slot:item.roles="{item}">
                <div v-for="r in item.roles" v-bind:key="r.id">
                  <h6>{{ r.display_name }}</h6>
                </div>
              </template>
    
              <template v-slot:item.actions="{item}">
                <IconView
                  class="mr-2"
                  @click="viewUser(item)"
                />
                <Tooltip v-if="false"
                    class="my-4 mr-4"
                    color="secondary"
                    text="Gerir Obras"
                    top
                >
                    <v-icon
                        small
                        color="secondary"
                        class="mr-2"
                        v-if="$store.getters.hasPermission(['super', 'works.read'])"
                        @click="$router.push('/super/utilizadores/' + item.id + '/obras')"
                    >
                        mdi-cog
                    </v-icon>
                </Tooltip>
                
                <IconRemove
                  v-if="$store.getters.hasPermission(['super', 'users.delete'])"
                  class="mr-2"
                  @on-submit="deleteUser(item)"
                />
              </template>
            </v-data-table>
          </div>
          <v-navigation-drawer
              fixed
              right
              v-model="searching"
              hide-overlay
              width="520"
              class="pt-6"
            >
            <v-list>
              <v-list-item>
                <v-btn fab small color="secondary" @click="searching = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
    
                <v-btn absolute right small color="error" @click="resetUsers">
                  Limpar campos
                </v-btn>
              </v-list-item>
            </v-list>
            <v-list class="pt-10">
              <v-form @submit.prevent="searchUsers">
                
                <v-list-item>
                  <v-text-field
                    v-model="filter.name"
                    prepend-inner-icon="mdi-account-details"
                    label="Nome"
                    clearable
                  ></v-text-field>
                </v-list-item>
                
                <v-list-item>
                  <v-text-field
                    v-model="filter.code"
                    prepend-inner-icon="mdi-account-details"
                    label="Código"
                    clearable
                  ></v-text-field>
                </v-list-item>
    
                <v-list-item>
                  <v-text-field
                    v-model="filter.email"
                    prepend-inner-icon="mdi-at"
                    label="email"
                    type="email"
                    clearable
                  ></v-text-field>
                </v-list-item>
    
                <v-list-item>
                  <v-text-field
                    v-model="filter.phone_number"
                    prepend-inner-icon="mdi-phone"
                    label="Telefone"
                    clearable
                  ></v-text-field>
                </v-list-item>
    
                <v-list-item>
                  <v-switch
                    style="margin-top:0px"
                    label="Ativos"
                    v-model="filter.is_active"
                    color="success"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-switch>
                </v-list-item>
    
                <v-list-item>
                  <multiple-select
                      v-model="filter.role_ids"
                      :items="roles"
                      item-text="display_name"
                      item-value="id"
                      menu-props="auto"
                      label="Roles"
                      prepend-inner-icon="mdi-shield-account"
                  ></multiple-select>
                </v-list-item>
    
                <v-row
                  class="mt-10"
                  align="center"
                  justify="space-around"
                >
                <v-btn
                  color="primary"
                  type="submit"
                >
                  Pesquisar
                </v-btn>
                </v-row>
              </v-form>
            </v-list>
          </v-navigation-drawer>
        
        </v-col>
      </v-row>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/UI/IconRemove.vue';
  import IconView from '@/components/UI/IconView.vue';
  import User from "@/api/User.js";
  import Role from "@/api/Role.js";
  import MultipleSelect from '@/components/UI/MultipleSelect.vue';
  import Tooltip from "@/components/UI/Tooltips/Tooltip"
  
  export default {
    components: {
      IconRemove,
      IconView,
      MultipleSelect,
      Tooltip
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchUsers()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      items: [],
      total: 0,
      loading: true,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        name: null,
        email:null,
        username:null,
        phone_number:null,
        is_active:1,
        role_ids:[],
      },
      roles:[]
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "users.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
  
    },
    methods:{
      fillBaseData() {       
        if(localStorage["users-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["users-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
        
        Role.all().then(({data}) => {
          this.roles = data;
        });

      },
      searchUsers(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["users-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        User.search(request).then(response => {
  
          let data = response.data;
  this.items = data.data
          this.total = data.total;
  
        
  
  
          this.loading = false;
  
        });
      },
      resetUsers()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchUsers();
      },
      viewUser(user){
        this.$router.push("/super/utilizadores/" + user.id);
      },
      deleteUser(item){
        this.$store.dispatch("deleteUser", item.id)
            .then(response => {

                this.success = true;

                this.searchUsers(this.options);

        });
      },
      toggleUser(item) {
        User.updateActivation(item)
          .then(() => {
            this.searchUsers();
          });
      }
    },
    computed: {
      headers(){
        let he = [
            {
                text: 'Código',
                sortable: false,
                value: 'funcionario.Codigo',
            },
            {
                text: 'Nome',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Username',
                sortable: false,
                value: 'username',
            },
            {
                text: 'Email',
                sortable: false,
                value: 'email',
            },
            {
                text: 'Roles',
                sortable: false,
                value: 'roles',
            },
            { 
                text: 'Opções', 
                value: 'actions', 
                sortable: false 
            },
        ]
        return he
      },
      tableFooter(){
        return {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
        }
      }, 
      crumbs: function() {
        return [
          {
            text: 'Utilizadores',
            disabled: true,
            exact: true,
          },
          {
            text: 'Consultar utilizadores',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  