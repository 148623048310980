<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card class="ma-0 pa-0">
            <v-card-title class="primary white--text">
                Pré-visualizar diária
            </v-card-title>
            <v-card-text>
                <v-row class="mt-4 text-body-1 font-weight-bold black--text" align="center" justify="center" v-if="!loading">
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{diary.data}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <span>{{diary.work_name}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center" v-if="diary.frente_name != null">
                        <span>{{diary.frente_name}}</span>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center" v-if="diary.classe_name != null">
                        <span>{{diary.classe_name}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-simple-table class="d-none d-lg-block">
                    <template v-slot:default>
                        <thead class="primary">
                            <tr>
                                <th class="text-start white--text text-caption font-weight-bold">
                                    Unidade Obra
                                </th>
                                <th class="text-start white--text text-caption font-weight-bold">
                                    Rendimento
                                </th>
                                <th class="text-start white--text text-caption font-weight-bold">
                                    Data Inicio
                                </th>
                                <th class="text-start white--text text-caption font-weight-bold">
                                    Data Fim
                                </th>
                                <th class="text-start white--text text-caption font-weight-bold">
                                    Notas
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in diary.lines" v-bind:key="index">
                                <td class="font-weight-bold">
                                    {{item.unidade_obra}}                                    
                                </td>
                                <td>
                                    {{item.rendimento}}
                                </td>
                                <td>
                                    {{item.data_inicio}}
                                </td>
                                <td>
                                    {{item.data_fim}}
                                </td>
                                <td>
                                    {{item.notas}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import DiaryKPI from "@/api/DiaryKPI.js";

export default{
    name: "PreviewKPIDiary",
    props:{
        diary_id: String
    },
    data(){
        return{
            loading: true,
            diary: null
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            DiaryKPI.preview(this.diary_id).then((resp) =>  {
                let prev_diary = resp.data
                this.diary = prev_diary

                this.loading = false
            })
        }
    }
}
</script>