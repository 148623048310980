<template>
    <v-dialog v-model="showDialog"
        width="50%">
        <v-card class="elevation-0 ma-0 pa-0">
            <v-card-title class="primary white--text">
                {{title}}
            </v-card-title>
            <v-card-text>
                <div class="mt-4" v-html="feedback"></div>
            </v-card-text>
            <v-card-actions>
                <v-btn style="margin: auto; display: block;" class="success" @click="$emit('close')">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default{
    name:"FeedbackModal",
    props:{
        title: String,
        feedback: String
    },
    data(){
        return{
            showDialog: true,
        }
    }
}
</script>