<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>Registo de Garantia</span>
        </h1>
  
        <div class="mt-16">
          <Guarantee method="create"></Guarantee>
        </div>
      </v-col>
    </v-row>
    </section>
  </template>
  <script>
  import Guarantee from '@/components/guarantees/Guarantee'
  
  export default {
    components: {
        Guarantee
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "guarantees.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de garantias',
            disabled: false,
            to: '/garantias/obras',
            exact: true,
          },
          {
            text: 'Registo de Garantia',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  