import API from "./API";

export default class PDEq extends API
{

    static async search(filter) {
        return this.get("api/admin/consulting/eq",{params: filter});
    }

    static async create(fields) {
        return this.post("api/admin/pdeq", fields);
    }

    static async updatePD(fields, id) {
        return this.put("api/admin/pdeq/" + id, fields);
    }

    static async deletePD(data) {
        return this.delete("api/admin/pdeq/" + data.id, {params: data});
    }

    static async getPD(params) {
        return this.get("api/admin/pdeq/" + params.ID, {params: params});
    }

    static async deleteEquipmentLine(id){
        return this.delete("api/admin/pdeq/equipment_line/" + id);
    }
}