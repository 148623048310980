import Tickets from "@/api/Tickets";

export const tickets = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async createTicket(params, fields) {
            return new Promise((resolve, reject) => {
                Tickets.create(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}