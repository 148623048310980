import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MiniAlert from '@/components/Alerts/MiniAlerts'
import EncryptValue from '@/plugins/encrypt'
import msal from 'vue-msal'
import VueCookies from 'vue-cookies'

import { ValidationObserver, ValidationProvider} from 'vee-validate';
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.prototype.$encryptValue = EncryptValue

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);
//Comments
Vue.use('MiniAlert', MiniAlert)

Vue.use(VueCookies, { expires: '7d'})

Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT, //'94fc18e1-318b-4282-bba6-ccd4fa7b1bdd'
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
