<template>
    <v-app-bar app dense>
        <v-app-bar-nav-icon @click="propMini" v-if="miniActivate"></v-app-bar-nav-icon>

        <Transition>
            <v-toolbar-title class="primary--text" v-show="isMini">
                <router-link to="/">
                    <v-avatar height="28" width="80" tile class="mt-2 mb-2">
                        <img src="@/assets/fundo_conduril.png">
                    </v-avatar>
                </router-link>
            </v-toolbar-title>
        </Transition>

        <v-spacer></v-spacer>

        <!-- Sino de notificação com animação -->
        <v-btn icon :color="alertHasData ? 'orange darken-2' : 'danger'" 
               elevation @click="notificationDialog=true"
               :class="{ 'bell-animation': alertHasData && showBellAnimation }">
            <v-icon v-if="!alertHasData">mdi-bell</v-icon>
            <v-icon v-else>mdi-bell-badge</v-icon>
        </v-btn>

        <v-dialog
            transition="dialog-top-transition"
            v-model="notificationDialog"
            max-width="800px"
            scrollable
        >
            <NotificationList @refresh="refreshHeader" v-if="notificationDialog" :messages="alerts"/>
        </v-dialog>

        <v-btn link to="/perfil" icon color="primary">
          <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-btn icon color="error" @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<style>
/* Animação para o sino de notificação */
@keyframes bellSwingBlink {
    0% { transform: rotate(0deg); opacity: 1; }
    25% { transform: rotate(-30deg); opacity: 0.5; }
    50% { transform: rotate(0deg); opacity: 1; }
    75% { transform: rotate(30deg); opacity: 0.5; }
    100% { transform: rotate(0deg); opacity: 1; }
}

/* Classe que ativa a animação */
.bell-animation {
    animation: bellSwingBlink 0.5s ease-in-out 6; /* 3 segundos totais (0.5s * 6) */
}
</style>

<script>
import NotificationList from "@/components/UI/Modals/NotificationList";
export default {
    components: {
        NotificationList
    },
    props: {
        isMini: Boolean
    },
    data() {
        return {
            alerts: [],
            alertHasData: false,
            notificationDialog: false,
            miniActivate: true,
            showBellAnimation: true,
        };
    },
    mounted() {
        this.getAlerts();
        // Desativa a animação após alguns segundos
        setTimeout(() => {
            this.showBellAnimation = false;
        }, 3000); // 3 segundos
    },
    methods: {
        changeMiniDeactivate() {
            this.miniActivate = false;
        },
        changeMiniActivate() {
            this.miniActivate = true;
        },
        refreshHeader() {
            this.getAlerts();
            this.notificationDialog = false;
            this.notificationDialog = true;
        },
        getAlerts() {
            this.$store.dispatch("getAlerts")
                .then((res) => {
                    this.success = true;
                    this.alerts = [];

                    if (res.data.lastCreationDataExpired == true) {
                        this.alerts.push({
                            id: 'ND',
                            title: 'Criação de PD',
                            subtitle: 'Sem atividade',
                            encarregado: 'System',
                            text: 'Não são encontrados registos de partes diárias há muito tempo',
                            diary_creation_role: null,
                            extraLink: null,
                            diaria: null,
                            id_user_creator: null
                        });
                    }

                    let notifications = res.data.notifications;
                    if (notifications.length >= 1) {
                        notifications.forEach(not => {
                            this.alerts.push({
                                id: not.ns_id,
                                title: not.title,
                                subtitle: '',
                                encarregado: not.creationRole,
                                diary_creation_role: not.diary_creation_role,
                                text: not.descricao,
                                extraLink: not.pd_id + '/' + not.webAppHashLink,
                                diaria: not.diaria,
                                id_user_creator: not.id_user_creator
                            });
                        });
                    }

                    if (this.alerts.length >= 1)
                        this.alertHasData = true;

                    this.error = false;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        propMini: function() {
            this.$emit("isMini", !this.isMini);
        },
        logout: function() {
            this.$store.dispatch("logout", this.$store.state.user)
                .then(response => {
                    this.$router.push("/login");
                })
                .catch(err => {
                    this.$router.push("/login");
                });
        }
    }
}
</script>
