<template>
    <section class="mt-16">
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">    
                <h2 class="primary--text">Listagem de Articulado</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :server-items-length="total"
                    :items="items"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    class="elevation-1"
                >
                <template v-slot:item.actions="{item}">
                    <v-row justify="center">
                        <v-col cols="12" md="auto">
                            <v-btn
                                title="Ver Articulado"
                                fab
                                dark
                                x-small
                                outlined
                                color="warning"
                                @click="viewArticulado(item)"
                            >
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn
                                title="Exportar como Excel"
                                fab
                                dark
                                x-small
                                outlined
                                color="success"
                                @click="startExport(item.id)"
                            >
                                <v-icon dark>
                                    mdi-export
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="auto" v-if="canDelete(item)">
                        
                            <IconRemove 
                                v-if="canDelete(item)"
                                outlined
                                color="error"
                                @on-submit="deleteArticulado(item)"
                            />
                    
                        </v-col>
                    </v-row>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </section>                  
</template>
<script>
import PaginatedTable from "@/components/UI/Tables/PaginatedTable"
import DatesPicker from "@/components/UI/Modals/DatesPicker.vue";
import KPIS from '@/api/KPIS.js'
import IconRemove from "@/components/UI/IconRemove.vue";

export default {
    components: {
        PaginatedTable,
        DatesPicker,
        IconRemove
    },
    watch:{
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchArticulado()}, 10);
            },
            deep: true,
        },
    },
    data: () => ({
        chooseDate: false,
        loading: true,
        items: [],
        headers: [
            {
                text: "Obra",
                align: "center",
                value: "obra.Descricao",
                align: "start"
            },
            {
                text: "Data importação",
                align: "center",
                value: "created_at",
                align: "start"
            },
            {
                text: "Opções",
                align: "center",
                value: "actions",
                align: "center"
            }
        ],
        tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
        },
        filter:{},
        options: {},        
        total: 0,
    }),
    mounted(){
        
    },
    methods:{  
        async remove(id){

        },
        canDelete(item){
            // if(!this.$store.getters.hasPermission('super', 'diaries.delete'))
            //     return false;

            // if(item.webAppLinkHash == null)
            //     return false

            // if(item.sync == true)
            //     return false;

             return true;
        },      
        async deleteArticulado(item){
            console.log(item.id);

            await KPIS.deleteMQTHeader(item.id)
            .then(response => {
                this.success = true;
                this.searchArticulado(this.options);
            });
            
        }, 
        async startExport(articuladoId){
            const timestamp = new Date().getTime();
            let params = {
                init:timestamp,
                end: timestamp,
                extension: "extension",
                secondOption:"test",
                type: "MQT",
                id: articuladoId
            }
            // Exportation.export_file(ob)
            await this.$store.dispatch("export_file", params)
            .then((res) => {
                this.success = true;
                window.open(res.data.url, '_blank')
                this.error = false;
                this.loading = false;
            }).catch(err => {
                console.log(err)
            });
        },
        viewArticulado(a){
            this.$router.push("/kpi/list/articulado/" + a.id);
        },      
        deleteRow(line){
            /*this.$store.dispatch("deleteMaterials", line.identificator)
            .then(() => {
                this.success = true;

                this.error = false;

                this.searchMaterials(this.options)

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });*/
        },
        searchArticulado(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loading = true;

            let filter = {...this.filter};
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };
            
            
            Object.assign(request, filter);

            localStorage["search-articulados-" + this.$store.state.user.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            KPIS.search(request).then(response => {

                let data = response.data;

                this.total = data.total;

                this.items = data.data.map((item) => {

                    this.searching = false;

                    return item;
                });

                this.loading = false;

            });
            
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Listagem de Articulados',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>