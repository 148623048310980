<template>
    <v-dialog
      :value="opened"
      persistent
      :max-width="$attrs['max-width'] || 600"
      v-bind="$attrs"
      v-on="$listeners"
      @keydown.esc="$emit('on-close')"
    >
      <slot name="activator"></slot>
      <template #default>
        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary elevation-16' : 'elevation-16'">
          <v-card-text class="content overflow-auto">
            <slot name="content"></slot>
          </v-card-text>
          <v-divider v-if="$slots['footer']" />
          <v-card-actions class="ma-0 pa-0">
            <slot name="footer"></slot>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'uiDialog',
    props: {
      title: {
        type: String,
        default: '',
      },
      opened: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .title {
    font-weight: 800;
  }
  .content {
    min-height: 10.5vh;
    max-height: 68vh;
  }
  .v-dialog > .v-card > .v-card__text {
    padding: 2rem;
  }
  </style>
  