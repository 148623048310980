<template>
    <section class="mt-16">
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
            <v-breadcrumbs
                large
                :items="crumbs"
                divider="/"
                light
                :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
                ></v-breadcrumbs>
        
                <h1 class="primary--text">
                <v-icon>mdi-magnifier</v-icon><span>Templates</span>
                </h1>
        
        
                <div style="position:relative">
                <v-container>
                    
                </v-container>
        
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="total"
                    :loading="loading"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
                    locale="pt-pt"
                >
                
                    <template v-slot:item.frente_obra="{item}">
                        {{item.Frente == null ? '-' : item.Frente.Descricao}}
                    </template>
                    <template v-slot:item.classe_obra="{item}">
                        {{item.Classe == null ? '-' : item.Classe.Descricao}}
                    </template>
                    <template v-slot:item.user="{item}">
                        {{item.user_id == null ? '-' : item.user.funcionario_codigo}}
                    </template>
                    <template v-slot:item.actions="{item}">
                    <v-row justify="center">
                        <v-col cols="12" md="auto">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                outlined
                                x-small
                                color="info"
                                v-if="$store.getters.hasPermission(['super', 'templates.update'])"
                                @click="viewTemplate(item)"
                                >
                                    <v-icon dark>
                                        mdi-eye
                                    </v-icon>
                            </v-btn>
                            <IconRemove
                                v-if="$store.getters.hasPermission(['super', 'templates.delete'])"
                                class="mr-2"
                                @on-submit="deleteTemplate(item)"
                            />
                        </v-col>
                    </v-row>
                    </template>
                </v-data-table>
                </div>
            </v-col>
        </v-row>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
                Template removido com sucesso!
        </DialogSuccess>

    </section>
  </template>
<script>
import Template from "@/api/Templates.js";
import dayjs from 'dayjs';
import IconRemove from '@/components/UI/IconRemove.vue';
import DialogSuccess from '@/components/UI/DialogSuccess.vue';

export default {
    components: {
        IconRemove,
        DialogSuccess
    },
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchTemplate()}, 10);
        },
        deep: true,
        },
    },
    data: () => ({
        loading: false,
        updateDialog: {
            show: false,
            linha: null
        },
        items: [],
        options: {},
        total: 0,
        success: false,
        headers:[
            {
                text: "Obra",
                align: "center",
                value: "Obra.Descricao",
            },
            /*{
                text: "Frente Obra",
                align: "center",
                value: "frente_obra",
            },
            {
                text: "Unidade Obra",
                align: "center",
                value: "classe_obra"
            },*/
            {
                text: "Utilizador",
                align: "center",
                value: "user"
            },
            {
                text: "Opções",
                align: "center",
                value: "actions"
            }
        ],
        tableFooter:{
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': 'Linhas por página'
        }
    }),
    mounted(){  
        this.fillBaseData();
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        fillBaseData() {

            if(localStorage["odc-templates-" + this.$store.state.user.id] != undefined){

            let data = JSON.parse(localStorage["odc-templates-" + this.$store.state.user.id]);

            this.filter = data.filter;

            this.options = data.options;
            }
        },
        searchTemplate(event){
            if(!!event && event.type == 'submit'){
            this.options.page = 1;
            }

            this.loading = true;

            let filter = {...this.filter};

            let request = {
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            };
        
            Object.assign(request, filter);

            localStorage["odc-templates-" + this.$store.state.user.id] = JSON.stringify({
            filter: this.filter,
            options: this.options,
            });

            Template.search(request).then(response => {

            let data = response.data;

            this.total = data.total;

            this.items = data.data.map((item) => {

                this.searching = false;

                return item;
            });

            this.loading = false;

            });
        },
        resetTemplate()
        {
            this.filter = {
            };

            this.searchTemplate();
        },
        viewTemplate(item){
            this.$router.push('templates/editar/' + item.id)
        },
        deleteTemplate(item){
            this.$store.dispatch("deleteTemplate", item.id)
                .then(() => {
                    this.success = true;

                    this.error = false;

                    this.updateDialog.show = false
                    this.searchTemplate()

                    this.loading = false;

                }).catch(err => {

                    console.log(err)
                });
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Templates',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
};
</script>
  