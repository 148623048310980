import WorkSchedule from "@/api/WorkSchedule";

export const work_schedule = {
    state: () => ({
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        async createWorkSchedule(params, fields) {
            return new Promise((resolve, reject) => {
                WorkSchedule.createWorkSchedule(fields)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getWorkSchedule(params, id) {
            return new Promise((resolve, reject) => {
                WorkSchedule.getWorkSchedule(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async getWorksScheduled(params, id) {
            return new Promise((resolve, reject) => {
                WorkSchedule.getWorksScheduled(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async updateWorkSchedule(params, data) {
            return new Promise((resolve, reject) => {
                WorkSchedule.updateWorkSchedule(data, data.id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        async deleteWorkSchedule(params, id){
            return new Promise((resolve, reject) => {
                WorkSchedule.deleteWorkSchedule(id)
                    .then(response => {
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
            });
        }
    }
}