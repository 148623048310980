<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Gestão Unidades</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                
                <v-btn
                    v-if="this.$store.getters.hasPermission(['super', 'unities.create'])"
                    tile
                    color="primary"
                    small
                    @click="newDialog = true"
                >
                <v-icon left>
                    mdi-plus
                </v-icon>
                    Adicionar novo
                </v-btn>
                
                <v-data-table      
                    :headers="headers"
                    :server-items-length="total"
                    :options.sync="options"
                    :footer-props="tableFooter"
                    :items="unidades"
                    locale="pt-pt"        
                    class="elevation-4 mt-4"
                >
                <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    color="danger"
                    @click="deleteUnidade(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog
            v-model="newDialog"
            persistent
            max-width="600px"
        >
        <AddUnidade
            v-if="newDialog"
            @close="newDialog = false"
            @new-unit="novaUnidade"
        />
        </v-dialog>
    </v-container>
</template>
<script>
import PaginatedTable from "@/components/UI/Tables/PaginatedTable";
import AddUnidade from "@/components/UI/Modals/AddUnidade";

export default {
    components:{
        PaginatedTable,
        AddUnidade
    },
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.loadData()}, 10);
        },
        deep: true,
        },
    },
    data(){
        return{
            success: false,
            newDialog: false,
            error: false,
            total: 0,
            loading: false,
            unidades: [],
            tableFooter: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            headers:[
                {
                    text: 'Abreviacao',
                    sortable: false,
                    value: 'abreviacao'
                },
                {
                    text: 'Nome',
                    sortable: false,
                    value: 'nome',
                },
                {
                    text: 'Opções',
                    sortable: false,
                    value: 'actions',
                },
            ],
            filter: {
                
            },
            options: {}
        }
    },
    methods:{
        deleteUnidade(u){
            this.$store.dispatch("deleteUnidades", u.id)
                .then((res) => {
                    
                    this.success = true;
                    
                    this.loadData()

                    this.newDialog = false

                    this.error = false;

                    this.loading = false;

                }).catch(err => {

                    console.log(err)
                });
        },
        novaUnidade(u){
            let data={
                abreviacao: u.abreviacao,
                nome: u.nome
            }
            this.$store.dispatch("createUnidades", data)
                .then((res) => {
                    
                    this.success = true;
                    
                    this.loadData()

                    this.newDialog = false

                    this.error = false;

                    this.loading = false;

                }).catch(err => {

                    console.log(err)
                });
        },
        loadData(){
            this.loading = true;

            let filter = {...this.filter};
            
            let request = {
                sortBy:     this.options.sortBy,
                sortDesc:   this.options.sortDesc,
                page:       this.options.page,
                limit:      this.options.itemsPerPage,
            };

            Object.assign(request, filter);

            localStorage["odc-unities-listings-" + this.$store.state.user.data.id] = JSON.stringify({
                filter: filter,
                options: this.options,
            });


            this.$store.dispatch("getListUnidades", request)
                .then((res) => {
                    
                    this.success = true;
                    this.unidades = []
                    this.unidades = res.data

                    this.total = res.total;


                    this.error = false;

                    this.loading = false;

                }).catch(err => {

                    console.log(err)
                });
        }
    },
    mounted(){
        if(localStorage["odc-unities-listings-" + this.$store.state.user.data.id] != undefined){

            let data = JSON.parse(localStorage["odc-unities-listings-" + this.$store.state.user.data.id]);

            this.filter = data.filter;

            this.options = data.options;
        }

    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Gestão de unidades',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>