<template>
  
  <v-tooltip v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>

</template>

<script>
export default {
  name: 'tooltip',
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
