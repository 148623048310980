<template>
    <v-container fluid>
            <v-progress-linear
                v-if="loading"
                class="mb-8"
                color="primary"
                indeterminate
            ></v-progress-linear>
            <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                    <section v-if="!loading">
                        <v-row :key="reloadHeader">
                            <v-col cols="12" md="4">
                                <validation-provider v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                                    <v-autocomplete
                                        :disabled="method == 'update'"
                                        v-model="fields.obra_id"
                                        :items="obras"
                                        outlined dense
                                        :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                        item-value="Id"
                                        :error-messages="errors"
                                        label="Obras *"
                                        @change="changeWork"
                                    />
                                </validation-provider>
                            </v-col>
                            <!--
                            <v-col cols="12" md="4">
                                <v-progress-circular
                                    v-if="loaders.loadFrentes"
                                    indeterminate
                                    color="primary"
                                >
                                </v-progress-circular>
                                <span v-if="loaders.loadFrentes" class="text-caption ml-2">A procurar frentes</span>

                                <div v-if="!hideFrentes">
                                    <validation-provider v-slot="{ errors }" vid="frentes" name="frentes" rules="|">
                                        <v-autocomplete
                                            :disabled="method == 'update'"
                                            v-model="fields.frente_id"
                                            outlined dense
                                            :items="getFrentes"
                                            :item-text="item => item.Descricao"
                                            item-value="ID"
                                            :error-messages="errors"
                                            label="Frentes de Obra"
                                            @change="changeFrenteObra"
                                        />
                                    </validation-provider>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">                                
                                <v-progress-circular
                                    v-if="loaders.loadClasses"
                                    indeterminate
                                    color="primary"
                                >
                                </v-progress-circular>
                                <span v-if="loaders.loadClasses" class="text-caption ml-2">A procurar classes</span>
                                <div v-if="getClasses != null && semClasse == false && !loaders.loadClasses">
                                    <validation-provider v-slot="{ errors }" vid="classes" name="classes" rules="|">
                                        <v-autocomplete
                                            :disabled="method == 'update'"
                                            v-model="fields.classe_id"
                                            outlined dense
                                            :items="getClasses"
                                            :item-text="item => item.Descricao"
                                            item-value="ID"
                                            :error-messages="errors"
                                            label="Classes"
                                            @change="chooseClass"
                                        />
                                    </validation-provider>
                                </div>
                            </v-col>
                            -->
                            <v-col cols="12" md="4" v-if="showContent">
                                <v-autocomplete
                                    dense outlined
                                    label="Atribuir a funcionário"
                                    :items="funcionarios"
                                    v-model="fields.user_id"
                                    :item-text="item=> item.funcionario_codigo + ' - ' + item.name"
                                    item-value="id"
                                />
                            </v-col>
                        </v-row>
                        <v-container :key="reloadContent" fluid v-if="showContent">
                            <v-tabs
                                v-model="tab"
                                dark
                                fixed-tabs
                                background-color="primary"
                                icons-and-text
                                
                            >
                                <v-tab :key="0">
                                    Mão Obra
                                    <v-icon>mdi-account-hard-hat</v-icon>
                                </v-tab>
                                <v-tab :key="1">
                                    Equipamento
                                    <v-icon>mdi-bulldozer</v-icon>
                                </v-tab>
                                <v-tab :key="2">
                                    Material
                                    <v-icon>mdi-archive</v-icon>
                                </v-tab>
                                <v-tab :key="3">
                                    Subempreitadas
                                    <v-icon>mdi-briefcase</v-icon>
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <!-- Mão Obra Interna -->
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card class="elevation-0">
                                                    <v-card-title class="warning white--text">
                                                        Mão Obra Interna
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-simple-table fixed-header>
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" width="15%">
                                                                            Nome
                                                                        </th>
                                                                        <th class="text-left" width="15%">
                                                                            Frente Obra
                                                                        </th>
                                                                        <th class="text-left" width="15%">
                                                                            Classe
                                                                        </th>
                                                                        <th class="text-left" width="7%">
                                                                            HN
                                                                        </th>
                                                                        <th class="text-left" width="7%">
                                                                            HExt
                                                                        </th>
                                                                        <th class="text-left" width="11%">
                                                                            Local
                                                                        </th>
                                                                        <th class="text-left" width="10%">
                                                                            Opções
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        v-for="item in computeFuncionariosInternos" :key="item.id"
                                                                    >
                                                                        <td>
                                                                            <validation-provider v-slot="{ errors }" vid="funcionario" name="funcionario" rules="required">
                                                                                <v-autocomplete
                                                                                    class="mt-4"
                                                                                    outlined dense
                                                                                    :items="funcionariosInternos"
                                                                                    :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                                                    item-value="Codigo"
                                                                                    label="Funcionário"
                                                                                    :error-messages="errors"
                                                                                    v-model="item.funcionario"
                                                                                />
                                                                            </validation-provider>
                                                                        </td>
                                                                        <td>
                                                                            <div v-if="showFrente(item)">
                                                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                    <v-autocomplete                                                                                
                                                                                        v-model="item.frente_id"
                                                                                        :items="frentes_obra"
                                                                                        outlined dense
                                                                                        class="mt-4"
                                                                                        :item-text="ob => ob.Descricao"
                                                                                        item-value="ID"
                                                                                        :error-messages="errors"
                                                                                        label="Frentes de Obra *"
                                                                                        @change="changeFrenteObraV2(item)"
                                                                                    />
                                                                                </validation-provider>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div v-if="showClasse(item)">
                                                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                    <v-autocomplete
                                                                                        v-model="item.classe_id"
                                                                                        :items="item.available_classes"
                                                                                        class="mt-4"
                                                                                        outlined dense
                                                                                        :item-text="ob => ob.Descricao"
                                                                                        item-value="ID"
                                                                                        :error-messages="errors"
                                                                                        label="Classe *"
                                                                                    />
                                                                                </validation-provider>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                label="HN"
                                                                                outlined dense
                                                                                placeholder="Insira HN"
                                                                                v-model="item.hn"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                label="HExt"
                                                                                outlined dense
                                                                                placeholder="Insira HExt"
                                                                                v-model="item.hext"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field  
                                                                                class="mt-4"
                                                                                label="Local"
                                                                                outlined dense
                                                                                placeholder="Insira Local"
                                                                                v-model="item.local"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-btn
                                                                                dark
                                                                                small
                                                                                color="error"
                                                                                @click="deleteFuncionarioInterno(item.id)"
                                                                            >
                                                                                <v-icon small>mdi-delete</v-icon>
                                                                            </v-btn>
                                                                        </td>
                                                                    
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" align="center">
                                                <v-btn fab @click="addFuncionarioInterno" class="primary">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <!-- Mão Obra Interna -->

                                    <!-- Mão Obra Externa -->
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card class="elevation-0">
                                                    <v-card-title class="d-none d-lg-block warning white--text">
                                                    Mão Obra Externa <v-btn
                                                                        style="float: right;"
                                                                        small
                                                                        class="primary"
                                                                        @click="entitySelect = false;entitySelect = true"
                                                                    ><v-icon>mdi-magnify</v-icon>Entidade
                                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                                                    Mão Obra Externa<v-btn
                                                                        small
                                                                        class="primary"
                                                                        @click="entitySelect = false;entitySelect = true"
                                                                    ><v-icon small>mdi-magnify</v-icon>Entidade
                                                                    </v-btn>
                                                </v-card-title>
                                                    <v-card-text>
                                                        <v-simple-table class="d-none d-lg-block" fixed-header>
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" width="20%">
                                                                            Entidade
                                                                        </th>
                                                                        <th class="text-left" width="15%">
                                                                            Nome
                                                                        </th>
                                                                        <th class="text-left" width="15%">
                                                                            Frente Obra
                                                                        </th>
                                                                        <th class="text-left" width="15%">
                                                                            Classe
                                                                        </th>
                                                                        <th class="text-left" width="7%">
                                                                            HN
                                                                        </th>
                                                                        <th class="text-left" width="7%">
                                                                            HExt
                                                                        </th>
                                                                        <th class="text-left" width="11%">
                                                                            Local
                                                                        </th>
                                                                        <th class="text-left" width="10%">
                                                                            Opções
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        v-for="item in computeFuncionariosExternos" :key="item.id"
                                                                        class="mt-4"
                                                                    >
                                                                        <td>
                                                                            <v-autocomplete
                                                                                class="mt-4"
                                                                                dense outlined
                                                                                :items="entidades"
                                                                                v-model="item.entidade"
                                                                                :item-text="item=>item.Codigo + ' - ' + item.Nome"
                                                                                return-object
                                                                                label="Entidade"
                                                                                @change="changedLineEntity(item)"
                                                                            />                                                                            
                                                                        </td>
                                                                        <td>
                                                                            <validation-provider v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                   
                                                                                <v-autocomplete
                                                                                    v-if="item.funcionariosEntidades.length > 0"
                                                                                    class="mt-4"
                                                                                    outlined dense
                                                                                    :items="item.funcionariosEntidades"
                                                                                    :item-text="item => item.numero + ' - ' + item.nome"
                                                                                    item-value="ID"
                                                                                    label="Funcionário"
                                                                                    :error-messages="errors"
                                                                                    v-model="item.funcionario"
                                                                                />
                                                                            </validation-provider>
                                                                        </td>
                                                                        <td>
                                                                            <div v-if="showFrente(item)">
                                                                                <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                    <v-autocomplete                                                                                
                                                                                        v-model="item.frente_id"
                                                                                        :items="frentes_obra"
                                                                                        outlined dense
                                                                                        class="mt-4"
                                                                                        :item-text="ob => ob.Descricao"
                                                                                        item-value="ID"
                                                                                        :error-messages="errors"
                                                                                        label="Frentes de Obra *"
                                                                                        @change="changeFrenteObraV2(item)"
                                                                                    />
                                                                                </validation-provider>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div v-if="showClasse(item)">
                                                                                <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                    <v-autocomplete
                                                                                        v-model="item.classe_id"
                                                                                        :items="item.available_classes"
                                                                                        class="mt-4"
                                                                                        outlined dense
                                                                                        :item-text="ob => ob.Descricao"
                                                                                        item-value="ID"
                                                                                        :error-messages="errors"
                                                                                        label="Classe *"
                                                                                    />
                                                                                </validation-provider>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                label="HN"
                                                                                placeholder="Insira HN"
                                                                                v-model="item.hn"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                label="HExt"
                                                                                placeholder="Insira HExt"
                                                                                v-model="item.hext"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field  
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                label="Local"
                                                                                placeholder="Insira Local"
                                                                                v-model="item.local"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-btn
                                                                                dark
                                                                                small
                                                                                color="error"
                                                                                @click="deleteFuncionarioExterno(item.id)"
                                                                            >
                                                                                <v-icon small>mdi-delete</v-icon>
                                                                            </v-btn>
                                                                        </td>
                                                                    
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>

                                                        <v-data-iterator
                                                        :items="fields.funcionariosExternos"
                                                        item-key="id"
                                                        :items-per-page="-1"
                                                        hide-default-footer
                                                        class="d-block d-lg-none"
                                                    >
                                                    <template v-slot:default="{ items }">
                                                        <v-row>
                                                            <v-col
                                                                v-for="item in items"
                                                                :key="item.id"
                                                                cols="12"
                                                            >
                                                                <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                                                    <v-list
                                                                        dense
                                                                    >
                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    v-if="item.entidade != null"
                                                                                    readonly
                                                                                    dense outlined
                                                                                    :value="item.entidade.Codigo + ' - ' + item.entidade.Nome"
                                                                                    label="Entidade"
                                                                                />
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <validation-provider v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                   
                                                                                    <v-autocomplete
                                                                                        class="mt-4"
                                                                                        outlined dense
                                                                                        :items="fixedAddFuncionariosExternosArray"
                                                                                        :item-text="item => item.Numero + ' - ' + item.Nome"
                                                                                        item-value="ID"
                                                                                        label="Funcionário"
                                                                                        :error-messages="errors"
                                                                                        v-model="item.funcionario"
                                                                                    />
                                                                                </validation-provider>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    label="HN"
                                                                                    outlined dense
                                                                                    placeholder="Insira HN"
                                                                                    v-model="item.hn"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    label="HExt"
                                                                                    outlined dense
                                                                                    placeholder="Insira HExt"
                                                                                    v-model="item.hext"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field  
                                                                                    class="mt-4"
                                                                                    label="Local"
                                                                                    outlined dense
                                                                                    placeholder="Insira Local"
                                                                                    v-model="item.local"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-btn
                                                                                    dark
                                                                                    small
                                                                                    color="error"
                                                                                    @click="deleteFuncionarioExterno(item.id)"
                                                                                >
                                                                                    <v-icon small>mdi-delete</v-icon> Remover
                                                                                </v-btn>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    </v-data-iterator>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" align="center">
                                                <v-btn v-if="true" fab @click="addFuncionarioExterno" class="primary">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                                <v-btn v-if="false" fab @click="entitySelect = false;entitySelect = true" class="primary">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <!-- Mão Obra Externa -->
                                </v-tab-item>
                                <v-tab-item>
                                    <Equipments :hideFrentes="hideFrentes" :template_classes="classes" :frentes_obra="frentes_obra" :fields="fields" return-object/>
                                </v-tab-item>
                                <v-tab-item>
                                    <Materials :hideFrentes="hideFrentes" :template_classes="classes" :frentes_obra="frentes_obra" :fields="fields" return-object/>
                                </v-tab-item>
                                <v-tab-item>
                                    <Subcontractors :fields="fields" return-object />
                                </v-tab-item>
                            </v-tabs-items>
                        </v-container>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="1" class="d-none d-lg-block">                                
                                <v-btn v-show="fields.obra_id != null" :disabled="tab == 0" @click="tab = tab - 1" small fab class="mt-4 primary">
                                    <v-icon small>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="10" align="center">
                                <v-btn rounded class="success mt-4" :disabled="invalid" type="submit">
                                    Gravar
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="1" class="d-none d-lg-block">                           
                                <v-btn v-show="fields.obra_id != null" :disabled="tab == 3" @click="tab = tab + 1" small fab class="mt-4 primary">
                                    <v-icon small>mdi-greater-than</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </section>
                </v-form>
            </validation-observer>

            <v-dialog
                v-model="entitySelect"
                fullscreen
            >
                <v-card class="elevation-0 ma-0 pa-0" v-if="entitySelect">
                    <v-toolbar
                        dark
                        color="primary"
                    >
                        <v-toolbar-title>Funcionários por entidade</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="entitySelect = false"
                        >
                            X
                        </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text class="mt-4 d-none d-lg-block">
                        <v-row>
                            <v-col cols="12" md="6" v-if="hideFrentes == false" >
                                <v-autocomplete                                                                                                       
                                    v-model="entitySelectedFrente"
                                    :items="frentes_obra"
                                    outlined dense
                                    class="mt-4"
                                    :item-text="ob => ob.Descricao"
                                    item-value="ID"
                                    label="Frentes de Obra *"
                                    @change="changeFrenteObraV2Entity"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-autocomplete
                                    v-model="entitySelectedClasse"
                                    :items="entityClasses"
                                    class="mt-4"
                                    outlined dense
                                    :item-text="ob => ob.Descricao"
                                    item-value="ID"
                                    label="Classe *"
                                />
                            </v-col>
                            <v-col cols="12">                        
                                <v-autocomplete
                                    dense outlined
                                    :items="entidades"
                                    v-model="entitySelected"
                                    :item-text="item=>item.Codigo + ' - ' + item.Nome"
                                    item-value="ID"
                                    label="Entidade"
                                    @change="fillColaboradoresEntidade"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                Pesquisa:
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    dense outlined
                                    v-model="searchEntitySelected"
                                    label="Nome/Número"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn
                                    class="success"
                                    @click="getFuncionariosPorEntidade"
                                ><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text class="mt-4 d-block d-lg-none">
                        <v-row no-gutters>
                            <v-col cols="12">                        
                                <v-autocomplete
                                    dense outlined
                                    :items="entidades"
                                    v-model="entitySelected"
                                    :item-text="item=>item.Codigo + ' - ' + item.Nome"
                                    item-value="ID"
                                    label="Entidade"
                                    @change="fillColaboradoresEntidade"
                                />
                            </v-col>
                            <v-col cols="12" md="auto">
                                Pesquisa:
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-text-field
                                    dense outlined
                                    v-model="searchEntitySelected"
                                    label="Nome/Número"
                                />
                            </v-col>
                            <v-col cols="12" md="2" align="center" justify="center">
                                <v-btn
                                    class="success"
                                    @click="getFuncionariosPorEntidade"
                                ><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text v-if="entitySelected != null">
                        <v-progress-linear
                            v-if="loadingTemplateEntities"
                            color="primary"
                            indeterminate
                        ></v-progress-linear>
                        <!-- DESKTOP -->
                        <v-virtual-scroll
                            v-if="!loadingTemplateEntities"
                            class="d-none d-lg-block"
                            :height="getWindowHeight" 
                            item-height="75"
                            :items="funcionariosExternos">
                            <template v-slot:default="{ item }">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                        <v-col cols="12" md="8">
                                            <v-text-field 
                                                dense outlined
                                                disabled
                                                v-model="item.funcionario"
                                                label="Funcionario"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field 
                                                dense outlined
                                                disabled
                                                v-model="item.hn"
                                                @keydown="item.selected = true"
                                                label="HN"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field 
                                                dense outlined
                                                disabled
                                                v-model="item.hext"
                                                @keydown="item.selected = true"
                                                label="HExt"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field 
                                                dense outlined
                                                disabled
                                                v-model="item.local"
                                                @keydown="item.selected = true"
                                                label="Local"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-switch 
                                                dense
                                                v-model="item.selected"
                                            />
                                        </v-col>
                                    </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-virtual-scroll>
                        <!-- DESKTOP -->

                        <!-- MOBILE -->
                        <v-virtual-scroll
                            v-if="!loadingTemplateEntities"
                            class="d-md-none"
                            height="600" 
                            item-height="475"
                            :items="funcionariosExternos">
                            <template v-slot:default="{ item }">
                                <v-card class="elevation-0" outlined>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-row>
                                            <v-col cols="12" md="8">
                                                <v-text-field 
                                                    dense outlined
                                                    disabled
                                                    v-model="item.funcionario"
                                                    label="Funcionario"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-text-field 
                                                    dense outlined
                                                    v-model="item.hn"
                                                    @keydown="item.selected = true"
                                                    label="HN"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-text-field 
                                                    dense outlined
                                                    v-model="item.hext"
                                                    @keydown="item.selected = true"
                                                    label="HExt"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-text-field 
                                                    dense outlined
                                                    v-model="item.local"
                                                    @keydown="item.selected = true"
                                                    label="Local"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-switch 
                                                    dense
                                                    v-model="item.selected"
                                                />
                                            </v-col>
                                        </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </template>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-iterator
                                        :items="funcionariosPorEntidade"
                                        item-key="id"
                                        :items-per-page="-1"
                                        hide-default-footer
                                        class="d-block d-lg-none ma-0 pa-0"
                                    >
                                    <template v-slot:default="{ items }">
                                        <v-row class="ma-0 pa-0">
                                            <v-col
                                                class="ma-0 pa-0"
                                                v-for="item in items"
                                                :key="item.id"
                                                cols="12"
                                            >
                                                <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                                    <v-list
                                                        dense
                                                    >
                                                        <v-list-item>
                                                            <v-list-item-content class="align-center">
                                                                <v-text-field 
                                                                    dense outlined
                                                                    disabled
                                                                    v-model="item.funcionario"
                                                                    label="Funcionario"
                                                                />
                                                            </v-list-item-content>                                                                            
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-list-item-content class="align-center">
                                                                <v-text-field 
                                                                    dense outlined
                                                                    v-model="item.hn"
                                                                    @keydown="item.selected = true"
                                                                    label="HN"
                                                                />
                                                            </v-list-item-content>                                                                            
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-list-item-content class="align-center">
                                                                <v-text-field 
                                                                    dense outlined
                                                                    v-model="item.hext"
                                                                    @keydown="item.selected = true"
                                                                    label="HExt"
                                                                />
                                                            </v-list-item-content>                                                                            
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-list-item-content class="align-center">
                                                                <v-text-field 
                                                                    dense outlined
                                                                    v-model="item.local"
                                                                    @keydown="item.selected = true"
                                                                    label="Local"
                                                                />
                                                            </v-list-item-content>                                                                            
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-list-item-content class="align-center">
                                                                <v-switch 
                                                                    v-model="item.selected"
                                                                    label="Selecionar"
                                                                    inset
                                                                />
                                                            </v-list-item-content>                                                                            
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    </v-data-iterator>
                                </v-col>
                            </v-row>
                        </v-virtual-scroll>
                        <!-- MOBILE -->
                    </v-card-text>
                    <v-card-actions v-if="entitySelected != null">
                        <v-row>
                            <v-col align="center" justify="center" cols="12">
                                <v-btn class="success" @click="addFuncionariosPorEntidade">Gravar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
                Template gravado com sucesso!
        </DialogSuccess>

        <SuccessSnackbar message="Colaboradores inseridos no template" v-model="successAddEntity"/>

    </v-container>
</template>
<script>
import dayjs from 'dayjs'
import Obra from "@/api/Obra.js"
import Entidades from "@/api/Entidades.js"
import FrenteObra from "@/api/FrenteObra.js"
import Classe from "@/api/Classe.js"
import Template from '@/api/Templates.js'
import Works from '@/api/Works.js'
import SearchUserById from "@/components/UI/Inputs/SearchUserById"
import Equipments from "@/components/Templates/objects/Equipments.vue"
import Materials from "@/components/Templates/objects/Materials.vue"
import Subcontractors from "@/components/Templates/objects/Subcontractors.vue"
import DialogSuccess from '@/components/UI/DialogSuccess.vue';
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default{
    name:"Template",
    components:{
        SearchUserById,
        Equipments,
        Materials,
        Subcontractors,
        DialogSuccess,
        SuccessSnackbar
    },
    props:{
        method: String,
        template: Object
    },
    watch: { 
        'fields.obra_id':{
            immediate: true,
            handler(){
                if(this.fields.obra_id == null)
                    return 
                    
                Obra.byObra(this.fields.obra_id).then((resp)    =>  {
                    this.funcionariosGeral = resp.data
                })
            }
        },
        entitySelect:{
            immediate: true,
            handler(val){
                if(val == undefined)
                    return

                if(val == false)
                    this.entitySelected = null
            }
        },
        template: {
            immediate: true, 
            handler (val) {        
                if(!val) {
                    return;
                }

                let data = {...val};

                this.fields = data.template;

                this.fixedAddFuncionariosExternosArray = data.template.funcionariosExternos

                
            }
        },
    },
    data(){
        return{
            funcionariosGeral: [],
            entitySelect: false,
            loadingTemplateEntities: false,
            searchEntitySelected: null,
            entitySelected: null,
            entitySelectedFrente: null,
            entitySelectedClasse: null,
            entityClasses: [],
            entityClasseNeeded: false,
            entityHideFrentes: false,
            entidades: [],
            mostrarClassesPorObra: false,
            loading: true,
            searchByIdName: '',
            semFrente: false,
            semClasse: false,
            tab: 0,
            obras: [],
            frentes_obra: [],
            classes: [],
            success: false,
            error: null,
            hideFrentes: false,
            error_message: null,
            funcionariosInternos: [],
            funcionariosExternos: [],
            showContent: false,
            globals:{
                obra: null,
                frente: null,
                classe: null
            },
            successAddEntity: false,
            fields:{
                obra_id: null,
                frente_id: null,
                classe_id: null,
                funcionariosInternos: [],
                funcionariosExternos: [],
                equipamentosInternos: [],
                equipamentosExternos: [],
                materiais: [],
                subempreitadas: [],
                obsMO: null,
                obsEQ: null
            },
            redirecter: null,
            loaders:{
                loadFrentes: false,
                loadClasses: false
            },
            reloadHeader: 0,
            reloadContent: 900,
            funcionariosPorEntidade: [],
            fixedAddFuncionariosExternosArray:[],
            funcionarios: []
        }
    },
    mounted(){
        this.fillBaseData()        
    },
    methods:{
        async changedLineEntity(item){
            await Obra.byEntidade(item.entidade.ID).then((resp) =>  {
                item.funcionariosEntidades = resp.data
            })
        },
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        async changeFrenteObraV2(item){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }


                await Classe.getClasses(data).then((resp) =>  {
                    item.available_classes = resp.data

                    if(item.available_classes.length == 0){
                        item.classeNeeded = false
                    }else item.classeNeeded = true
                    
                })

                
            }

           
            
            this.loading = false
        },
        async changeFrenteObraV2Entity(){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.entityClasses = resp.data
                })

                if(this.entityClasses.length == 0){
                    this.entityClasseNeeded = false
                }else this.entityClasseNeeded = true
            }

            
            this.loading = false
        },
        showFrente(item){
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true

            return false
        },
        fillColaboradoresEntidade(){
            this.loadingTemplateEntities = true
            Obra.byEntidade(this.entitySelected).then((resp) =>  {
                this.funcionariosPorEntidade = resp.data

                this.getFuncionariosPorEntidade()
                this.loadingTemplateEntities = false
            })
        },
        getFuncionariosPorEntidade(){
            let r = []     

            this.funcionariosPorEntidade.forEach(element => {
                if(element.entidade != null){
                    if(element.entidade.ID == this.entitySelected){
                        let func = element.Numero + ' - ' + element.Nome
                        
                        let ob = null;
                        if(this.searchEntitySelected == null){
                            ob = {
                                    id: element.ID,
                                    funcionario: func,
                                    hn: 8,
                                    hext: 0, 
                                    local: null,
                                    selected: false,
                                    numero: element.Numero,
                                    nome: element.Nome,
                                    entidade: element.entidade
                                }
                        }else{
                            if(func.toLowerCase().includes(this.searchEntitySelected.toLowerCase())){                                 
                                ob = {
                                        id: element.ID,
                                        funcionario: func,
                                        hn: 8,
                                        hext: 0, 
                                        local: null,
                                        selected: false,
                                        numero: element.Numero,
                                        nome: element.Nome,
                                        entidade: element.entidade
                                    }
                            }
                        }


                        let existe = false
                        if(ob != null){
                            this.fields.funcionariosExternos.forEach(aux => {
                                if(aux.funcionario != null)
                                    if(aux.funcionario.includes(ob.numero))
                                        existe = true
                            });
                        }

                        if(!existe)
                            if(ob != null)
                                r.push(ob)
                    }
                }
            });

            this.funcionariosExternos = r
        },
        /*changedExterno(val){
            let ent = null
            this.funcionariosExternos.forEach(element => {
                if(element.ID == val){
                    ent = element.entidade
                }
            });
            

            let pos = -1
            let i = 0
            
            this.fields.funcionariosExternos.forEach(element => {
                
                if(element.Numero == val || element.funcionario == val)
                    pos = i

                i++
            });

            if(pos != -1)
                this.fields.funcionariosExternos[pos].entidade = ent
        },*/
        addFuncionariosPorEntidade(){
            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element.Descricao
            });
            let classe = null
            this.entityClasses.forEach(element => {
                if(element.ID == this.entitySelectedClasse)
                    classe = element.Descricao
            });

            this.funcionariosExternos.forEach(element => {
                if(element.selected){
                    /*let ja_existe = false
                    this.fields.funcionariosExternos.forEach(existencia => {
                        if(element.funcionario.includes(existencia.numero)){
                            ja_existe = true
                        }
                    });*/

                    //if(ja_existe == false){
                        
                        element.frente_id = this.entitySelectedFrente
                        element.classe_id = this.entitySelectedClasse
                        element.classe_obra = classe == null ? '-' : classe
                        element.frente_obra = frente == null ? '-' : frente
                        element.funcionario = element.id
                        this.fixedAddFuncionariosExternosArray.push(element)
                        
                        if(this.method == 'create'){
                            this.fields.funcionariosExternos.push({
                                id: element.id,
                                ID: element.id,
                                funcionario: element,
                                frente_id: this.entitySelectedFrente,
                                classe_id: this.entitySelectedClasse,
                                funcionariosEntidades:this.fixedAddFuncionariosExternosArray,
                                frente_obra: frente,
                                classe_obra: classe,
                                hn: element.hn,
                                hext: element.hext,
                                local: element.local,
                                options: "",
                                entidade: element.entidade
                            })
                        }
                    //}
                }
            });



            this.entitySelected = null
            this.entitySelect = false
            this.successAddEntity = true
        },
        onSuccess(){
            this.success = false

            if(this.method == 'create'){
                if(this.redirecter != null){
                    this.$router.push('/configuracoes/templates/editar/' + this.redirecter)                   
                }
            }
        },
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
            });

            Classe.getClasseFuncionariosTemplate().then((resp)    =>  {
                this.funcionariosInternos = resp.data
            })

            if(this.method == 'update'){
                if(this.fields.frente_id == null)
                    this.semFrente = true
                if(this.fields.classe_id == null)
                    this.semClasse = true
                
                this.changeWork()
            }
 
            

            this.loading = false
        },
        chooseClass(){
            if(this.mostrarClassesPorObra == false)
                this.globals.frente.classes.forEach(element => {
                    if(element.ID == this.fields.classe_id)
                        this.globals.classe = element
                });
        },
        changeWork(){        
            this.reloadHeader++
            this.reloadContent++
            this.loaders.loadFrentes = true

            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    ObraPai = element.Codigo
            });
            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data
                
                if(this.frentes_obra.length == 0 || ObraPai == null){
                    this.hideFrentes = true
                    this.mostrarClassesPorObra = true
                    this.classesPorObra()
                }

                this.loaders.loadFrentes = false
            })

            Entidades.all().then((resp)   =>  {
                this.entidades = resp.data.entidades
            })

            Works.getUsers(this.fields.obra_id).then((resp)    =>  {
                this.funcionarios = resp.data
            })

            this.showContent = true
        },
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            this.loaders.loadClasses = true

            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
                this.entityClasses = this.classes
                this.loaders.loadClasses = false               
            })
        },
        changeFrenteObra(){
            let save_copy = this.fields.obra_id
            
            this.reloadHeader++
            this.reloadContent++

            this.fields.obra_id = save_copy

            this.loaders.loadClasses = true

            let SubEmp = null
            this.getFrentes.forEach(element => {
                if(element.ID == this.fields.frente_id){
                    SubEmp = element.SubEmp
                }
            });
            
            let data = {
                SubEmp: SubEmp
            }
            Classe.getClasses(data).then((resp) =>  {
                this.classes = resp.data
                this.loaders.loadClasses = false
            })
        },
        addFuncionarioInterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.frentes_obra.length == 0){
                available_classes = this.classes;
                classeNeeded = true;
            }

            this.fields.funcionariosInternos.push({
                id: dayjs().unix() + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                options: ""
            })
        },
        addFuncionarioExterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.frentes_obra.length == 0){
                available_classes = this.classes;
                classeNeeded = true;
            }
            
            this.fields.funcionariosExternos.push({
                id: dayjs().unix() + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                funcionariosEntidades: [],
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                options: ""
            })
        },
        deleteFuncionarioInterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosInternos.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.funcionariosInternos.splice(pos, 1)
        },
        deleteFuncionarioExterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosExternos.forEach(element => {
                if(element.id == id)
                    pos = i

                i++
            });

            this.fields.funcionariosExternos.splice(pos, 1)
        },
        submit(){
            this.loading = true


            let a = true
            let subEmpId = null
            if(this.globals.frente != null)
                subEmpId = this.globals.frente.SubEmpId

            let classeID = null
            if(this.globals.classe != null)
                classeID = this.globals.classe.ClasseId

            if(this.mostrarClassesPorObra){
                this.classes.forEach(element => {
                    if(element.ID == this.fields.classe_id)
                        classeID = element.ClasseId
                });
            }
            

         
            let data = {
                mo_internal: this.fields.funcionariosInternos,
                mo_external: this.fields.funcionariosExternos,
                eq_internal: this.fields.equipamentosInternos,
                eq_external: this.fields.equipamentosExternos,
                subEmpId: subEmpId,
                classeId: classeID,
                material: this.fields.materiais,
                subEmpreitadas: this.fields.subempreitadas,
                id: this.$route.params.id,
                work_code: this.fields.obra_id,
                user_id: this.fields.user_id
            }

            Template[this.method == 'update' ? 'edit' : this.method](data, data.id)
                .then((response) => {

                    if(response != null)
                        if(response.data != null)
                            if(response.data.id != null)
                                this.redirecter = response.data.id
                                
                    this.success = true;

                    this.error = false;

                    this.loading = false;

                
                }).catch(err => {

                    if(err.response.status == 422){

                        this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                    }
                    
                    this.success = false;

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    },
    computed:{
        computeFuncionariosInternos(){
            let returner = []

            this.fields.funcionariosInternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.frentes_obra.length > 0){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        },
        computeFuncionariosExternos(){
            let returner = []

            this.fields.funcionariosExternos.forEach(element => {                
                if(element.classe_id != null){
                    if(this.frentes_obra.length > 0){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner
        },
        getWindowHeight(){
            //Altura da janela menos 20%

            let calculo = window.innerHeight * 0.45
            
            return window.innerHeight - calculo
        },
        showFrentesObra(){
            let show = true
            if(this.getFrentes == null)
                return false

            if(this.semFrente == true)
                return false

            if(this.getFrentes.length == 0)
                return false

            return show
        },  
        getFrentes(){
            if(this.fields.obra_id == null)
                return null

            let choosen = null
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id){
                    choosen = element
                    this.globals.obra = element
                }
            });

            if(choosen == null)
                return null

            return this.frentes_obra
        },
        getClasses(){
            if(this.mostrarClassesPorObra)
                return this.classes

            if(this.fields.frente_id == null)
                return null

            let choosen = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id){
                    choosen = element
                    this.globals.frente = element
                }
            });

            if(choosen == null)
                return null

            return choosen.classes
        }
    }
}
</script>