<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-briefcase</v-icon> <span>Edição de Diário de KPI</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <DiaryKPI v-else method="update" :diary="diary"></DiaryKPI>
        </div>
    </section>
  </template>
  <script>
  import DiaryKPI from '@/components/Diaries/Kpi/DiaryKPI.vue'
  
  import DiaryKPI_API from "@/api/DiaryKPI.js";
  
  export default {
    components: {
        DiaryKPI
    },
    beforeCreate(){
      document.title = "ODC - Edição de Diário KPI";
    },
    data: () => ({
        diary: {},
        loading:true
    }),
    mounted(){
  
        if(!this.$store.getters.hasPermission(["super", 'kpi_diaries.update'])) {
        this.$router.push('/admin');
      }
  
      DiaryKPI_API.find(this.$route.params.id).then(({data}) => { this.diary = data; this.loading = false;});
    },
    methods:{
      
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Edição de Diário de KPI',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  